import React, { FunctionComponent, useState, useCallback } from "react";
import Select from "react-select";
import useDispatch from "../../hooks/use-thunk-dispatch";
import moment from "moment";

import {
  ConfirmationButtons,
  Message,
  YesButton,
  NoButton,
  Input
} from "./style";

interface ConfirmationModalProps {
  onCancel: () => void;
  onConfirm: () => void;
  onChangeCloneDays: (any) => void;
  selectedWeek: string | null;
  message: string;
  daysToClone: {}[];
}

export const ConfirmationModal: FunctionComponent<ConfirmationModalProps> = props => {
  const [selectedDaysToClone, setSelectedDaysToClone] = useState(
    props.daysToClone
  );

  let currentWeek = moment(sessionStorage.getItem("SelectedWeek"));
  return (
    <React.Fragment>
      <Message>{props.message}</Message>
      <Input
        disabled
        style={{
          width: "200px"
        }}
        type="week"
        value={moment(currentWeek).format("GGGG-[W]WW")}
      ></Input>
      <div style={{ height: "10px" }}></div>
      <Select
        isMulti={true}
        options={props.daysToClone}
        value={selectedDaysToClone}
        onChange={e => {
          setSelectedDaysToClone(e);
          props.onChangeCloneDays(e);
        }}
      />
      <p>
        Attention : le clone supprime toutes les disponibilités des jours
        sélectionnés.
      </p>
      <ConfirmationButtons>
        <YesButton onClick={props.onConfirm}>Oui</YesButton>
        <NoButton onClick={props.onCancel}>Non</NoButton>
      </ConfirmationButtons>
    </React.Fragment>
  );
};
