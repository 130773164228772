import { Availability } from "../../typings/availability";
import { CheckAvailability } from "../../typings/checkAvailability";

export const SET_AVAILABILITIES = "SET_AVAILABILITIES";
export const SET_CHECK_AVAILABILITIES = "SET_CHECK_AVAILABILITIES";
export const PUT_AVAILABILITY_SUCCESS = "PUT_AVAILABILITY_SUCCESS";
export const POST_AVAILABILITY_SUCCESS = "POST_AVAILABILITY_SUCCESS";
export const DELETE_AVAILABILITY_SUCCESS = "DELETE_AVAILABILITY_SUCCESS";
export const CLONE_AVAILABILITY_SUCCESS = "CLONE_AVAILABILITY_SUCCESS";
export const CLONE_DAILY_AVAILABILITY_SUCCESS =
  "CLONE_DAILY_AVAILABILITY_SUCCESS";
export const CLEAN_AVAILABILITY_SUCCESS = "CLEAN_AVAILABILITY_SUCCESS";

interface SetAvailabilities {
  type: typeof SET_AVAILABILITIES;
  availabilities: Availability[];
}
interface SetCheckAvailabilities {
  type: typeof SET_CHECK_AVAILABILITIES;
  checkAvailability: CheckAvailability[];
}

export type AvailabilitiesActionTypes = SetAvailabilities;

export type CheckAvailabilitiesActionTypes = SetCheckAvailabilities;

export interface AvailabilitiesState {
  allAvailabilities: Availability[];
}
export interface CheckAvailabilitiesState {
  checkAvailability: CheckAvailability;
}
export interface ActionResponse {
  success: boolean;
  error?: string;
}
