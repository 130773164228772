import styled from "styled-components";

export const Wrapper = styled.div`
    width 80%;
    @media(min-width: 768px) {
    width: 60%;
    }
    @media(min-width: 1024px) {
    width: 40%;
    }
`;

export const ConfirmationButtons = styled.div`
  display: flex;
  justify-content: center;
`;

export const Message = styled.div`
  font-size: 0.9rem;
  margin-bottom: 10px;
  text-align: center;
`;

export const YesButton = styled.button`
  background-color: #122630;
  color: white;
  width: 3rem;
`;

export const NoButton = styled.button`
  background-color: #122630;
  color: white;
  width: 3rem;
  margin-left: 10px;
`;

export const Input = styled.input`
  display: block;
  box-sizing: border-box;
  width: 100%;
  border-radius: 4px;
  border: 1px solid black;
  padding: 10px 15px;
  margin-left: 3px;
  font-size: 14px;
  margin: auto;
`;

export const Label = styled.label`
  line-height: 2;
  text-align: left;
  display: block;
  margin-bottom: 13px;
  margin-top: 20px;
  color: black;
  font-size: 14px;
  font-weight: 200;
`;

export const Select = styled.select`
  display: block;
  box-sizing: border-box;
  width: 100%;
  border-radius: 4px;
  border: 1px solid black;
  padding: 10px 15px;
  margin-bottom: 10px;
  font-size: 14px;
`;

export const Button = styled.button`
  background-color: #122630;
  color: white;
  font-size: 20px;
  border-radius: 5px;
  margin: auto;
  cursor: pointer;
`;

export const SpanBox = styled.span`
  background-color: rgba(255, 255, 128, 0.5);
  color: black;
  font-size: 20px;
  border-radius: 5px;
  margin: auto;
  cursor: pointer;
`;

export const ButtonContainer = styled.div`
  margin: 2% 40%;
`;

export const ResultContainer = styled.div`
  height: 200px;
  width: 100%;
  display: flex;
  margin: 4% auto;
`;

export const ScrollableList = styled.div`
  height: 150px;
  width: 400px;
`;
