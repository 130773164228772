import React, { useCallback } from "react";
import { useDispatch } from "react-redux";

import { Container, Icon, Text, TextWrapper } from "../order-info-piece/style";
import { BagButton } from "./style";
import { setCurrentBag } from "../../store/orders/actions";
import { Item, BagTypeType } from "../../store/orders/types";
import { getBagIcon } from "../../utils/formatter";

interface Props {
  barcode: string;
  type: BagTypeType;
  itemization: Item[];
}

const BagInfoPiece = ({ barcode, itemization, type }: Props) => {
  const dispatch = useDispatch();

  const setCurrentBagCb = useCallback(
    () => dispatch(setCurrentBag(barcode, itemization)),
    [barcode, dispatch]
  );
  return (
    <BagButton onClick={setCurrentBagCb} style={{ paddingLeft: 0 }}>
      <Container>
        <div style={{ display: "table-row" }}>
          <Icon
            src={getBagIcon(itemization)}
            alt=""
            color="#00aced"
            style={{ display: "table-cell" }}
          />

          <TextWrapper>
            <Text>
              {barcode} - {type}
            </Text>
          </TextWrapper>
        </div>
      </Container>
    </BagButton>
  );
};

export default BagInfoPiece;
