import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { confirmAlert } from "react-confirm-alert";
import { getBagIcon } from "../../utils/formatter";

import { Icon } from "../order-info-piece/style";
import {
  BagButton,
  BagDetail,
  BagDetailItem,
  BagTitle,
  Container,
  Name,
  Qty
} from "./style";
import { AppState } from "../../store";
import { setCurrentBag, actionRemoveBag } from "../../store/orders/actions";
import { BagType, OrderType } from "../../store/orders/types";
import { Product as ProductType } from "../../typings/product";
import "../../assets/css/react-confirm-alert/react-confirm-alert.css";

interface Props {
  bag: BagType;
}

const BagView = ({ bag }: Props) => {
  const dispatch = useDispatch();
  const products = useSelector<AppState, ProductType[]>(
    state => state.products.allProducts
  );
  const currentOrder = useSelector<AppState, OrderType | null>(
    state => state.orders.currentOrder
  );

  const setCurrentBagCb = useCallback(
    () => dispatch(setCurrentBag(bag.barcode, bag.itemization)),
    [bag.barcode, dispatch]
  );

  const removeBag = useCallback(
    barcode => {
      confirmAlert({
        title: "Confirm the delete",
        message: "This will delete the bag and the itemization",
        buttons: [
          {
            label: "Yes",
            onClick: () =>
              currentOrder &&
              dispatch(actionRemoveBag(currentOrder.id, barcode))
          },
          {
            label: "No",
            onClick: () => {
              //
            }
          }
        ]
      });
    },
    [dispatch]
  );

  return (
    <Container>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between"
        }}
      >
        <BagTitle>
          <Icon src={getBagIcon(bag.itemization)} alt="" />
          {bag.barcode} - {bag.type}
        </BagTitle>

        {currentOrder && currentOrder.isItemizationBlocked ? null : (
          <div
            style={{ padding: "20px" }}
            dangerouslySetInnerHTML={{ __html: "&#10006" }}
            onClick={() => removeBag(bag.barcode)}
          />
        )}
      </div>
      <BagDetail>
        {bag.itemization && bag.itemization.length > 0 ? (
          bag.itemization.map(p => {
            if (p === null) {
              return "";
            }

            const product = products.find(prod => prod.id === p.product);
            return (
              <BagDetailItem key={p.product}>
                <Name>{product ? product.name : p.product}</Name>
                <Qty>{p.quantity}</Qty>
              </BagDetailItem>
            );
          })
        ) : (
          <p>Pas de produits comptés</p>
        )}
      </BagDetail>
      <BagButton onClick={setCurrentBagCb}>Accèder au comptage</BagButton>
    </Container>
  );
};

export default BagView;
