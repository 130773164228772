import React, { FC, CSSProperties } from "react";

import { Container, Loader, Text } from "./style";

interface Props {
  children: string | JSX.Element;
  loading?: boolean;
  style?: CSSProperties;
  disabled?: boolean;
  href?: string;
}

const Button: FC<Props> = ({
  children,
  disabled = false,
  loading = false,
  style,
  href,
  ...props
}) => (
  <Container
    href={href ? href : "#"}
    style={style}
    disabled={disabled || loading}
    loading={loading}
    {...props}
  >
    {loading ? (
      <Loader>
        <img src="/img/spinner.svg" alt="Chargement..." />
      </Loader>
    ) : (
      <Text>{children}</Text>
    )}
  </Container>
);

export default Button;
