import React from "react";

import { Comment as CommentType } from "../../store/orders/types";
import OrderComment from "../order-comment";
import { OrderInfo } from "../order/style";

interface Props {
  comments: CommentType[];
}

const Comments = ({ comments }: Props) => (
  <OrderInfo>
    {comments.map(({ comment, createdAt, createdBy, id }) => (
      <OrderComment
        key={createdAt.toString()}
        commentId={id}
        content={comment}
        icon="/img/comment.svg"
        subtitle={createdAt.toString()}
        title={createdBy}
      />
    ))}
  </OrderInfo>
);

export default Comments;
