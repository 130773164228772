import { ThunkAction } from "redux-thunk";
import { AnyAction } from "redux";
import {
  get,
  post,
  remove,
  put,
  HttpResponse,
  postStatus
} from "../../utils/http";
import { ReduxDispatch } from "../../hooks/use-thunk-dispatch";
import {
  SET_AVAILABILITIES,
  SET_CHECK_AVAILABILITIES,
  PUT_AVAILABILITY_SUCCESS,
  POST_AVAILABILITY_SUCCESS,
  DELETE_AVAILABILITY_SUCCESS,
  CLONE_AVAILABILITY_SUCCESS,
  CLONE_DAILY_AVAILABILITY_SUCCESS,
  CLEAN_AVAILABILITY_SUCCESS,
  AvailabilitiesActionTypes,
  CheckAvailabilitiesActionTypes
} from "./types";
import { AppState } from "..";
import { Availability } from "../../typings/availability";
import { CheckAvailability } from "../../typings/checkAvailability";
import { ActionResponse } from "../availabilities/types";

interface GetAvailabilitiesResponse {
  availabilities: Availability[];
}

export const SetAvailabilities = (
  availabilities: Availability[]
): AvailabilitiesActionTypes => ({
  type: SET_AVAILABILITIES,
  availabilities
});

export const SetCheckAvailabilities = (
  checkAvailability: CheckAvailability[]
): CheckAvailabilitiesActionTypes => ({
  type: SET_CHECK_AVAILABILITIES,
  checkAvailability
});

const postAvailabilitySuccess = response => {
  return {
    type: POST_AVAILABILITY_SUCCESS,
    response: response
  };
};

const putAvailabilitySuccess = response => {
  return {
    type: PUT_AVAILABILITY_SUCCESS,
    response: response
  };
};

const deleteAvailabilitySuccess = response => {
  return {
    type: DELETE_AVAILABILITY_SUCCESS,
    response: response
  };
};

const cloneAvailabilitySuccess = response => {
  return {
    type: CLONE_AVAILABILITY_SUCCESS,
    response: response
  };
};

const cloneDailyAvailabilitySuccess = response => {
  return {
    type: CLONE_DAILY_AVAILABILITY_SUCCESS,
    response: response
  };
};

const cleanAvailabilitySuccess = response => {
  return {
    type: CLEAN_AVAILABILITY_SUCCESS,
    response: response
  };
};

export const getAvailabilities = (
  rangeFrom,
  rangeTo
): ThunkAction<
  Promise<HttpResponse<GetAvailabilitiesResponse>>,
  AppState,
  {},
  AnyAction
> => async (dispatch: ReduxDispatch, getState) => {
  try {
    const response = await get<GetAvailabilitiesResponse>(
      "/allAvailabilities?from=" + rangeFrom + "&to=" + rangeTo,
      getState()
    );
    if (response.parsedBody) {
      const { availabilities } = response.parsedBody;
      dispatch(SetAvailabilities(availabilities));
    }
  } catch (error) {
    return error;
  }
};

export const checkAvailabilities = (
  lat,
  lng,
  serviceClass
): ThunkAction<
  Promise<HttpResponse<CheckAvailability>>,
  AppState,
  {},
  AnyAction
> => async (dispatch: ReduxDispatch, getState) => {
  try {
    const response = await get<CheckAvailability>(
      "/availabilities?lat=" +
        lat +
        "&lng=" +
        lng +
        "&serviceClass=" +
        serviceClass,
      getState()
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const postAvailability = (availability: Availability) => async (
  dispatch: ReduxDispatch,
  getState: () => AppState
): Promise<ActionResponse> => {
  let response!: HttpResponse<Availability>;

  try {
    response = await post(`/availabilities`, availability, getState());
    if (response && response.ok && response.parsedBody) {
      dispatch(postAvailabilitySuccess("response"));
      return {
        success: true
      };
    } else {
      return {
        success: false
      };
    }
  } catch (e) {
    return {
      success: false
    };
  }
};

export const putAvailability = (
  availability: Availability,
  id: string
) => async (
  dispatch: ReduxDispatch,
  getState: () => AppState
): Promise<ActionResponse> => {
  let response!: HttpResponse<Availability>;
  try {
    response = await put(
      `/UpdateAvailabilities/` + id,
      availability,
      getState()
    );

    if (response && response.ok && response.parsedBody) {
      dispatch(putAvailabilitySuccess("response"));
      return {
        success: true
      };
    } else {
      return {
        success: false
      };
    }
  } catch (e) {
    return {
      success: false
    };
  }
};

export const deleteWeekAvailability = (
  rangeFrom: string,
  areaReference: string
) => async (
  dispatch: ReduxDispatch,
  getState: () => AppState
): Promise<ActionResponse> => {
  let response!: HttpResponse<Availability>;
  try {
    response = await postStatus(
      "/availabilitiesDeleteOneWeek/" + areaReference + "/" + rangeFrom,
      {},
      getState()
    );
    if (response && response.ok) {
      dispatch(deleteAvailabilitySuccess("response"));
      return {
        success: true
      };
    } else {
      return {
        success: false
      };
    }
  } catch (e) {
    return {
      success: false
    };
  }
};

export const deleteAvailability = (availabilityId: string) => async (
  dispatch: ReduxDispatch,
  getState: () => AppState
): Promise<ActionResponse> => {
  let response!: HttpResponse<Availability>;

  try {
    response = await remove(
      "/deleteAvailabilities/" + availabilityId,
      getState()
    );
    if (response && response.ok && response.parsedBody) {
      dispatch(deleteAvailabilitySuccess("response"));
      return {
        success: true
      };
    } else {
      return {
        success: false
      };
    }
  } catch (e) {
    return {
      success: false
    };
  }
};

export const cloneAvailability = (startWeek, areaReference) => async (
  dispatch: ReduxDispatch,
  getState: () => AppState
): Promise<ActionResponse> => {
  let response!: HttpResponse<Availability>;

  try {
    response = await postStatus(
      "/availabilitiesCloneWeekToNextWeek/" + areaReference + "/" + startWeek,
      {},
      getState()
    );
    if (response && response.ok) {
      dispatch(cloneAvailabilitySuccess("response"));
      return {
        success: true
      };
    } else {
      return {
        success: false
      };
    }
  } catch (e) {
    return {
      success: false
    };
  }
};

export const cloneDayAvailability = (
  rangeFrom,
  areaReference,
  daysToClone
) => async (
  dispatch: ReduxDispatch,
  getState: () => AppState
): Promise<ActionResponse> => {
  let response!: HttpResponse<Availability>;

  try {
    response = await postStatus(
      "/availabilitiesCloneWeekFirstDay/" + areaReference + "/" + rangeFrom,
      daysToClone,
      getState()
    );
    if (response && response.ok) {
      dispatch(cloneDailyAvailabilitySuccess("response"));
      return {
        success: true
      };
    } else {
      return {
        success: false
      };
    }
  } catch (e) {
    return {
      success: false
    };
  }
};

export const cleanAvailability = rangeToClean => async (
  dispatch: ReduxDispatch,
  getState: () => AppState
): Promise<ActionResponse> => {
  let response!: HttpResponse<Availability>;

  try {
    response = await postStatus(
      "/cleanAvailabilities/",
      rangeToClean,
      getState()
    );
    if (response && response.ok) {
      dispatch(cleanAvailabilitySuccess("response"));
      return {
        success: true
      };
    } else {
      return {
        success: false
      };
    }
  } catch (e) {
    return {
      success: false
    };
  }
};
