import styled from "styled-components";
import colors from "../../../style/colors";

export const BagButton = styled.button`
  border: none;
  cursor: pointer;
  width: 100%;
`;

export const Title = styled.div`
  color: ${colors.secondary};
`;
