import { AuthActionTypes, AuthState, LOG_IN, LOG_OUT, JwtUser } from "./types";
/* eslint-disable */
import jwt_decode from "jwt-decode";
/* eslint-enable */
const initialState: AuthState = {
  token: "",
  role: ""
};

const getRoleFromDecodedAccessToken = (token: string) => {
  try {
    const userObject = jwt_decode<JwtUser>(token);
    if (userObject && userObject.role) {
      return userObject.role;
    } else {
      return "";
    }
  } catch (Error) {
    return "";
  }
};

const authReducer = (
  state = initialState,
  action: AuthActionTypes
): AuthState => {
  switch (action.type) {
    case LOG_IN: {
      return {
        ...state,
        token: action.token,
        role: getRoleFromDecodedAccessToken(action.token)
      };
    }
    case LOG_OUT: {
      return initialState;
    }
    default:
      return state;
  }
};

export default authReducer;
