import styled from "styled-components";

export const OrderListWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  & > div {
    margin-right: 10px;
  }
  & > div:last-child {
    margin-right: 0;
  }
`;
