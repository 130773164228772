import styled from "styled-components";

export const Button = styled.button`
  background-color: #122630;
  color: white;
  font-size: 20px;
  padding: 10px 20px;
  border-radius: 5px;
  margin: 10px 10px;
  cursor: pointer;
  float: right;
`;

export const Editbutton = styled.button`
  background-color: #122630;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 2px;
  cursor: pointer;
  padding: 10px;
`;

export const SearchInput = styled.input`
  background-color: #122630;
  color: white;
  font-size: 20px;
  padding: 10px 20px;
  border-radius: 5px;
  margin: 10px 10px;
  cursor: pointer;
  float: right;
`;
