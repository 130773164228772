import { ThunkAction } from "redux-thunk";
import { AnyAction } from "redux";
import { get, post, remove, put, HttpResponse } from "../../utils/http";
import { ReduxDispatch } from "../../hooks/use-thunk-dispatch";
import {
  SET_OPERATIONAREAS,
  OperationAreasActionTypes,
  POST_OPERATIONAREA_SUCCESS,
  PUT_OPERATIONAREA_SUCCESS,
  DELETE_OPERATIONAREA_SUCCESS
} from "./types";
import { AppState } from "..";
import { OperationArea } from "../../typings/operationArea";
import { ActionResponse } from "../orders/types";

interface GetOperationAreasResponse {
  operationAreas: OperationArea[];
}

export const setOperationAreas = (
  operationAreas: OperationArea[]
): OperationAreasActionTypes => ({
  type: SET_OPERATIONAREAS,
  operationAreas
});

export const getOperationAreas = (): ThunkAction<
  Promise<HttpResponse<GetOperationAreasResponse>>,
  AppState,
  {},
  AnyAction
> => async (dispatch: ReduxDispatch, getState) => {
  try {
    const response = await get<GetOperationAreasResponse>(
      "/operationAreas",
      getState()
    );
    if (response.parsedBody) {
      const { operationAreas } = response.parsedBody;
      dispatch(setOperationAreas(operationAreas));
    }
  } catch (error) {
    return error;
  }
};

const postOperationAreaSuccess = response => {
  return {
    type: POST_OPERATIONAREA_SUCCESS,
    response: response
  };
};

const putOperationAreaSuccess = response => {
  return {
    type: PUT_OPERATIONAREA_SUCCESS,
    response: response
  };
};

const deleteOperationAreaSuccess = response => {
  return {
    type: DELETE_OPERATIONAREA_SUCCESS,
    response: response
  };
};

export const postOperationArea = (operationArea: OperationArea) => async (
  dispatch: ReduxDispatch,
  getState: () => AppState
): Promise<ActionResponse> => {
  let response!: HttpResponse<OperationArea>;

  try {
    response = await post(`/operationAreas`, operationArea, getState());
    if (response && response.ok && response.parsedBody) {
      dispatch(postOperationAreaSuccess("response"));
      return {
        success: true
      };
    } else {
      return {
        success: false
      };
    }
  } catch (e) {
    return {
      success: false
    };
  }
};

export const putOperationArea = (operationArea: OperationArea) => async (
  dispatch: ReduxDispatch,
  getState: () => AppState
): Promise<ActionResponse> => {
  let response!: HttpResponse<OperationArea>;

  try {
    response = await put(`/operationAreas`, operationArea, getState());
    if (response && response.ok && response.parsedBody) {
      dispatch(putOperationAreaSuccess("response"));
      return {
        success: true
      };
    } else {
      return {
        success: false
      };
    }
  } catch (e) {
    return {
      success: false
    };
  }
};

export const deleteOperationArea = (operationAreaId: string) => async (
  dispatch: ReduxDispatch,
  getState: () => AppState
): Promise<ActionResponse> => {
  let response!: HttpResponse<OperationArea>;

  try {
    response = await remove("/operationAreas/" + operationAreaId, getState());
    if (response && response.ok && response.parsedBody) {
      dispatch(deleteOperationAreaSuccess("response"));
      return {
        success: true
      };
    } else {
      return {
        success: false
      };
    }
  } catch (e) {
    return {
      success: false
    };
  }
};
