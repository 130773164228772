import styled from "styled-components";
import colors from "../../../style/colors";
import { InvisibleButton } from "../../../ui/button/invisible-button";

export const Select = styled.select`
  height: 32px;
  padding: 10px;
  font-size: 18px !important;
  display: inline-block;
`;

export const Header = styled.div`
  align-items: center;
  background-color: ${colors.primary};
  color: ${colors.white};
  display: flex;
  flex-direction: row;
  height: 80px;
  justify-content: space-between;
  position: relative;
  padding: 0 50px;
  width: 100%;
`;

export const Left = styled.div`
  img {
    height: auto;
    width: 30px;
  }

  @media print {
    display: none;
  }
`;

export const Center = styled.div`
  * {
    display: inline-block;
  }

  @media print {
    display: none;
  }
`;

export const Title = styled.h1`
  color: ${colors.white};
  font-family: Montserrat;
  font-size: 36px;
  font-weight: normal;
  line-height: 44px;

  @media print {
    font-size: 17px;
  }
`;

export const Right = styled.div`
  @media print {
    display: none;
  }
`;

export const ContentWrapper = styled.div`
  height: 100%;
  margin: 0;
`;

export const LogoutButton = styled(InvisibleButton)`
  height: 30px;
  width: 30px;
`;

interface WrapperProps {
  fixed: boolean;
}

export const Wrapper = styled.div<WrapperProps>`
  ${({ fixed }): string =>
    fixed ? "position: fixed; height: 100%; width: 100%" : ""};
`;
