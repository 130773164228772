import { OrderType } from "../../store/orders/types";
import OrderInfoPiece from "../order-info-piece";
import React, { FC } from "react";

import { Block, BlockTitle, OrderInfo } from "./style";
import { Wrapper } from "./style/order-information";

const getItemTotals = (order: OrderType) => {
  let sum = 0;
  order.itemization.bags.forEach(b => {
    b.itemization.forEach(i => {
      sum += i.quantity;
    });
  });
  return sum;
};

interface OrderInformationProps {
  order: OrderType;
}

const OrderInformation: FC<OrderInformationProps> = ({ order }) => (
  <Wrapper>
    <Block>
      <OrderInfo>
        <BlockTitle>Commande</BlockTitle>
        <OrderInfoPiece
          printTextSmall
          icon="/img/order-number.svg"
          content={order.id}
        />
        <OrderInfoPiece
          icon="/img/profile.svg"
          content={order.customer}
          printTextLarge
        />
        {order.corporate ? (
          <OrderInfoPiece
            icon="/img/office.svg"
            content={order.corporate.name}
          />
        ) : null}
        <OrderInfoPiece
          icon="/img/star.svg"
          content={order.serviceClass.toUpperCase()}
          printTextLarge
        />
        <OrderInfoPiece
          icon="/img/bag.svg"
          content={`Total articles: ${getItemTotals(order)}`}
        />
      </OrderInfo>
    </Block>
  </Wrapper>
);

export default OrderInformation;
