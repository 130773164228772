import React, { FunctionComponent, useState, useEffect } from "react";
import useDispatch from "../../hooks/use-thunk-dispatch";
import { AppState } from "../../store";
import { getServiceClasses } from "../../store/serviceClasses/actions";
import { ServiceClasse } from "../../typings/serviceClasse";
import { useSelector } from "react-redux";
import Select from "react-select";
import { List, ListItem, ListHeader } from "react-scrollable-accordion";
import { checkAvailabilities } from "../../store/availabilities/actions";
import { OperationArea } from "../../typings/operationArea";
import {
  Message,
  Label,
  Button,
  SpanBox,
  ButtonContainer,
  ResultContainer,
  ScrollableList
} from "./style";
import { CheckAvailability } from "../../typings/checkAvailability";
import moment from "moment";
/* eslint-disable */
let polygonCenter = require("geojson-polygon-center");

interface CheckModalProps {
  message: string;
}

export const CheckModal: FunctionComponent<CheckModalProps> = props => {
  const dispatch = useDispatch();
  const [pending, setPending] = React.useState(true);
  const [checking, setChecking] = React.useState(false);
  const [checkProgress, setCheckProgress] = React.useState(false);
  const [isAvailable, setIsAvailable] = React.useState(false);

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      setPending(true);
      await dispatch(getServiceClasses());
      setPending(false);
    };
    fetchData();
  }, []);

  const serviceClasses: ServiceClasse[] = useSelector<
    AppState,
    ServiceClasse[]
  >(state => state.serviceClasses.allServiceClasses);
  const [serviceClass, setServiceClass] = useState("");
  const [checkAvailability, setCheckAvailability] = useState(
    {} as CheckAvailability
  );

  const operationAreaId = sessionStorage.getItem("operationAreaId");

  const operationArea = useSelector<AppState, OperationArea>(state => {
    return state.operationAreas.allOperationAreas.find(
      item => item.id === operationAreaId
    ) as OperationArea;
  });
  const coordinates = operationArea.location.coordinates[0];

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      let center = polygonCenter(operationArea.location);
      let lat = center.coordinates[1];
      let lng = center.coordinates[0];
      setPending(true);
      if (serviceClass && lat && lng) {
        setCheckProgress(true);
        let response = await dispatch(
          checkAvailabilities(lat, lng, serviceClass)
        );
        if (response.ok) {
          setCheckAvailability(response.parsedBody as CheckAvailability);
          setIsAvailable(true);
        } else {
          setCheckAvailability({
            location: {
              lat: 0,
              lng: 0
            },
            isAvailable: false,
            pickupTimeslots: [
              {
                startTime: "",
                endTime: "",
                dropoffLeadTimeInMins: 0
              }
            ],
            dropoffTimeslots: [
              {
                startTime: "",
                endTime: ""
              }
            ]
          });
          setIsAvailable(true);
        }
        setCheckProgress(false);
      }
      setCheckProgress(false);

      setChecking(false);
      setPending(false);
    };
    if (checking) {
      fetchData();
    }
  }, [dispatch, checking]);

  const handleCheckClick = e => {
    setChecking(true);
  };

  return (
    <React.Fragment>
      <Message>{props.message}</Message>
      <Label>Service</Label>
      <Select
        onChange={e =>
          setServiceClass(e == null ? serviceClasses[0].id : e.value)
        }
        options={serviceClasses.map(e => ({
          label: e.name,
          value: e.id
        }))}
        value={serviceClasses
          .map(e => ({
            label: e.name,
            value: e.id
          }))
          .find(s => s.value == serviceClass)}
      />
      <ButtonContainer>
        <Button onClick={handleCheckClick}>
          {checkProgress ? "Vérification en cours ..." : "Vérifier"}
        </Button>
      </ButtonContainer>
      <SpanBox>
        {isAvailable
          ? checkAvailability.isAvailable
            ? "La zone " + operationArea.name + " est disponible"
            : "La zone " + operationArea.name + " est indisponible"
          : ""}
      </SpanBox>

      <ResultContainer>
        <ScrollableList>
          <h4>Créneaux horaire de dépot</h4>
          <List stickyHeaders scrollBehavior="smooth">
            {checkAvailability.isAvailable
              ? checkAvailability.pickupTimeslots.map(
                  ({ startTime, endTime, dropoffLeadTimeInMins }) => (
                    <ListItem key={""}>
                      de {moment(startTime).format("DD.MM.YYYY HH:mm")} à :{" "}
                      {moment(endTime).format("DD.MM.YYYY HH:mm")}
                    </ListItem>
                  )
                )
              : ""}
          </List>
        </ScrollableList>
        <ScrollableList>
          <span>
            <h4>Plages horaire de ramassage</h4>
          </span>
          <List stickyHeaders scrollBehavior="smooth">
            {checkAvailability.isAvailable
              ? checkAvailability.dropoffTimeslots.map(
                  ({ startTime, endTime }) => (
                    <ListItem key={""}>
                      de {moment(startTime).format("DD.MM.YYYY HH:mm")} à :{" "}
                      {moment(endTime).format("DD.MM.YYYY HH:mm")}
                    </ListItem>
                  )
                )
              : ""}
          </List>
        </ScrollableList>
      </ResultContainer>
    </React.Fragment>
  );
};
