import { ThunkAction } from "redux-thunk";
import { AnyAction } from "redux";
import { get, post, remove, put, HttpResponse } from "../../utils/http";
import { ReduxDispatch } from "../../hooks/use-thunk-dispatch";
import {
  SET_TIMESLOTS,
  SET_SERVICECLASSES,
  TimeslotActionTypes,
  PUT_TIMESLOT_SUCCESS,
  POST_TIMESLOT_SUCCESS,
  DELETE_TIMESLOT_SUCCESS
} from "./types";
import { AppState } from "..";
import { ServiceClasse } from "../../typings/serviceClasse";
import { Timeslot } from "../../typings/timeslot";
import { ActionResponse } from "../timeslot/types";

interface GetServiceClassesResponse {
  serviceClasses: ServiceClasse[];
}

interface GetTimeSlotsResponse {
  timeslots: Timeslot[];
}

export const SetServiceClasse = (
  serviceClasses: ServiceClasse[]
): TimeslotActionTypes => ({
  type: SET_SERVICECLASSES,
  serviceClasses
});

export const SetTimeSlot = (timeslots: Timeslot[]): TimeslotActionTypes => ({
  type: SET_TIMESLOTS,
  timeslots
});

const postTimeslotSuccess = response => {
  return {
    type: POST_TIMESLOT_SUCCESS,
    response: response
  };
};

const putTimeslotSuccess = response => {
  return {
    type: PUT_TIMESLOT_SUCCESS,
    response: response
  };
};

const deleteTimeslotSuccess = response => {
  return {
    type: DELETE_TIMESLOT_SUCCESS,
    response: response
  };
};

export const getServiceClasses = (): ThunkAction<
  Promise<HttpResponse<GetServiceClassesResponse>>,
  AppState,
  {},
  AnyAction
> => async (dispatch: ReduxDispatch, getState) => {
  try {
    const response = await get<GetServiceClassesResponse>(
      "/service-classes",
      getState()
    );
    if (response.parsedBody) {
      const { serviceClasses } = response.parsedBody;
      dispatch(SetServiceClasse(serviceClasses));
    }
  } catch (error) {
    return error;
  }
};

export const getTimeSlots = (): ThunkAction<
  Promise<HttpResponse<GetTimeSlotsResponse>>,
  AppState,
  {},
  AnyAction
> => async (dispatch: ReduxDispatch, getState) => {
  try {
    const response = await get<GetTimeSlotsResponse>("/timeslots", getState());
    if (response.parsedBody) {
      const { timeslots } = response.parsedBody;
      dispatch(SetTimeSlot(timeslots));
    }
  } catch (error) {
    return error;
  }
};

export const postTimeslot = (timeslot: Timeslot) => async (
  dispatch: ReduxDispatch,
  getState: () => AppState
): Promise<ActionResponse> => {
  let response!: HttpResponse<Timeslot>;

  try {
    response = await post(`/timeslots`, timeslot, getState());
    if (response && response.ok && response.parsedBody) {
      dispatch(postTimeslotSuccess("response"));
      return {
        success: true
      };
    } else {
      return {
        success: false
      };
    }
  } catch (e) {
    return {
      success: false
    };
  }
};

export const putTimeslot = (timeslot: Timeslot, id: string) => async (
  dispatch: ReduxDispatch,
  getState: () => AppState
): Promise<ActionResponse> => {
  let response!: HttpResponse<Timeslot>;
  try {
    response = await put(`/timeslots/` + id, timeslot, getState());

    if (response && response.ok && response.parsedBody) {
      dispatch(putTimeslotSuccess("response"));
      return {
        success: true
      };
    } else {
      return {
        success: false
      };
    }
  } catch (e) {
    return {
      success: false
    };
  }
};

export const deleteTimeslot = (timeslotId: string) => async (
  dispatch: ReduxDispatch,
  getState: () => AppState
): Promise<ActionResponse> => {
  let response!: HttpResponse<Timeslot>;

  try {
    response = await remove("/timeslots/" + timeslotId, getState());
    if (response && response.ok && response.parsedBody) {
      dispatch(deleteTimeslotSuccess("response"));
      return {
        success: true
      };
    } else {
      return {
        success: false
      };
    }
  } catch (e) {
    return {
      success: false
    };
  }
};
