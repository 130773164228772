import React, { useCallback } from "react";
import { useDispatch } from "react-redux";

import { addProduct, removeProduct } from "../../store/orders/actions";

import {
  ProductContainer,
  ProductName,
  Quantity,
  QuantityModifier,
  QuantityModifierContainer
} from "./style";

interface Props {
  id: string;
  name: string;
  quantity: number;
  editMode: boolean;
}

const Product = ({ id, name, quantity, editMode }: Props) => {
  const dispatch = useDispatch();

  const hasQuantity = quantity > 0;

  const add = useCallback(() => {
    dispatch(addProduct(id, quantity));
  }, [dispatch, id, quantity]);

  const remove = useCallback(() => {
    dispatch(removeProduct(id, quantity));
  }, [dispatch, id, quantity]);

  return (
    <ProductContainer key={id}>
      <ProductName>{name}</ProductName>
      {editMode || hasQuantity ? (
        <QuantityModifierContainer>
          {editMode ? (
            <QuantityModifier onClick={add}>+</QuantityModifier>
          ) : null}
          {quantity > 0 ? (
            <Quantity>{quantity}</Quantity>
          ) : (
            <Quantity>0</Quantity>
          )}
          {editMode ? (
            <QuantityModifier onClick={remove}>-</QuantityModifier>
          ) : null}
        </QuantityModifierContainer>
      ) : null}
    </ProductContainer>
  );
};

export default Product;
