import { AppState } from "../../store";
import Cart from "./cart";
import OrderInformation from "./order-information";
import { OrderType } from "../../store/orders/types";
import PickupDropoffInfo from "./pickup-dropoff";
import React from "react";
import { RouteComponentProps } from "react-router";
import { useSelector } from "react-redux";
import QRCode from "qrcode.react";

import {
  Layout,
  LayoutContent,
  Button,
  ButtonContainer,
  QRCodeContainer
} from "./style";

interface MatchParams {
  id: string;
}

const OrderConfirmation = ({
  match: {
    params: { id: orderId }
  }
}: RouteComponentProps<MatchParams>) => {
  const order = useSelector<AppState, OrderType | undefined>(state =>
    state.orders.orders.find(o => o.id === orderId)
  );

  if (!order) return <span>Erreur lors du chargement de la commande</span>;

  return (
    <Layout title="Confirmation de commande" fixed>
      <LayoutContent>
        <ButtonContainer>
          <Button onClick={() => window.print()}>Imprimer</Button>
          <Button onClick={() => console.log("click")}>Valider</Button>
        </ButtonContainer>

        <QRCodeContainer>
          <QRCode value={orderId} size={144} />
        </QRCodeContainer>

        <OrderInformation order={order} />
        <PickupDropoffInfo order={order} />

        <Cart order={order} showPrice={false} />
      </LayoutContent>
    </Layout>
  );
};

export default OrderConfirmation;
