import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import Layout from "../layout";
import useDispatch from "../../hooks/use-thunk-dispatch";
import DataTable from "react-data-table-component";
import { getTimeSlots, getServiceClasses } from "../../store/timeslot/actions";
import { useToast, TOAST_TYPE } from "../../ui/toast";
import { ServiceClasse, ServiceClasseMap } from "../../typings/serviceClasse";
import { Timeslot } from "../../typings/timeslot";
import { AppState } from "../../store";
import Select from "react-select";
import { Link } from "react-router-dom";
import { Button, Editbutton, SearchInput } from "./style";
import { FaEdit } from "react-icons/fa";

const options = [
  { value: "0", label: "Dimanche" },
  { value: "1", label: "Lundi" },
  { value: "2", label: "Mardi" },
  { value: "3", label: "Mercredi" },
  { value: "4", label: "Jeudi" },
  { value: "5", label: "Vendredi" },
  { value: "6", label: "Samedi" }
];

const FilterComponent = ({ filterText, onFilter }) => (
  <>
    <SearchInput
      id="search"
      placeholder="Rechercher..."
      type="text"
      value={filterText}
      onChange={onFilter}
    />
  </>
);

const Timeslots = () => {
  const dispatch = useDispatch();
  const [pending, setPending] = React.useState(true);
  const [filterText, setFilterText] = React.useState("");

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      setPending(true);
      await dispatch(getServiceClasses());
      await dispatch(getTimeSlots());

      setPending(false);
    };

    fetchData();
  }, [dispatch]);

  const data: Timeslot[] = useSelector<AppState, Timeslot[]>(
    state => state.timeslots.allTimeslots
  );

  const serviceClasses: ServiceClasse[] = useSelector<
    AppState,
    ServiceClasse[]
  >(state => state.serviceClasses.allServiceClasses);

  const columns = [
    {
      name: "Journées",
      selector: "days",
      cell: row => {
        return (function Actions() {
          return (
            <Select
              isDisabled
              isMulti={true}
              value={options.filter(o => row.days.indexOf(o.value) > -1)}
            />
          );
        })();
      },
      sortable: false,
      width: "20%"
    },
    {
      name: "Délai de livraison",
      selector: "dropoffLeadTimeInMins",
      sortable: true
    },
    {
      name: "Commence à",
      selector: "startsAt",
      sortable: true,
      width: "10%"
    },
    {
      name: "Fini à",
      selector: "endsAt",
      sortable: true
    },
    {
      name: "Actif",
      selector: d => d.isActive,
      cell: row => {
        return (function change() {
          if (row.isActive) {
            return "oui";
          } else {
            return "non";
          }
        })();
      },
      sortable: true
    },
    {
      name: "Type",
      selector: d => d.isPickup.toString(),
      cell: row => {
        return (function change() {
          if (row.isPickup) {
            return "Ramassage";
          } else {
            return "Dépot";
          }
        })();
      },
      sortable: true
    },
    {
      name: "Service",
      selector: row => {
        return (function Actions() {
          let serviceClasse = serviceClasses.find(
            s => s._id === row.serviceClass
          ) as ServiceClasse;
          return serviceClasse ? serviceClasse.name : "-";
        })();
      },
      cell: row => {
        return (function Actions() {
          let serviceClasse = serviceClasses.find(
            s => s._id === row.serviceClass
          ) as ServiceClasse;
          return serviceClasse ? serviceClasse.name : "-";
        })();
      },
      sortable: true
    },
    {
      name: "Date de création",
      selector: d => d.createdAt as Date,
      sortable: true,
      width: "12%"
    },
    {
      name: "Dernier mis à jour",
      selector: "updatedAt",
      sortable: true,
      width: "12%"
    },
    {
      name: "action",
      selector: "id",
      cell: row => {
        return (function Actions() {
          return (
            <Link to={"timeslot/edit/" + row.id}>
              <Editbutton>
                <FaEdit></FaEdit>
              </Editbutton>
            </Link>
          );
        })();
      }
    }
  ];

  const filteredItems = data.filter(item => {
    let serviceClasse = serviceClasses.find(
      serviceClasse => serviceClasse._id === item.serviceClass
    ) as ServiceClasse;
    if (
      serviceClasse &&
      serviceClasse.name
        .toString()
        .toLowerCase()
        .includes(filterText.toLowerCase())
    ) {
      return item;
    }

    return "";
  });

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setFilterText("");
      }
    };
  }, [filterText]);

  return (
    <Layout title="Tranches de temps " showSearchBag={false}>
      <div className="App">
        <Link to="/timeslot/edit">
          <Button>Ajouter une nouvelle tranche de temps</Button>
        </Link>
        <FilterComponent
          onFilter={e => setFilterText(e.target.value)}
          filterText={filterText}
        />
        <DataTable
          title="Tranches de temps"
          columns={columns}
          data={filteredItems}
          defaultSortField="title"
          pagination
          responsive
          progressPending={pending}
        />
      </div>
    </Layout>
  );
};

export default Timeslots;
