import { AppState } from "../store";

export interface HttpResponse<T> extends Response {
  parsedBody?: T;
  error?: Error;
}

interface Body {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

export interface Error {
  request: string;
  code: string;
  message: string;
}

const baseUrl = process.env.REACT_APP_API_URL || "https://api.test.wast.fr/v1";
// const baseUrl = "http://localhost:4000";

export const http = <T>(
  path: string,
  state: AppState,
  args: RequestInit
): Promise<HttpResponse<T>> => {
  const { token } = state.auth;

  const request = new Request(`${baseUrl}${path}`, {
    ...args,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...(token && { Authorization: `Bearer ${token}` })
    }
  });

  return new Promise((resolve, reject) => {
    let response: HttpResponse<T>;

    fetch(request)
      .then(res => {
        response = res;
        return res.json();
      })
      .then(body => {
        if (response.ok) {
          response.parsedBody = body;
          resolve(response);
        } else {
          response.error = body;
          reject(response);
        }
      })
      .catch(err => {
        reject(err);
      });
  });
};

export const get = <T>(
  path: string,
  state: AppState,
  args: RequestInit = {}
): Promise<HttpResponse<T>> => {
  return http<T>(path, state, { ...args, method: "get" });
};

export const remove = <T>(
  path: string,
  state: AppState,
  args: RequestInit = {}
): Promise<HttpResponse<T>> => {
  return http<T>(path, state, { ...args, method: "delete" });
};

export const post = <T>(
  path: string,
  body: Body,
  state: AppState,
  args: RequestInit = {}
): Promise<HttpResponse<T>> => {
  return http<T>(path, state, {
    ...args,
    method: "post",
    body: JSON.stringify(body)
  });
};

export const put = <T>(
  path: string,
  body: Body,
  state: AppState,
  args: RequestInit = {}
): Promise<HttpResponse<T>> => {
  return http<T>(path, state, {
    ...args,
    method: "put",
    body: JSON.stringify(body)
  });
};

export const httpStatus = <T>(
  path: string,
  state: AppState,
  args: RequestInit
): Promise<HttpResponse<T>> => {
  const { token } = state.auth;

  const request = new Request(`${baseUrl}${path}`, {
    ...args,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...(token && { Authorization: `Bearer ${token}` })
    }
  });

  return new Promise((resolve, reject) => {
    let response: HttpResponse<T>;
    fetch(request)
      .then(res => {
        response = res;
        return res;
      })
      .then(body => {
        if (response.ok) {
          resolve(response);
        } else {
          reject(response);
        }
      })
      .catch(err => {
        reject(err);
      });
  });
};

export const postStatus = <T>(
  path: string,
  body: Body,
  state: AppState,
  args: RequestInit = {}
): Promise<HttpResponse<T>> => {
  return httpStatus<T>(path, state, {
    ...args,
    method: "post",
    body: JSON.stringify(body)
  });
};
