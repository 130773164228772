import React, { FunctionComponent, useState, useCallback } from "react";
import useDispatch from "../../hooks/use-thunk-dispatch";
import moment from "moment";

import {
  ConfirmationButtons,
  Message,
  YesButton,
  NoButton,
  Wrapper,
  Input
} from "./style";
import { useModal } from "../modal/useModal";

interface ConfirmationModalProps {
  onCancel: () => void;
  onConfirm: () => void;
  message: string;
  cloneUntilWeek: string | null;
}

export const ConfirmationModal: FunctionComponent<ConfirmationModalProps> = props => {
  const [cloneUntilWeek, setCloneUntilWeek] = useState(
    moment(props.cloneUntilWeek).add(1, "week")
  );
  const dispatch = useDispatch();

  sessionStorage.setItem(
    "cloneUntilWeek",
    cloneUntilWeek ? cloneUntilWeek.format("GGGG-[W]WW") : ""
  );

  let minDate = moment(sessionStorage.getItem("SelectedWeek")).add(1, "week");
  return (
    <React.Fragment>
      <Message>{props.message}</Message>
      <Input
        style={{
          width: "200px"
        }}
        type="week"
        value={
          cloneUntilWeek ? moment(cloneUntilWeek).format("GGGG-[W]WW") : ""
        }
        onChange={e => setCloneUntilWeek(moment(e.target.value))}
        min={minDate ? minDate.format("GGGG-[W]WW") : ""}
      ></Input>
      <p>
        Attention : le clone supprime toutes les disponibilités de la semaine.
      </p>
      <ConfirmationButtons>
        <YesButton onClick={props.onConfirm}>Oui</YesButton>
        <NoButton onClick={props.onCancel}>Non</NoButton>
      </ConfirmationButtons>
    </React.Fragment>
  );
};
