import React, {
  FunctionComponent,
  useState,
  useEffect,
  useCallback
} from "react";
import { useHistory } from "react-router-dom";
import { useToast } from "../toast";
import { postTask } from "../../store/tasks/actions";

import moment from "moment";

import {
  Message,
  Label,
  Input,
  Textarea,
  AdressContainer,
  StreetInput,
  NumberInput,
  CityInput,
  CountryInput,
  LocationInput,
  Container,
  ButtonAction,
  NameInput,
  NoteInput,
  TelInput,
  CompleteInput
} from "./style";
import { OnfleetTask, Tasks } from "../../typings/tasks";
import useDispatch from "../../hooks/use-thunk-dispatch";

interface ConfirmationModalProps {
  onConfirm: () => void;
  task: OnfleetTask;
}

export const TasksModal: FunctionComponent<ConfirmationModalProps> = props => {
  // const storagetask = sessionStorage.getItem("task");
  const dispatch = useDispatch();

  // const task = storagetask ? JSON.parse(storagetask) : ({} as OnfleetTask);

  const [onfleetTask, setonfleetTask] = useState(props.task);
  const [notes, setNotes] = useState(onfleetTask["notes"]);
  onfleetTask["notes"] = notes;
  const [inprogress, setInprogress] = React.useState(false);
  // sessionStorage.setItem("updatedOnfleetTask", JSON.stringify(onfleetTask));

  let completeafter = moment(onfleetTask.completeAfter).format(
    "YYYY-MM-DD, hh:mm "
  );
  let completebefore = moment(onfleetTask.completeBefore).format(
    "YYYY-MM-DD, hh:mm "
  );

  return (
    <React.Fragment>
      <Message>
        {onfleetTask.pickupTask
          ? "Tache de récupération"
          : "Tache de livraison"}
      </Message>

      <Label>Adresse:</Label>
      <AdressContainer>
        <NumberInput value={onfleetTask.destination.address.number} readOnly />
        <StreetInput value={onfleetTask.destination.address.street} readOnly />
        <CityInput value={onfleetTask.destination.address.city} readOnly />
        <CountryInput
          value={onfleetTask.destination.address.country}
          readOnly
        />
      </AdressContainer>

      <Container>
        <Label>Location</Label>
        <LocationInput
          value={onfleetTask.destination.location.toString()}
          readOnly
        />
        <Label>Notes</Label>
        <NoteInput value={notes} onChange={e => setNotes(e.target.value)} />
      </Container>

      <Label>Destinataires:</Label>
      <Container>
        <Label>Nom</Label>
        <NameInput value={onfleetTask.recipients[0].name} readOnly />

        <Label>Tel</Label>
        <TelInput value={onfleetTask.recipients[0].phone} readOnly />
      </Container>

      <Container>
        <Label>Terminé après</Label>
        <CompleteInput value={completeafter} readOnly />

        <Label>Terminer avant</Label>
        <CompleteInput value={completebefore} readOnly />
      </Container>
      <Container>
        <Label>Métadonnées:</Label>
        <Label>order</Label>
        <Input value={onfleetTask.metadata[0].value} readOnly />

        <Label>Service</Label>
        <Input value={onfleetTask.metadata[1].value} readOnly />

        <Label>Commande</Label>
        <Input value={onfleetTask.metadata[2].value} readOnly />
      </Container>
      <ButtonAction
        // onClick={() => handleSubmit(onfleetTask)}
        onClick={props.onConfirm}
        disabled={inprogress}
      >
        {inprogress ? "En cours..." : "Confirmer"}
      </ButtonAction>
    </React.Fragment>
  );
};
