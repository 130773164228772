import React, { useEffect, useMemo, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import Board from "react-trello";
import moment from "moment";

import { AppState } from "../../store";
import { useToast } from "../toast";
import useDispatch from "../../hooks/use-thunk-dispatch";
import {
  getOrders,
  setPage,
  changeDates,
  endCleaning,
  startRecleaning,
  clearBag,
  clearSearchedOrder,
  setSearchedOrder
} from "../../store/orders/actions";
import Layout from "../layout";
import {
  OrderType,
  CleaningStatus,
  OrderStatus
} from "../../store/orders/types";
import { Product as ProductType } from "../../typings/product";
import useWindowDimensions from "../../hooks/use-window-dimensions";

interface Orders {
  orders: OrderType[];
}

interface Accumulator {
  [key: string]: OrderType[];
}

const Orders = () => {
  const history = useHistory();
  const page = useSelector<AppState, number>(state => state.orders.page);
  const totalPages = useSelector<AppState, number>(
    state => state.orders.totalPages
  );
  const { showToast } = useToast();
  const { width } = useWindowDimensions();
  const orders = useSelector<AppState, OrderType[]>(
    state => state.orders.orders
  );
  const products = useSelector<AppState, ProductType[]>(
    state => state.products.allProducts
  );
  const [loading, setLoading] = useState(true);
  const [eventBus, setEventBus] = useState<any>(undefined);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchOrders = async () => {
      setLoading(true);
      dispatch(
        changeDates(
          moment()
            .add(1, "d")
            .startOf("day")
            .unix() +
            "-" +
            moment()
              .add(1, "d")
              .startOf("day")
              .add(12, "h")
              .unix()
        )
      );
      dispatch(getOrders());
      setLoading(false);
    };

    dispatch(setPage(1));
    fetchOrders();
  }, [dispatch]);

  useEffect(() => {
    if (loading) return;

    if (page === totalPages || totalPages < 1) {
      return;
    }

    if (page < totalPages) {
      dispatch(setPage(page + 1));
      dispatch(getOrders(undefined, true));
      setLoading(false);
    }
  }, [dispatch, page, totalPages, loading, setLoading]);

  const ordersReducer = (acc: Accumulator, val: OrderType) => {
    let status = val.cleaning.status;
    const itemizationStatus = val.itemization.status;
    const cleaningStatus = val.cleaning.status;

    // I want to do this hack in case the facility is changing the itemization after the cleaning is completed
    if (itemizationStatus === "IN_PROGRESS") {
      status = CleaningStatus.BEING_PROCESSED;
    }

    if (cleaningStatus === "RECLEANING") {
      status = CleaningStatus.BEING_RECLEANED;
    }

    if (
      val.status === OrderStatus.FAILED_PICKUP ||
      val.status === OrderStatus.NOSHOW_PICKUP
    ) {
      return acc;
    }

    const statusOrders = acc[status];

    if (statusOrders) {
      acc[status].push(val);
      return acc;
    }

    return acc;
  };

  const clickOrderAction = useCallback(
    (cardId: string) => {
      dispatch(clearBag());
      const order = orders.find(o => o.id === cardId);
      if (order) dispatch(setSearchedOrder(order));
      history.push(`/order/${cardId}`);
    },
    [dispatch, history, orders]
  );

  const moveOrderAction = useCallback(
    async (
      fromLaneId: string,
      toLaneId: string,
      cardId: string,
      index: number
    ) => {
      // from cleaning to ready
      if (
        fromLaneId === CleaningStatus.BEING_PROCESSED &&
        toLaneId === CleaningStatus.READY_FOR_DROPOFF
      ) {
        confirmAlert({
          title: "Confirmation",
          message: "Passer la commande en nettoyage terminé",
          closeOnClickOutside: false,
          closeOnEscape: false,
          buttons: [
            {
              label: "Oui",
              onClick: async () => {
                const response = await dispatch(
                  endCleaning({ orderId: cardId }, false)
                );
                if (response.success) {
                  dispatch(clearBag());
                  dispatch(clearSearchedOrder());
                } else {
                  showToast(response.error);
                }
              }
            },
            {
              label: "Non",
              onClick: () => {
                if (
                  eventBus !== undefined &&
                  eventBus !== null &&
                  eventBus.publish
                ) {
                  eventBus.publish({
                    type: "MOVE_CARD",
                    fromLaneId: CleaningStatus.READY_FOR_DROPOFF,
                    toLaneId: CleaningStatus.BEING_PROCESSED,
                    cardId: cardId,
                    index
                  });
                }
              }
            }
          ]
        });
      } else if (
        // from cleaning to recleaning
        fromLaneId === CleaningStatus.BEING_PROCESSED &&
        toLaneId === CleaningStatus.BEING_RECLEANED
      ) {
        confirmAlert({
          title: "Confirmation",
          message: "Passer la commande en renettoyage",
          closeOnEscape: false,
          closeOnClickOutside: false,
          buttons: [
            {
              label: "Oui",
              onClick: async () => {
                const response = await dispatch(
                  startRecleaning({ orderId: cardId }, false)
                );
                if (response.success) {
                  dispatch(clearBag());
                  dispatch(clearSearchedOrder());
                } else {
                  showToast(response.error);
                }
              }
            },
            {
              label: "Non",
              onClick: () => {
                if (
                  eventBus !== undefined &&
                  eventBus !== null &&
                  eventBus.publish
                ) {
                  eventBus.publish({
                    type: "MOVE_CARD",
                    fromLaneId: CleaningStatus.BEING_RECLEANED,
                    toLaneId: CleaningStatus.BEING_PROCESSED,
                    cardId: cardId,
                    index
                  });
                }
              }
            }
          ]
        });
      } else if (
        // from recleaning to ready
        fromLaneId === CleaningStatus.BEING_RECLEANED &&
        toLaneId === CleaningStatus.READY_FOR_DROPOFF
      ) {
        confirmAlert({
          title: "Confirmation",
          message: "Passer la commande en nettoyage terminé",
          closeOnEscape: false,
          closeOnClickOutside: false,
          buttons: [
            {
              label: "Oui",
              onClick: async () => {
                const response = await dispatch(
                  endCleaning({ orderId: cardId }, false)
                );
                if (response.success) {
                  dispatch(clearBag());
                  dispatch(clearSearchedOrder());
                } else {
                  showToast(response.error);
                }
              }
            },
            {
              label: "Non",
              onClick: () => {
                if (
                  eventBus !== undefined &&
                  eventBus !== null &&
                  eventBus.publish
                ) {
                  eventBus.publish({
                    type: "MOVE_CARD",
                    fromLaneId: CleaningStatus.READY_FOR_DROPOFF,
                    toLaneId: CleaningStatus.BEING_RECLEANED,
                    cardId: cardId,
                    index
                  });
                }
              }
            }
          ]
        });
      } else if (
        // from ready to cleaning
        fromLaneId === CleaningStatus.READY_FOR_DROPOFF &&
        toLaneId === CleaningStatus.BEING_PROCESSED
      ) {
        eventBus!.publish({
          type: "MOVE_CARD",
          fromLaneId: CleaningStatus.BEING_PROCESSED,
          toLaneId: CleaningStatus.READY_FOR_DROPOFF,
          cardId: cardId,
          index
        });
      } else if (
        // from recleaning to cleaning
        fromLaneId === CleaningStatus.BEING_RECLEANED &&
        toLaneId === CleaningStatus.BEING_PROCESSED
      ) {
        eventBus!.publish({
          type: "MOVE_CARD",
          fromLaneId: CleaningStatus.BEING_PROCESSED,
          toLaneId: CleaningStatus.BEING_RECLEANED,
          cardId: cardId,
          index
        });
      } else if (
        // from ready for dropoff to recleaning
        fromLaneId === CleaningStatus.READY_FOR_DROPOFF &&
        toLaneId === CleaningStatus.BEING_RECLEANED
      ) {
        eventBus!.publish({
          type: "MOVE_CARD",
          fromLaneId: CleaningStatus.BEING_RECLEANED,
          toLaneId: CleaningStatus.READY_FOR_DROPOFF,
          cardId: cardId,
          index
        });
      }
    },
    [dispatch, eventBus]
  );

  const filteredOrders = useMemo(
    () =>
      orders
        ? orders.reduce(ordersReducer, {
            [CleaningStatus.WAITING]: [],
            [CleaningStatus.BEING_PROCESSED]: [],
            [CleaningStatus.READY_FOR_DROPOFF]: [],
            [CleaningStatus.BEING_RECLEANED]: []
          })
        : {
            [CleaningStatus.WAITING]: [],
            [CleaningStatus.BEING_PROCESSED]: [],
            [CleaningStatus.READY_FOR_DROPOFF]: [],
            [CleaningStatus.BEING_RECLEANED]: []
          },
    [orders]
  );

  const generateCardOrder = (order: OrderType, laneId: string) => {
    const productList: string[] = [];
    for (let i = 0; i < order.itemization.bags.length; i += 1) {
      for (
        let j = 0;
        j < order.itemization.bags[i].itemization.length;
        j += 1
      ) {
        if (
          order.itemization.bags[i] &&
          order.itemization.bags[i].itemization[j]
        ) {
          const product = products.find(
            prod => prod.id === order.itemization.bags[i].itemization[j].product
          );
          productList.push(
            product
              ? product.name
              : order.itemization.bags[i].itemization[j].product
          );
        }
      }
    }
    return {
      id: order.id,
      title: `${order.id} ${order.customer} `,
      description: productList.join("\n"),
      label: `${moment(order.dropoffTime.startTime).format("HH:mm")} - ${moment(
        order.dropoffTime.endTime
      ).format("HH:mm")}`,
      laneId
    };
  };

  const data = {
    lanes: [
      {
        id: CleaningStatus.BEING_PROCESSED,
        title: "En cours de traitement",
        cardStyle: { maxWidth: width * 0.3 },
        cards: filteredOrders[CleaningStatus.BEING_PROCESSED].map(order =>
          generateCardOrder(order, CleaningStatus.BEING_PROCESSED)
        )
      },
      {
        id: CleaningStatus.READY_FOR_DROPOFF,
        title: "Prêtes",
        cardStyle: { maxWidth: width * 0.3 },
        cards: filteredOrders[CleaningStatus.READY_FOR_DROPOFF].map(order =>
          generateCardOrder(order, CleaningStatus.READY_FOR_DROPOFF)
        )
      },
      {
        id: CleaningStatus.BEING_RECLEANED,
        title: "Relavage",
        cardStyle: { maxWidth: width * 0.3 },
        cards: filteredOrders[CleaningStatus.BEING_RECLEANED].map(order =>
          generateCardOrder(order, CleaningStatus.BEING_RECLEANED)
        )
      }
    ]
  };

  return (
    <Layout
      title="Commandes du lendemain matin"
      showDatesDropdown={false}
      showSearch={false}
    >
      <Board
        data={data}
        editable={false}
        laneDraggable={false}
        hideCardDeleteIcon={true}
        onCardMoveAcrossLanes={moveOrderAction}
        eventBusHandle={setEventBus}
        onCardClick={clickOrderAction}
      />
    </Layout>
  );
};

export default Orders;
