import React, { CSSProperties, useState, useCallback } from "react";
import useDispatch from "../../hooks/use-thunk-dispatch";
import { useSelector } from "react-redux";
import {
  Container,
  Icon,
  SubTitle,
  Title,
  TextWrapper,
  Input,
  Button
} from "./style";
import { styles } from "./style";
import {
  updateOrderComment,
  deleteOrderComment
} from "../../store/orders/actions";
import { OrderType } from "../../store/orders/types";
import { AppState } from "../../store";

interface Props {
  content: string;
  title?: string;
  subtitle?: string;
  icon?: string;
  bagsCount?: string;
  className?: string;
  commentId: string;
  style?: CSSProperties;
  rightIcon?: string;
}

const OrderComment = ({
  content,
  icon,
  bagsCount,
  subtitle,
  title,
  className,
  commentId,
  style
}: Props) => {
  style = style || {};
  style["display"] = "flex";
  style["flex-direction"] = "space-between";
  style["align-content"] = "center";
  style["justify-content"] = "center";

  const dispatch = useDispatch();
  const currentOrder = useSelector<AppState, OrderType | null>(
    state => state.orders.currentOrder
  );
  const [edit, setEdit] = useState<boolean>(false);
  const [comment, setComment] = useState<string>("");
  const [deleteConfirmation, setDeleteConfirmation] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const updateComment = useCallback(async () => {
    if (!currentOrder) return;
    setLoading(true);
    await dispatch(updateOrderComment(currentOrder.id, commentId, comment));
    setEdit(false);
    setComment("");
    setLoading(false);
  }, [comment, dispatch, currentOrder, commentId]);

  const deleteComment = useCallback(async () => {
    if (!currentOrder) return;
    setLoading(true);
    await dispatch(deleteOrderComment(currentOrder.id, commentId));
    setDeleteConfirmation(false);
    setLoading(false);
  }, [commentId, dispatch, currentOrder]);

  const updateCommentValue = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setComment(e.currentTarget.value);
    },
    []
  );

  if (edit) {
    return (
      <>
        <Input
          placeholder="Modifier un commentaire..."
          defaultValue={content}
          onChange={updateCommentValue}
          style={{
            marginLeft: "2%",
            marginRight: "2%",
            marginBottom: "10px",
            width: "96%",
            border: "1px solid gray"
          }}
        />
        <Container>
          <Button
            loading={loading}
            onClick={updateComment}
            style={{
              marginLeft: "2%",
              marginRight: "2%",
              marginBottom: "10px",
              width: "96%"
            }}
          >
            Enregistrer
          </Button>
          <Button
            loading={loading}
            onClick={() => setEdit(false)}
            style={{
              marginLeft: "2%",
              marginRight: "2%",
              marginBottom: "10px",
              width: "96%"
            }}
          >
            Annuler
          </Button>
        </Container>
      </>
    );
  }

  if (deleteConfirmation) {
    return (
      <Container>
        <Title>Êtes-vous sûr de supprimer ce commentaire ?</Title>
        <Button
          loading={loading}
          onClick={deleteComment}
          style={{
            marginLeft: "2%",
            marginRight: "2%",
            marginBottom: "10px",
            width: "96%"
          }}
        >
          Supprimer
        </Button>
        <Button
          loading={loading}
          onClick={() => setDeleteConfirmation(false)}
          style={{
            marginLeft: "2%",
            marginRight: "2%",
            marginBottom: "10px",
            width: "96%"
          }}
        >
          Annuler
        </Button>
      </Container>
    );
  }

  return (
    <div style={style}>
      <Container>
        {icon && (
          <TextWrapper>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Icon src={icon} alt="" />
              <TextWrapper style={{ fontSize: 12, paddingLeft: 4 }}>
                {bagsCount}
              </TextWrapper>
            </div>
          </TextWrapper>
        )}

        <TextWrapper>
          {title && <Title>{title}</Title>}
          {subtitle && <SubTitle>{subtitle}</SubTitle>}
          <div
            style={styles[className!]}
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </TextWrapper>

        <TextWrapper>
          {/* <div style={{ display: "flex", flexDirection: "column" }}> */}
          <Icon
            src="/img/edit.svg"
            alt=""
            style={{
              marginBottom: "10px",
              display: "block",
              marginLeft: "30px"
              // width: "20px"
            }}
            onClick={() => {
              setEdit(true);
            }}
          />
          <Icon
            src="/img/bin.svg"
            alt=""
            style={{
              // marginBottom: "3px",
              display: "block",
              marginLeft: "30px"
              // width: "20px"
            }}
            // onCl
            onClick={() => setDeleteConfirmation(true)}
          />
          {/* </div> */}
        </TextWrapper>
        {/* <div style={{ display: "flex" }}>
          <Icon src="/img/warning.svg" alt="" style={{ width: "60px" }} />
          <Icon src="/img/warning.svg" alt="" style={{ width: "60px" }} />
        </div> */}
      </Container>

      {/* {rightIcon && ( */}
      {/* )} */}
    </div>
  );
};

export default OrderComment;
