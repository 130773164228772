import styled from "styled-components";
import colors from "../../style/colors";

interface ContainerProps {
  disabled: boolean;
  loading: boolean;
}

export const Container = styled.a<ContainerProps>`
  height: 48px;
  padding-horizontal: 16px;
  background-color: ${({ loading }) =>
    loading ? colors.grey : colors.secondary};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  shadow-color: ${colors.black};
  shadow-offset: 0 2px;
  shadow-opacity: 0.2;
  shadow-radius: 3.84;
  elevation: 5;
  cursor: pointer;
  width: 150px;
  margin: auto;
  text-decoration: none;

  &:hover {
    background-color: ${({ loading }) =>
      loading ? colors.grey : colors.secondaryDarken15};
  }
`;

export const Loader = styled.div`
  display: flex;
  height: 100%;
`;

const IconContainer = styled.div`
  width: 24px;
  height: 24px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const Left = styled(IconContainer)`
  margin-right: 5px;
`;

export const Right = styled(IconContainer)`
  margin-left: 5px;
`;

export const Text = styled.span`
  font-family: inherit;
  font-size: 14px;
  color: ${colors.white};
`;
