import styled from "styled-components";
import colors from "../../style/colors";

export enum BUTTON_TYPE {
  PRIMARY = "PRIMARY",
  SECONDARY = "SECONDARY"
}

interface Props {
  type?: BUTTON_TYPE;
}

interface ContainerProps extends Props {
  disabled: boolean;
}

const getColor = ({ type }: Props): string | undefined => {
  switch (type) {
    case BUTTON_TYPE.PRIMARY:
      return colors.white;
    case BUTTON_TYPE.SECONDARY:
      return colors.secondary;
  }
};

export const getBackgroundColor = ({
  type,
  disabled
}: ContainerProps): string | undefined => {
  if (disabled) {
    return colors.grey;
  }

  switch (type) {
    case BUTTON_TYPE.PRIMARY:
      return colors.secondary;
    case BUTTON_TYPE.SECONDARY:
      return colors.white;
  }
};

export const Container = styled.div<ContainerProps>`
  height: 48px;
  padding-horizontal: 16px;
  background-color: ${getBackgroundColor};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  shadow-color: ${colors.black};
  shadow-offset: 0 2px;
  shadow-opacity: 0.2;
  shadow-radius: 3.84;
  elevation: 5;
  cursor: pointer;
`;

export const Loader = styled.div`
  display: flex;
  height: 100%;
`;

const IconContainer = styled.div`
  width: 24px;
  height: 24px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const Left = styled(IconContainer)`
  margin-right: 5px;
`;

export const Right = styled(IconContainer)`
  margin-left: 5px;
`;

export const Text = styled.span<Props>`
  font-family: inherit;
  font-size: 14px;
  color: ${getColor};
`;
