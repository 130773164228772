import { ThunkAction } from "redux-thunk";
import { AnyAction } from "redux";

import { get, HttpResponse } from "../../utils/http";
import { ReduxDispatch } from "../../hooks/use-thunk-dispatch";

import { SET_CATEGORIES, SET_PRODUCTS, ProductsActionTypes } from "./types";
import { AppState } from "..";
import { Product } from "../../typings/product";
import { Category } from "../../typings/category";

interface GetCategoriesResponse {
  categories: Category[];
}

interface GetProductsResponse {
  products: Product[];
}

export const setCategories = (categories: Category[]): ProductsActionTypes => ({
  type: SET_CATEGORIES,
  categories
});

export const setProducts = (products: Product[]): ProductsActionTypes => ({
  type: SET_PRODUCTS,
  products
});

export const getCategories = (): ThunkAction<
  Promise<HttpResponse<GetProductsResponse>>,
  AppState,
  {},
  AnyAction
> => async (dispatch: ReduxDispatch, getState) => {
  try {
    if (Object.keys(getState().products.categories).length === 0) {
      const response = await get<GetCategoriesResponse>(
        "/categories",
        getState()
      );
      if (response.parsedBody) {
        const { categories } = response.parsedBody;
        dispatch(setCategories(categories));
      }

      return response;
    } else {
      return { ok: true };
    }
  } catch (error) {
    return error;
  }
};

export const getProducts = (): ThunkAction<
  Promise<HttpResponse<GetProductsResponse>>,
  AppState,
  {},
  AnyAction
> => async (dispatch: ReduxDispatch, getState) => {
  try {
    if (getState().products.allProducts.length === 0) {
      const response = await get<GetProductsResponse>("/products", getState());
      if (response.parsedBody) {
        const { products } = response.parsedBody;
        dispatch(setProducts(products));
      }

      return response;
    } else {
      return { ok: true };
    }
  } catch (error) {
    return error;
  }
};
