import React, { useEffect, useState, useCallback } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { AppState } from "../../store";
import Layout from "../layout";
import Select from "react-select";
import {
  Div,
  Input,
  Label,
  Number,
  CheckBoxWrapper,
  CheckBox,
  CheckBoxLabel,
  ButtonAction,
  ButtonAnnuler,
  ButtonSupprimer
} from "./style";
import { Link } from "react-router-dom";
import {
  getTimeSlots,
  getServiceClasses,
  putTimeslot,
  postTimeslot,
  deleteTimeslot
} from "../../store/timeslot/actions";
import { ServiceClasse, ServiceClasseMap } from "../../typings/serviceClasse";
import useDispatch from "../../hooks/use-thunk-dispatch";
import { Timeslot } from "../../typings/timeslot";
import { confirmAlert } from "react-confirm-alert";
import { useToast } from "../toast";

const TimeslotEdit = () => {
  const dispatch = useDispatch();
  const [pending, setPending] = React.useState(true);
  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      setPending(true);
      await dispatch(getServiceClasses());
      await dispatch(getTimeSlots());
      setPending(false);
    };
    fetchData();
  }, [dispatch]);

  const serviceClasses: ServiceClasse[] = useSelector<
    AppState,
    ServiceClasse[]
  >(state => state.serviceClasses.allServiceClasses);

  const options = [
    { value: 0, label: "Dimanche" },
    { value: 1, label: "Lundi" },
    { value: 2, label: "Mardi" },
    { value: 3, label: "Mercredi" },
    { value: 4, label: "Jeudi" },
    { value: 5, label: "Vendredi" },
    { value: 6, label: "Samedi" }
  ];

  const params = useParams();
  const editMode = params["id"];
  const editable = useSelector<AppState, Timeslot>(state => {
    if (editMode) {
      return state.timeslots.allTimeslots.find(
        item => item.id === params["id"]
      ) as Timeslot;
    }
    return {
      days: [] as number[],
      dropoffLeadTimeInMins: "",
      startsAt: "",
      endsAt: "",
      serviceClass: "",
      isActive: false,
      isPickup: false
    } as Timeslot;
  });

  let arrayofdays: number[] = [];
  editable.days.forEach(element => {
    arrayofdays.push(+element);
  });
  const [id, setId] = useState(params["id"]);
  const [days, setDays] = useState(arrayofdays);
  const [dropoffLeadTimeInMins, setDropoffLeadTimeInMins] = useState(
    editable.dropoffLeadTimeInMins
  );
  const [startsAt, setStartsAt] = useState(editable.startsAt);
  const [endsAt, setEndsAt] = useState(editable.endsAt);
  const [isActive, setIsActive] = useState(editable.isActive);
  const [isPickup, setIsPickup] = useState(editable.isPickup);
  const [serviceClass, setServiceClass] = useState(editable.serviceClass);

  const title = editMode ? "Modifier" : "Ajouter";

  const { showToast } = useToast();
  const history = useHistory();
  const [inprogress, setInprogress] = React.useState(false);
  const [suppressionProgress, setSuppressionProgress] = React.useState(false);
  const [actionProgress, setActionProgress] = React.useState(false);

  const handleDelete = useCallback(
    async id => {
      confirmAlert({
        title: "Confirmer la supression",
        message:
          "Etes-vous sûr que vous voulez supprimer cette tranches de temps ?",
        buttons: [
          {
            label: "oui",
            onClick: async () => {
              setSuppressionProgress(true);
              setInprogress(true);
              const response = await dispatch(deleteTimeslot(id));
              if (response.success) {
                showToast("Suppression effectuée avec succès");
                history.push(`/timeslots`);
              } else {
                showToast("Erreur lors de la suppression.");
              }
            }
          },
          {
            label: "non",
            onClick: () => {
              //
            }
          }
        ]
      });
    },
    [dispatch]
  );

  const renderSupprimerButton = () => {
    if (editMode) {
      return (
        <ButtonSupprimer
          onClick={() => handleDelete(editable.id)}
          disabled={inprogress}
        >
          {suppressionProgress ? "en cours..." : "supprimer"}
        </ButtonSupprimer>
      );
    } else {
      return null;
    }
  };

  const handleSubmit = useCallback(
    async (
      days,
      dropoffLeadTimeInMins,
      startsAt,
      endsAt,
      isActive,
      isPickup,
      serviceClass
    ) => {
      const timeslot = {
        days: days,
        dropoffLeadTimeInMins: dropoffLeadTimeInMins,
        startsAt: startsAt,
        endsAt: endsAt,
        isActive: isActive,
        isPickup: isPickup,
        serviceClass: serviceClass
      } as Timeslot;
      setActionProgress(true);
      setInprogress(true);
      const response = editMode
        ? await dispatch(putTimeslot(timeslot, id))
        : await dispatch(postTimeslot(timeslot));
      if (response.success) {
        editMode
          ? showToast("Modification effectuée avec succès")
          : showToast("Ajout effectuée avec succès");
        history.push(`/timeslots`);
      } else {
        setActionProgress(false);
        setInprogress(false);
        showToast("Veuillez  vérifier les infomrations entrées");
      }
    },
    [dispatch]
  );

  return (
    <Layout title={title} showSearchBag={false}>
      <div className="App">
        <Div>
          <Label>Journées</Label>
          <Select
            isMulti={true}
            options={options}
            onChange={e => setDays(e == null ? [] : e.map(e => e.value))}
            value={options.filter(o => days.indexOf(o.value) > -1)}
          />
          <Label>Délai de livraison</Label>
          <Number
            type="number"
            value={dropoffLeadTimeInMins}
            onChange={e => setDropoffLeadTimeInMins(e.target.value)}
          ></Number>
          <Label>Commence à</Label>
          <Input
            type="time"
            value={startsAt}
            onChange={e => setStartsAt(e.target.value)}
          ></Input>
          <Label>fini à</Label>
          <Input
            type="time"
            value={endsAt}
            onChange={e => setEndsAt(e.target.value)}
          ></Input>
          <Label>Actif</Label>
          <CheckBoxWrapper>
            <CheckBox
              id="actif"
              type="checkbox"
              checked={isActive}
              onChange={e =>
                e.target.checked ? setIsActive(true) : setIsActive(false)
              }
            />
            <CheckBoxLabel htmlFor="actif" />
          </CheckBoxWrapper>

          <Label>Ramassage</Label>
          <CheckBoxWrapper>
            <CheckBox
              id="recuperer"
              type="checkbox"
              checked={isPickup}
              onChange={e =>
                e.target.checked ? setIsPickup(true) : setIsPickup(false)
              }
            />
            <CheckBoxLabel htmlFor="recuperer" />
          </CheckBoxWrapper>

          <Label>Service</Label>
          <Select
            onChange={e => setServiceClass(e == null ? "" : e.value)}
            options={serviceClasses.map(e => ({
              label: e.name,
              value: e._id
            }))}
            value={serviceClasses
              .map(e => ({
                label: e.name,
                value: e._id
              }))
              .find(s => s.value == serviceClass)}
          />
          <ButtonAction
            onClick={() =>
              handleSubmit(
                days,
                dropoffLeadTimeInMins,
                startsAt,
                endsAt,
                isActive,
                isPickup,
                serviceClass
              )
            }
            disabled={inprogress}
          >
            {actionProgress ? "En cours..." : title}
          </ButtonAction>
          <Link to="/timeslots">
            <ButtonAnnuler disabled={inprogress}>Annuler</ButtonAnnuler>
          </Link>
          {renderSupprimerButton()}
        </Div>
      </div>
    </Layout>
  );
};

export default TimeslotEdit;
