import { SET_HOLIDAYS, HolidaysActionTypes, HolidaysState } from "./types";

const initialState: HolidaysState = {
  allHolidays: []
};

const Holidays = (
  state = initialState,
  action: HolidaysActionTypes
): HolidaysState => {
  switch (action.type) {
    case SET_HOLIDAYS: {
      return {
        ...state,
        allHolidays: action.holidays
      };
    }
    default:
      return state;
  }
};

export default Holidays;
