import { ThunkAction } from "redux-thunk";
import { AnyAction } from "redux";
import { get, post, remove, put, HttpResponse } from "../../utils/http";
import { ReduxDispatch } from "../../hooks/use-thunk-dispatch";
import {
  SET_HOLIDAYS,
  HolidaysActionTypes,
  PUT_HOLIDAY_SUCCESS,
  POST_HOLIDAY_SUCCESS,
  DELETE_HOLIDAY_SUCCESS
} from "./types";
import { AppState } from "..";
import { Holiday } from "../../typings/holidays";
import { ActionResponse } from "../holidays/types";

interface GetHolidaysResponse {
  holidays: Holiday[];
}

export const SetHolidays = (holidays: Holiday[]): HolidaysActionTypes => ({
  type: SET_HOLIDAYS,
  holidays
});

const postHolidaySuccess = response => {
  return {
    type: POST_HOLIDAY_SUCCESS,
    response: response
  };
};

const putHolidaySuccess = response => {
  return {
    type: PUT_HOLIDAY_SUCCESS,
    response: response
  };
};

const deleteHolidaySuccess = response => {
  return {
    type: DELETE_HOLIDAY_SUCCESS,
    response: response
  };
};

export const getHolidays = (): ThunkAction<
  Promise<HttpResponse<GetHolidaysResponse>>,
  AppState,
  {},
  AnyAction
> => async (dispatch: ReduxDispatch, getState) => {
  try {
    const response = await get<GetHolidaysResponse>(
      "/holidays?from=2021-01-01&to=2023-01-01",
      getState()
    );
    if (response.parsedBody) {
      const { holidays } = response.parsedBody;
      dispatch(SetHolidays(holidays));
    }
  } catch (error) {
    return error;
  }
};

export const postHoliday = (holiday: Holiday) => async (
  dispatch: ReduxDispatch,
  getState: () => AppState
): Promise<ActionResponse> => {
  let response!: HttpResponse<Holiday>;

  try {
    response = await post(`/holidays`, holiday, getState());
    if (response && response.ok && response.parsedBody) {
      dispatch(postHolidaySuccess("response"));
      return {
        success: true
      };
    } else {
      return {
        success: false
      };
    }
  } catch (e) {
    return {
      success: false
    };
  }
};

export const putHoliday = (holiday: Holiday, id: string) => async (
  dispatch: ReduxDispatch,
  getState: () => AppState
): Promise<ActionResponse> => {
  let response!: HttpResponse<Holiday>;
  try {
    response = await put(`/holidays/` + id, holiday, getState());

    if (response && response.ok && response.parsedBody) {
      dispatch(putHolidaySuccess("response"));
      return {
        success: true
      };
    } else {
      return {
        success: false
      };
    }
  } catch (e) {
    return {
      success: false
    };
  }
};

export const deleteHoliday = (holidayId: string) => async (
  dispatch: ReduxDispatch,
  getState: () => AppState
): Promise<ActionResponse> => {
  let response!: HttpResponse<Holiday>;

  try {
    response = await remove("/holidays/" + holidayId, getState());
    if (response && response.ok && response.parsedBody) {
      dispatch(deleteHolidaySuccess("response"));
      return {
        success: true
      };
    } else {
      return {
        success: false
      };
    }
  } catch (e) {
    return {
      success: false
    };
  }
};
