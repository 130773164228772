import styled, { keyframes } from "styled-components";
import COLORS from "../../../style/colors";

export const Wrapper = styled.div`
  position: fixed;
  bottom: 8px;
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
  padding: 0 2.5%;
  z-index: 9999999;
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
    top: 30px;
  }
  to {
    opacity: 1;
    top: 0px;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
    top: 0px;
  }
  to {
    opacity: 0;
    top: 30px;
  }
`;

export enum TYPE {
  ERROR = "ERROR",
  SUCCESS = "SUCCESS"
}

interface ToastProps {
  out?: boolean;
  type?: TYPE;
}

export const Toast = styled.div<ToastProps>`
  animation: ${({ out }) => (out ? fadeOut : fadeIn)} ease-in-out 300ms;
  background-color: ${({ type }) => {
    switch (type) {
      case TYPE.ERROR:
        return COLORS.error;
      case TYPE.SUCCESS:
        return COLORS.success;
      default:
        return "rgba(100, 100, 100, 0.95)";
    }
  }};
  padding: 8px 16px;
  border-radius: 4px;
  color: ${COLORS.white};
  font-size: 14px;
  box-shadow: ${COLORS.shadow}
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  box-sizing: border-box;
`;

export default Toast;
