import { Holiday } from "../../typings/holidays";

export const SET_HOLIDAYS = "SET_HOLIDAYS";
export const PUT_HOLIDAY_SUCCESS = "PUT_HOLIDAY_SUCCESS";
export const POST_HOLIDAY_SUCCESS = "POST_HOLIDAY_SUCCESS";
export const DELETE_HOLIDAY_SUCCESS = "DELETE_HOLIDAY_SUCCESS";

interface SetHolidays {
  type: typeof SET_HOLIDAYS;
  holidays: Holiday[];
}

export type HolidaysActionTypes = SetHolidays;

export interface HolidaysState {
  allHolidays: Holiday[];
}

export interface ActionResponse {
  success: boolean;
  error?: string;
}
