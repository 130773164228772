import React, { useEffect, useState, useCallback } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useToast } from "../toast";
import { Link } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";

import Layout from "../layout";
import useDispatch from "../../hooks/use-thunk-dispatch";
import { useSelector } from "react-redux";
import { AppState } from "../../store";
import {
  getOperationAreas,
  postOperationArea,
  putOperationArea,
  deleteOperationArea
} from "../../store/operationAreas/actions";
import { OperationArea } from "../../typings/operationArea";
import {
  Div,
  Input,
  Label,
  Textarea,
  Select,
  ButtonAction,
  ButtonAnnuler,
  ButtonSupprimer,
  Iupload,
  CheckBoxLabel,
  CheckBoxWrapper,
  CheckBox
} from "./style";

const options = [
  {
    id: 1,
    label: "Polygon",
    value: "Polygon"
  }
];

const OperationAreaEdit = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getOperationAreas());
  }, [dispatch]);

  const params = useParams();
  const editMode = params["id"];

  const editable = useSelector<AppState, OperationArea>(state => {
    if (editMode) {
      return state.operationAreas.allOperationAreas.find(
        item => item.id === params["id"]
      ) as OperationArea;
    }
    return {
      id: "",
      name: "",
      isEnabled: true,
      location: {
        coordinates: [[[]]] as number[][][],
        type: "Polygon"
      }
    } as OperationArea;
  });

  const [id, setId] = useState(editable.id);
  const [name, setName] = useState(editable.name);
  const [isEnabled, setIsEnabled] = useState(editable.isEnabled);
  const [coordinates, setCoordinates] = useState(editable.location.coordinates);
  const [type, setType] = useState(editable.location.type);

  const [inprogress, setInprogress] = React.useState(false);
  const [suppressionProgress, setSuppressionProgress] = React.useState(false);
  const [actionProgress, setActionProgress] = React.useState(false);

  const title = editMode ? "Modifier" : "Ajouter";

  const { showToast } = useToast();
  const history = useHistory();

  const handleDelete = useCallback(
    async id => {
      confirmAlert({
        title: "Confirmer la supression",
        message: "Etes-vous sûr que vous voulez supprimer cette zone ",
        buttons: [
          {
            label: "oui",
            onClick: async () => {
              setSuppressionProgress(true);
              setInprogress(true);
              const response = await dispatch(deleteOperationArea(id));
              if (response.success) {
                showToast("Suppression effectuée avec succès");
                history.push(`/OperationAreas`);
              } else {
                setSuppressionProgress(false);
                setInprogress(false);
                showToast("Erreur lors de la suppression.");
              }
            }
          },
          {
            label: "non",
            onClick: () => {
              //
            }
          }
        ]
      });
    },
    [dispatch]
  );

  const renderSupprimerButton = () => {
    if (editMode) {
      return (
        <ButtonSupprimer onClick={() => handleDelete(id)} disabled={inprogress}>
          {suppressionProgress ? "En cours..." : "Supprimer"}
        </ButtonSupprimer>
      );
    } else {
      return null;
    }
  };

  const handleSubmit = useCallback(
    async (id, name, isEnabled, coordinates, type) => {
      type = type ? type : "Polygon";
      const operationArea = {
        id: id,
        name: name,
        isEnabled: isEnabled,
        location: {
          coordinates: coordinates,
          type: type
        }
      } as OperationArea;
      setActionProgress(true);
      setInprogress(true);
      const response = editMode
        ? await dispatch(putOperationArea(operationArea))
        : await dispatch(postOperationArea(operationArea));
      if (response.success) {
        editMode
          ? showToast("Modification effectuée avec succès")
          : showToast("Ajout effectuée avec succès");
        history.push(`/OperationAreas`);
      } else {
        setActionProgress(false);
        setInprogress(false);
        showToast("Veuillez  vérifier les infomrations entrées");
      }
    },
    [dispatch]
  );

  const handleUpload = e => {
    const fileReader = new FileReader();
    fileReader.readAsText(e.target.files[0], "UTF-8");
    fileReader.onload = e => {
      let result = fileReader.result as string;
      let resultObject = JSON.parse(result).geometry as OperationArea;
      resultObject.name = JSON.parse(result).properties["nom"];
      const code = JSON.parse(result).properties["code"];
      setId(
        (code + "-" + resultObject.name)
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .replace(/\s+/g, "")
      );
      setName(resultObject.name);
      setCoordinates(resultObject["coordinates"]);
      setType(resultObject["type"]);
    };
  };

  return (
    <Layout title={title} showSearchBag={false}>
      <div className="App">
        <Div>
          <Iupload type="file" onChange={handleUpload} />
          <Label>Id</Label>
          <Input
            value={id}
            readOnly={editMode}
            onChange={e =>
              setId(
                e.target.value
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
                  .replace(/\s+/g, "")
              )
            }
          />
          <Label>Nom</Label>
          <Input value={name} onChange={e => setName(e.target.value)} />
          <Label>Actif</Label>
          <CheckBoxWrapper>
            <CheckBox
              id="enabled"
              type="checkbox"
              checked={isEnabled}
              onChange={e =>
                e.target.checked ? setIsEnabled(true) : setIsEnabled(false)
              }
            />
            <CheckBoxLabel htmlFor="enabled" />
          </CheckBoxWrapper>
          <Label>Emplacement</Label>
          <Textarea
            value={JSON.stringify(coordinates, null, 4)}
            onChange={e => setCoordinates(JSON.parse(e.target.value))}
          />
          <Select defaultValue={type} onChange={e => setType(e.target.value)}>
            {options.map(option => (
              <option key={option.id} value={option.value}>
                {option.label}
              </option>
            ))}
          </Select>
          <ButtonAction
            onClick={() => handleSubmit(id, name, isEnabled, coordinates, type)}
            disabled={inprogress}
          >
            {actionProgress ? "En cours..." : title}
          </ButtonAction>
          <Link to="/OperationAreas">
            <ButtonAnnuler disabled={inprogress}>Annuler</ButtonAnnuler>
          </Link>
          {renderSupprimerButton()}
        </Div>
      </div>
    </Layout>
  );
};

export default OperationAreaEdit;
