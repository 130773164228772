import React, { useEffect, useState } from "react";
import { AppWrapper } from "./App-style";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { Persistor } from "redux-persist/es/types";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import { Store } from "redux";
import { configureStore } from "./store";
import GlobalStyles from "./style/globals";
import Routes from "./Routes";
import { ToastProvider } from "./components/toast";

const App: React.FC = () => {
  const [store, setStore] = useState<Store | null>(null);
  const [persistor, setPersistor] = useState<Persistor | null>(null);

  useEffect(() => {
    const { store, persistor } = configureStore();

    setStore(store);
    setPersistor(persistor);
  }, []);

  const history = createBrowserHistory();

  if (!store || !persistor) {
    return null;
  }

  return (
    <AppWrapper>
      <GlobalStyles />
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ToastProvider>
            <Router history={history}>
              <Routes />
            </Router>
          </ToastProvider>
        </PersistGate>
      </Provider>
    </AppWrapper>
  );
};

export default App;
