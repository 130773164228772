import {
  ServiceClasse,
  ServiceClasseUpdate
} from "../../typings/serviceClasse";

export const SET_SERVICECLASSES = "SET_SERVICECLASSES";
export const SET_SERVICECLASSE = "SET_SERVICECLASSE";
export const SET_SERVICECLASSE_UPDATE = "SET_SERVICECLASSE_UPDATE";

export const POST_SERVICECLASSE_SUCCESS = "POST_SERVICECLASSE_SUCCESS";
export const PUT_SERVICECLASSE_SUCCESS = "PUT_SERVICECLASSE_SUCCESS";
export const DELETE_SERVICECLASSE_SUCCESS = "DELETE_SERVICECLASSE_SUCCESS";

interface SetServiceClasses {
  type: typeof SET_SERVICECLASSES;
  serviceClasses: ServiceClasse[];
}

interface SetServiceClasse {
  type: typeof SET_SERVICECLASSE;
  serviceClasse: ServiceClasseUpdate;
}

export type ServiceClassesActionTypes = SetServiceClasses | SetServiceClasse;

export interface ServiceClassesState {
  allServiceClasses: ServiceClasse[];
  serviceClasse: ServiceClasseUpdate;
}

export interface ServiceClasseState {
  serviceClasse: ServiceClasseUpdate;
}
