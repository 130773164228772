import React, { useEffect, useState, useCallback } from "react";
import { useParams, useHistory } from "react-router-dom";
import Layout from "../layout";
import {
  Div,
  Input,
  Label,
  ButtonAction,
  ButtonAnnuler,
  ButtonSupprimer,
  ButtonWithSelect,
  RowWrapper
} from "./style";
import Select from "react-select";

import { Link } from "react-router-dom";
import useDispatch from "../../hooks/use-thunk-dispatch";
import { useSelector } from "react-redux";
import { AppState } from "../../store";
import { Holiday } from "../../typings/holidays";
import moment from "moment";
import {
  deleteHoliday,
  postHoliday,
  putHoliday
} from "../../store/holidays/actions";
import { useToast } from "../toast";
import { confirmAlert } from "react-confirm-alert";
import { getOperationAreas } from "../../store/operationAreas/actions";
import { OperationArea } from "../../typings/operationArea";
import "./style/index.css";

const HolidayEdit = () => {
  const dispatch = useDispatch();
  const [pending, setPending] = React.useState(true);

  useEffect(() => {
    dispatch(getOperationAreas());
  }, [dispatch]);

  const operationAreas: OperationArea[] = useSelector<
    AppState,
    OperationArea[]
  >(state => state.operationAreas.allOperationAreas);
  const [options, setOptions] = useState(
    operationAreas.map(e => ({
      label: e.name,
      value: e._id
    }))
  );

  const [selectedOptions, setSelectedOptions] = useState(
    operationAreas.map(e => ({
      label: e.name,
      value: e._id
    }))
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      setPending(false);
      dispatch(getOperationAreas());
    }, 2000);
    return () => clearTimeout(timeout);
  }, [dispatch]);

  const params = useParams();

  const editMode = params["id"];
  const title = editMode ? "Modifier" : "Ajouter";

  const [inprogress, setInprogress] = React.useState(false);
  const [suppressionProgress, setSuppressionProgress] = React.useState(false);
  const [actionProgress, setActionProgress] = React.useState(false);

  const { showToast } = useToast();
  const history = useHistory();

  const editable = useSelector<AppState, Holiday>(state => {
    if (editMode) {
      return state.holidays.allHolidays.find(
        item => item.id === params["id"]
      ) as Holiday;
    }
    return {
      startsAt: "",
      endsAt: ""
    } as Holiday;
  });

  const [id, setId] = useState(params["id"]);
  const [startsAt, setStartsAt] = useState(
    moment(editable.startsAt).format("YYYY-MM-DDTkk:mm")
  );
  const [endsAt, setEndsAt] = useState(
    moment(editable.endsAt).format("YYYY-MM-DDTkk:mm")
  );
  const [operationArea, setOperationArea] = useState(editable.operationArea);

  useEffect(() => {
    setOptions(
      operationAreas.map(e => ({
        label: e.name,
        value: e._id
      }))
    );
    if (editable) {
      setSelectedOptions(options.filter(s => s.value == operationArea));
    }
  }, [operationAreas]);

  const handleDelete = useCallback(
    async id => {
      confirmAlert({
        title: "Confirmer la supression",
        message:
          "Etes-vous sûr que vous voulez supprimer cet tranches de temps",
        buttons: [
          {
            label: "oui",
            onClick: async () => {
              setSuppressionProgress(true);
              setInprogress(true);
              const response = await dispatch(deleteHoliday(id));
              if (response.success) {
                showToast("Suppression effectuée avec succès");
                history.push(`/holidays`);
              } else {
                showToast("Erreur lors de la suppression.");
              }
            }
          },
          {
            label: "non",
            onClick: () => {
              //
            }
          }
        ]
      });
    },
    [dispatch]
  );

  const renderSupprimerButton = () => {
    if (editMode) {
      return (
        <ButtonSupprimer
          disabled={inprogress}
          onClick={() => handleDelete(editable.id)}
        >
          {suppressionProgress ? "en cours..." : "supprimer"}
        </ButtonSupprimer>
      );
    } else {
      return null;
    }
  };

  const handleSubmit = useCallback(
    async (startsAt, endsAt, operationAreas) => {
      if (editMode) {
        let holiday = {
          startsAt: startsAt,
          endsAt: endsAt,
          operationArea
        } as Holiday;

        setActionProgress(true);
        setInprogress(true);
        let response = await dispatch(putHoliday(holiday, id));
        if (response.success) {
          showToast("Modification effectuée avec succès");
          history.push(`/holidays`);
        } else {
          setActionProgress(false);
          setInprogress(false);
          showToast("Veuillez  vérifier les infomrations entrées");
        }
      } else {
        operationAreas.forEach(async element => {
          let holiday = {
            operationArea: element.value,
            startsAt: startsAt,
            endsAt: endsAt
          } as Holiday;
          setActionProgress(true);
          setInprogress(true);
          let response = await dispatch(postHoliday(holiday));
          if (response.success) {
            showToast(
              "Ajout de la vacances à la zone " +
                element.label +
                "  effectuée avec succès"
            );
            history.push(`/holidays`);
          } else {
            setActionProgress(false);
            setInprogress(false);
            showToast("Veuillez  vérifier les infomrations entrées");
          }
        });
      }
    },
    [dispatch]
  );

  const handleSelectAllClick = e => {
    setSelectedOptions(options.filter(o => false));
    setSelectedOptions(options);
  };

  const handleSelectActivesClick = e => {
    setSelectedOptions(options.filter(o => false));
    setSelectedOptions(
      operationAreas
        .filter(o => o.isEnabled === true)
        .map(e => ({
          label: e.name,
          value: e._id
        }))
    );
  };

  return (
    <Layout title={title} showSearchBag={false}>
      <div className="App">
        <Div>
          {editMode ? (
            <Label className="block">Zone géographique</Label>
          ) : (
            <RowWrapper hidden={editMode}>
              <Label className="block">Zones géographiques</Label>
              <div style={{ width: "100px" }} />
              <ButtonWithSelect
                className="block"
                onClick={handleSelectAllClick}
              >
                Tout
              </ButtonWithSelect>
              <ButtonWithSelect
                className="block"
                onClick={handleSelectActivesClick}
              >
                Actifs
              </ButtonWithSelect>
            </RowWrapper>
          )}
          <Select
            classNamePrefix="react-select"
            isMulti={editMode ? false : true}
            options={options}
            value={selectedOptions}
            onChange={e =>
              editMode
                ? setSelectedOptions(e == null ? "" : e)
                : setSelectedOptions(e == null ? [] : e)
            }
          />

          <Label>Commence à</Label>
          <Input
            type="datetime-local"
            value={startsAt}
            onChange={e => setStartsAt(e.target.value)}
          ></Input>
          <Label>fini à</Label>
          <Input
            type="datetime-local"
            value={endsAt}
            onChange={e => setEndsAt(e.target.value)}
          ></Input>

          <ButtonAction
            disabled={inprogress}
            onClick={() => handleSubmit(startsAt, endsAt, selectedOptions)}
          >
            {actionProgress ? "En cours..." : title}
          </ButtonAction>
          <Link to="/holidays">
            <ButtonAnnuler disabled={inprogress}>Annuler</ButtonAnnuler>
          </Link>
          {renderSupprimerButton()}
        </Div>
      </div>
    </Layout>
  );
};

export default HolidayEdit;
