export const menu = {
  bmBurgerButton: {
    position: "absolute",
    width: "36px",
    height: "30px",
    left: "24px",
    top: "24px"
  },
  bmBurgerBars: {
    background: "#fff"
  },
  bmBurgerBarsHover: {
    background: "#a90000"
  },
  bmCrossButton: {
    height: "24px",
    width: "24px"
  },
  bmCross: {
    background: "#bdc3c7"
  },
  bmMenuWrap: {
    position: "fixed",
    left: "0px",
    top: "0px",
    height: "100%"
  },
  bmMenu: {
    background: "#373a47",
    padding: "2.5em 1.5em 0",
    fontSize: "1.15em"
  },
  bmMorphShape: {
    fill: "#373a47"
  },
  bmItemList: {
    color: "#b8b7ad",
    padding: "0.8em"
  },
  bmItem: {
    display: "inline-block",
    textDecoration: "none",
    color: "#fff",
    marginBottom: "15px"
  },
  bmOverlay: {
    background: "rgba(0, 0, 0, 0.3)",
    top: "0px",
    left: "0px"
  }
};
