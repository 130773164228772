import React, { useState, useEffect } from "react";
import useDispatch from "../../hooks/use-thunk-dispatch";
import { AppState } from "../../store";
import { GoogleMapReactWrapper, Title } from "./style";
import GoogleMapReact from "google-map-react";
import { OperationArea } from "../../typings/operationArea";
import { useSelector } from "react-redux";
import { getOperationAreas } from "../../store/operationAreas/actions";

interface OperationAreasMap {
  title: string;
  operationAreas: OperationArea[];
}

const convertToGoogleMapLatLng = (GeoJSON: OperationArea) => {
  let paths = google.maps.LatLng[300];
  const type = GeoJSON.location.type;
  for (let i = 0; i < GeoJSON.location.coordinates.length; i++) {
    if (type == "Polygon") {
      for (let j = 0; j < GeoJSON.location.coordinates[i].length; j++) {
        if (!paths) {
          paths = [];
        }

        if (!paths[i]) {
          paths[i] = [];
        }

        paths[i].push({
          lat: GeoJSON.location.coordinates[i][j][1],
          lng: GeoJSON.location.coordinates[i][j][0]
        });
      }
    }
  }

  return paths;
};

const drawPolygons = (map, maps, operationAreas) => {
  if (map && maps) {
    operationAreas.forEach(operationArea => {
      const polygonCoords = convertToGoogleMapLatLng(operationArea);
      let polygon = new maps.Polygon({
        paths: polygonCoords,
        strokeColor: operationArea.isEnabled ? "Green" : "Red",
        strokeOpacity: 0.6,
        strokeWeight: 2,
        fillColor: operationArea.isEnabled ? "Green" : "Red",
        fillOpacity: 0.25
      });
      polygon.setMap(map);
    });
  }
};

const OperationAreasMap = () => {
  const dispatch = useDispatch();
  const [pending, setPending] = React.useState(true);
  const [mapReference, setMapReference] = useState(null);
  const [mapsReference, setMapsReference] = useState(null);
  const data: OperationArea[] = useSelector<AppState, OperationArea[]>(
    state => state.operationAreas.allOperationAreas
  );

  useEffect(() => {
    setPending(false);
  }, [dispatch]);

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      await dispatch(getOperationAreas());
    };
    if (pending) {
      fetchData();
    }
    if (mapReference && mapsReference && !pending) {
      drawPolygons(mapReference, mapsReference, data);
    }
  }, [pending, mapReference, mapsReference]);

  const [clicks, setClicks] = React.useState<google.maps.LatLng[]>([]);
  const [zoom, setZoom] = React.useState(11); // initial zoom
  const [center, setCenter] = React.useState<google.maps.LatLngLiteral>({
    lat: 48.856614,
    lng: 2.3522219
  });

  const onClick = (e: google.maps.MapMouseEvent) => {
    setClicks([...clicks, e.latLng!]);
  };

  const onIdle = (m: google.maps.Map) => {
    setZoom(m.getZoom()!);
    setCenter(m.getCenter()!.toJSON());
  };

  const handleApiLoaded = (map, maps) => {
    setMapReference(map);
    setMapsReference(maps);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <GoogleMapReactWrapper>
        <Title>Vue sur carte</Title>
        <GoogleMapReact
          bootstrapURLKeys={{
            key: process.env.GOOGLE_API_KEY
              ? process.env.GOOGLE_API_KEY
              : "AIzaSyCzc8iDPgBmEpREjtjBiTutTt24aJos8Z4",
            language: "fr",
            region: "FR"
          }}
          defaultCenter={center}
          defaultZoom={zoom}
          options={{
            fullscreenControl: true,
            zoomControl: true
          }}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
        ></GoogleMapReact>
      </GoogleMapReactWrapper>
    </div>
  );
};

export default OperationAreasMap;
