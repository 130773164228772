import { Timeslot } from "../../typings/timeslot";
import { ServiceClasse, ServiceClasseMap } from "../../typings/serviceClasse";

export const SET_TIMESLOTS = "SET_TIMESLOTS";
export const SET_SERVICECLASSES = "SET_SERVICECLASSES";
export const PUT_TIMESLOT_SUCCESS = "PUT_TIMESLOT_SUCCESS";
export const POST_TIMESLOT_SUCCESS = "POST_TIMESLOT_SUCCESS";
export const DELETE_TIMESLOT_SUCCESS = "DELETE_TIMESLOT_SUCCESS";

interface SetServiceClasses {
  type: typeof SET_SERVICECLASSES;
  serviceClasses: ServiceClasse[];
}

interface SetTimeslots {
  type: typeof SET_TIMESLOTS;
  timeslots: Timeslot[];
}

export type TimeslotActionTypes = SetServiceClasses | SetTimeslots;

export interface TimeslotsState {
  allTimeslots: Timeslot[];
  serviceClasses: ServiceClasseMap;
}

export interface ActionResponse {
  success: boolean;
  error?: string;
}
