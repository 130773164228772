import styled from "styled-components";
import colors from "../../../style/colors";

export const Toggle = styled.div`
  display: flex;
  margin-bottom: 18px;
  width: 100%;

  input {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    overflow: hidden;
    position: absolute !important;
    width: 1px;
  }

  label {
    border: 1px solid ${colors.border};
    border-right: 0;
    color: ${colors.border};
    font-weight: bold;
    padding: 14px;
    text-align: center;
    text-transform: uppercase;
    transition: all 0.15s;
    width: 100%;

    &:hover {
      cursor: pointer;
    }

    &:first-of-type {
      border-radius: 4px 0 0 4px;
    }

    &:last-of-type {
      border-radius: 0 4px 4px 0;
      border-right: 1px solid ${colors.border};
    }
  }

  input:checked + label {
    background-color: ${colors.primary};
    border-color: ${colors.primary};
    color: ${colors.white};
  }
`;
