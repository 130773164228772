import "dayjs/locale/fr";
import React, { FC } from "react";

import { formatRange } from "../../utils/formatter";
import { OrderType } from "../../store/orders/types";
import OrderInfoPiece from "../order-info-piece";
import { Block, BlockTitle, OrderInfo } from "./style";
import { Wrapper } from "./style/pickup-dropoff";

interface OrderPickupProps {
  order: OrderType;
}

const PickupDropoff: FC<OrderPickupProps> = ({ order }) => (
  <Wrapper>
    <Block printHide={false}>
      <OrderInfo>
        <BlockTitle>Récupération</BlockTitle>
        <OrderInfoPiece
          icon="/img/time.svg"
          content={formatRange(order.pickupTime)}
        />
      </OrderInfo>
    </Block>
    <Block>
      <OrderInfo>
        <BlockTitle>Livraison</BlockTitle>
        <OrderInfoPiece
          icon="/img/time.svg"
          content={formatRange(order.dropoffTime)}
          printTextLarge
        />
      </OrderInfo>
    </Block>
  </Wrapper>
);

export default PickupDropoff;
