import React, { useEffect, useState, useCallback } from "react";
import Layout from "../layout";
import { Div, Editbutton, Button } from "./style";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "moment/locale/fr";
import "react-big-calendar/lib/css/react-big-calendar.css";
import useDispatch from "../../hooks/use-thunk-dispatch";
import { useSelector } from "react-redux";
import { AppState } from "../../store";
import "moment/locale/fr";
import { getHolidays } from "../../store/holidays/actions";
import { Holiday } from "../../typings/holidays";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { getOperationAreas } from "../../store/operationAreas/actions";
import { OperationArea } from "../../typings/operationArea";

const localizer = momentLocalizer(moment);
const messages = {
  allDay: "journée",
  previous: "précédent",
  next: "suivant",
  today: "aujourd'hui",
  month: "mois",
  week: "semaine",
  day: "jour",
  agenda: "Agenda",
  date: "date",
  time: "heure",
  event: "événement",
  showMore: total => `+ ${total} autre(s)`
};
const Holidays = () => {
  const dispatch = useDispatch();
  const [pending, setPending] = React.useState(true);
  const history = useHistory();

  useEffect(() => {
    setPending(false);
    const timeout = setTimeout(() => {
      dispatch(getOperationAreas());
      dispatch(getHolidays());
    }, 2000);
    setPending(true);

    return () => clearTimeout(timeout);
  }, [dispatch]);

  const data: Holiday[] = useSelector<AppState, Holiday[]>(
    state => state.holidays.allHolidays as Holiday[]
  );

  const operationareas: OperationArea[] = useSelector<
    AppState,
    OperationArea[]
  >(state => state.operationAreas.allOperationAreas);

  const handleSelectEvent = useCallback(
    event => history.push("/holidays/edit/" + event.id),
    []
  );
  return (
    <Layout title="Vacances" showSearchBag={false}>
      <div className="App">
        <Div>
          <div style={{ height: "2%", padding: "0% 3%" }}>
            <Link to="/holidays/edit">
              <Button>Ajouter une nouvelle vacance</Button>
            </Link>
          </div>

          <Calendar
            Selectable
            localizer={localizer}
            titleAccessor={data => {
              let operationarea = operationareas.find(
                s => s._id === data.operationArea
              ) as OperationArea;
              return operationarea
                ? "un jour de vacance - " + operationarea.name
                : "un jour de vacance";
            }}
            events={data}
            startAccessor={data => {
              return new Date(data.startsAt);
            }}
            endAccessor={data => {
              return new Date(data.endsAt);
            }}
            style={{ height: "80%", margin: "50px" }}
            culture="fr"
            messages={messages}
            onSelectEvent={handleSelectEvent}
          />
        </Div>
      </div>
    </Layout>
  );
};

export default Holidays;
