import React, { useState } from "react";
import OrderSummary, { EmptyOrder } from "../order-summary";
import SortBar from "../order-sort-bar";
import { OrderListWrapper } from "./style";
import { OrderType, SortDirection, OrderSort } from "../../store/orders/types";

function sortOrdersBy(orders: OrderType[], { property, direction }: OrderSort) {
  orders.sort((a, b) => {
    switch (property) {
      case "id":
      case "customer":
        if (direction === "ASC") return a[property] < b[property] ? -1 : 1;
        return a[property] > b[property] ? -1 : 1;
      default:
        return 1;
    }
  });
}

interface OrderList {
  title: string;
  orders: OrderType[];
  onChange?: (newOrder: OrderType) => void;
}

const OrderList = ({ title, orders, onChange }: OrderList) => {
  const [sort, setSort] = useState<OrderSort>({
    property: "id",
    direction: SortDirection.ASC
  });

  sortOrdersBy(orders, sort);

  return (
    <OrderListWrapper>
      <h2>{title}</h2>
      <SortBar currentSort={sort} updateSort={setSort} />
      {orders.length === 0 ? (
        <EmptyOrder content="Pas de commande" />
      ) : (
        orders.map((o, i) => (
          <OrderSummary
            key={o.id}
            order={o}
            onChange={onChange}
            borderBottom={i === orders.length - 1}
          />
        ))
      )}
    </OrderListWrapper>
  );
};

export default OrderList;
