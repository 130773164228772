import styled from "styled-components";
import colors from "../../../style/colors";

interface OrderWrapperProps {
  borderBottom: boolean;
}

export const OrderWrapper = styled.div<OrderWrapperProps>`
  border: 1px solid ${colors.border};
  ${({ borderBottom }) => !borderBottom && `border-bottom: none;`}
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

interface OrderInfoProps {
  color?: string;
}

export const OrderInfo = styled.div<OrderInfoProps>`
  color: ${({ color }) => (color ? color : colors.black)}
  padding: 12px 24px;

  span {
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;

    &:first-child {
      margin-right: 25px;
    }
  }

  &.orderItemizing {
    background-color: ${colors.orderItemizing};
  }

  &.orderReady {
    background-color: ${colors.orderReady};
  }
`;

export const EmptyOrderContent = styled.div`
  align-items: center;
  display: flex;
  font-style: italic;
  padding: 12px 24px;
`;
