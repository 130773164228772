import { UserType } from "../user/types";
import { setUser } from "../user/actions";
import { post, HttpResponse } from "../../utils/http";
import { ReduxDispatch } from "../../hooks/use-thunk-dispatch";
import Errors from "../../constants/errors.json";
import { AppState } from "../index";
import { LOG_IN, LOG_OUT, AuthActionTypes, Response } from "./types";

interface SetAuth {
  token: string;
}

export const setAuth = ({ token }: SetAuth): AuthActionTypes => ({
  type: LOG_IN,
  token
});

export const resetAuth = (): AuthActionTypes => ({
  type: LOG_OUT
});

export interface Login {
  email: string;
  password: string;
  userType: UserType;
}

interface AuthResponse {
  id: string;
  name: string;
  email: string;
  token: string;
}

export const login = ({ userType, email, password }: Login) => async (
  dispatch: ReduxDispatch,
  getState: () => AppState
): Promise<Response> => {
  let response!: HttpResponse<AuthResponse>;

  try {
    response = await post(
      userType === UserType.DRIVER
        ? "/drivers/login"
        : userType === UserType.FACILITY
        ? "/facilities/login"
        : "/admins/login",
      { email, password },
      getState()
    );
  } catch (e) {
    const errorText = [401, 400].includes(e.status)
      ? Errors.invalidCredentials
      : Errors.serverError;

    return {
      success: false,
      error: errorText
    };
  }

  if (response && response.parsedBody) {
    const { id, name, email, token } = response.parsedBody;

    dispatch(setAuth({ token }));
    dispatch(setUser({ id, name, type: userType, email }));
  }

  return {
    success: true
  };
};

export const logout = () => async (
  dispatch: ReduxDispatch
): Promise<Response> => {
  dispatch(resetAuth());
  return {
    success: true
  };
};
