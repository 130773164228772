import { ThunkAction } from "redux-thunk";
import { AnyAction } from "redux";
import { get, post, remove, put, HttpResponse } from "../../utils/http";
import { ReduxDispatch } from "../../hooks/use-thunk-dispatch";
import {
  SET_SERVICECLASSES,
  SET_SERVICECLASSE,
  ServiceClassesActionTypes,
  POST_SERVICECLASSE_SUCCESS,
  PUT_SERVICECLASSE_SUCCESS,
  DELETE_SERVICECLASSE_SUCCESS
} from "./types";
import { AppState } from "..";
import {
  ServiceClasse,
  ServiceClasseUpdate
} from "../../typings/serviceClasse";
import { ActionResponse } from "../orders/types";

interface GetServiceClassesResponse {
  serviceClasses: ServiceClasse[];
}

interface GetServiceClasseResponse {
  serviceClasse: ServiceClasseUpdate;
}

export const setServiceClasses = (
  serviceClasses: ServiceClasse[]
): ServiceClassesActionTypes => ({
  type: SET_SERVICECLASSES,
  serviceClasses
});

export const setServiceClasse = (
  serviceClasse: ServiceClasseUpdate
): ServiceClassesActionTypes => ({
  type: SET_SERVICECLASSE,
  serviceClasse
});

export const getServiceClasses = (): ThunkAction<
  Promise<HttpResponse<GetServiceClassesResponse>>,
  AppState,
  {},
  AnyAction
> => async (dispatch: ReduxDispatch, getState) => {
  try {
    const response = await get<GetServiceClassesResponse>(
      "/service-classes",
      getState()
    );
    if (response.parsedBody) {
      const { serviceClasses } = response.parsedBody;
      dispatch(setServiceClasses(serviceClasses));
    }
  } catch (error) {
    return error;
  }
};
//------------------------------geyByID---------------------------------
export const getServiceClasse = (
  id: string
): ThunkAction<
  Promise<HttpResponse<ServiceClasseUpdate>>,
  AppState,
  {},
  AnyAction
> => async (dispatch: ReduxDispatch, getState) => {
  try {
    const response = await get<ServiceClasseUpdate>(
      "/service-classes/" + id,
      getState()
    );

    if (response.parsedBody) {
      const serviceClasse = response.parsedBody;
      dispatch(setServiceClasse(serviceClasse));
    }
  } catch (error) {
    return error;
  }
};

const postServiceClasseSuccess = response => {
  return {
    type: POST_SERVICECLASSE_SUCCESS,
    response: response
  };
};

const putServiceClasseSuccess = response => {
  return {
    type: PUT_SERVICECLASSE_SUCCESS,
    response: response
  };
};

const deleteServiceClasseSuccess = response => {
  return {
    type: DELETE_SERVICECLASSE_SUCCESS,
    response: response
  };
};

export const postServiceClasse = (serviceClasse: ServiceClasseUpdate) => async (
  dispatch: ReduxDispatch,
  getState: () => AppState
): Promise<ActionResponse> => {
  let response!: HttpResponse<ServiceClasse>;

  try {
    response = await post(`/service-classes`, serviceClasse, getState());
    if (response && response.ok && response.parsedBody) {
      dispatch(postServiceClasseSuccess("response"));
      return {
        success: true
      };
    } else {
      return {
        success: false
      };
    }
  } catch (e) {
    return {
      success: false
    };
  }
};

export const putServiceClasse = (
  serviceClasse: ServiceClasseUpdate,
  id: string
) => async (
  dispatch: ReduxDispatch,
  getState: () => AppState
): Promise<ActionResponse> => {
  let response!: HttpResponse<ServiceClasse>;

  try {
    response = await put(`/service-classes/` + id, serviceClasse, getState());
    if (response && response.ok && response.parsedBody) {
      dispatch(putServiceClasseSuccess("response"));
      return {
        success: true
      };
    } else {
      return {
        success: false
      };
    }
  } catch (e) {
    return {
      success: false
    };
  }
};

export const deleteServiceClasse = (serviceClasseId: string) => async (
  dispatch: ReduxDispatch,
  getState: () => AppState
): Promise<ActionResponse> => {
  let response!: HttpResponse<ServiceClasse>;

  try {
    response = await remove("/service-classes/" + serviceClasseId, getState());
    if (response && response.ok && response.parsedBody) {
      dispatch(deleteServiceClasseSuccess("response"));
      return {
        success: true
      };
    } else {
      return {
        success: false
      };
    }
  } catch (e) {
    return {
      success: false
    };
  }
};
