import styled from "styled-components";
import { InvisibleButton as OButton } from "../../../style/button";

interface SortButtonProps {
  isActive: boolean;
}

export const Button = styled(OButton)<SortButtonProps>`
  font-size: 16px;
  margin-left: 5px;
  ${({ isActive }) => (isActive ? `font-weight: bold;` : ``)}
`;
