import { createGlobalStyle } from "styled-components";
import MontserratBold from "../assets/fonts/montserrat/Montserrat-Bold.ttf";
import MontserratItalic from "../assets/fonts/montserrat/Montserrat-Italic.ttf";
import MontserratRegular from "../assets/fonts/montserrat/Montserrat-Regular.ttf";

const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: "Montserrat";
    src: url(${MontserratRegular}) format("truetype");
  }
  @font-face {
    font-family: "Montserrat";
    src: url(${MontserratBold}) format("truetype");
    font-weight: bold;
  }
  @font-face {
    font-family: "Montserrat";
    src: url(${MontserratItalic}) format("truetype");
    font-style: italic;
  }


  /* apply a natural box layout model to all elements, but allowing components to change */
  html {
    box-sizing: border-box;
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

  body {
    font-family: Montserrat;
    margin: 0;
    padding: 0;

    @media print {
      font-size: 70%;
    }
  }
`;

export default GlobalStyles;
