import { Tasks } from "../../typings/tasks";

export const SET_TASKS = "SET_TASKS";
export const POST_TASK_SUCCESS = "POST_TASK_SUCCESS";

interface SetTasks {
  type: typeof SET_TASKS;
  tasks: Tasks[];
}

export type TasksActionTypes = SetTasks;

export interface TasksState {
  allTasks: Tasks[];
}

export interface ActionResponse {
  success: boolean;
  error?: string;
}
