import styled from "styled-components";
import colors from "./colors";

export const Button = styled.button`
  background-color: ${colors.primary};
  border: none;
  border-radius: 3px;
  color: ${colors.white};
  font-family: Montserrat;
  font-weight: bold;
  padding: 10px 34px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.15s all;

  &:hover {
    background-color: ${colors.primaryLight};
    cursor: pointer;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }
`;

export const InvisibleButton = styled.button`
  background: none;
  border: none;
  height: auto;
  width: auto;

  &:hover {
    cursor: pointer;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }
`;
