import React, { FC } from "react";
import { useSelector } from "react-redux";

import useProducts from "../itemizer/use-products";
import { AppState } from "../../store";
import { Product as ProductType } from "../../typings/product";
import { BagType, Item, OrderType } from "../../store/orders/types";
import { BlockTitle } from "./style";

import {
  BagLineHeader,
  BagLine,
  BagName,
  BagQuantity,
  BagUnitPrice,
  BagPrice,
  BagItemization,
  BagWrapper,
  CartWrapper
} from "./style/cart";

interface BagsProps {
  bag: BagType;
  showPrice: boolean;
}

const Bag: FC<BagsProps> = ({ bag, showPrice }) => {
  const products = useSelector<AppState, ProductType[]>(
    state => state.products.allProducts
  );
  const { loading } = useProducts();
  return (
    <>
      {loading ? (
        "Chargement..."
      ) : (
        <BagWrapper>
          <BlockTitle>Sac n°{bag.barcode}</BlockTitle>

          {bag.itemization.length === 0 ? (
            ""
          ) : (
            <BagItemization>
              <BagLineHeader>
                <BagName>Produit</BagName>
                <BagQuantity>Quantité</BagQuantity>
                {showPrice ? <BagUnitPrice>P.U.</BagUnitPrice> : ""}

                {showPrice ? <BagPrice>Prix</BagPrice> : ""}
              </BagLineHeader>

              {bag.itemization.map(({ product, quantity }: Item) => {
                const productObj = products.find(prod => prod.id === product);
                if (!productObj) {
                  return null;
                }
                return (
                  <BagLine key={productObj.id}>
                    <BagName>{productObj.name}</BagName>
                    <BagQuantity>{quantity}</BagQuantity>
                    {showPrice ? (
                      <BagUnitPrice>{productObj.price} €</BagUnitPrice>
                    ) : (
                      ""
                    )}

                    {showPrice ? (
                      <BagPrice>{productObj.price * quantity} €</BagPrice>
                    ) : (
                      ""
                    )}
                  </BagLine>
                );
              })}
            </BagItemization>
          )}
        </BagWrapper>
      )}
    </>
  );
};

interface CartProps {
  order: OrderType;
  showPrice: boolean;
}

const Cart: FC<CartProps> = ({ order, showPrice }) => {
  return (
    <CartWrapper>
      {order.itemization.bags.map((b: BagType) => (
        <Bag key={b.barcode} bag={b} showPrice={showPrice} />
      ))}
    </CartWrapper>
  );
};

export default Cart;
