import React, { useEffect, useState, useCallback } from "react";
import Layout from "../layout";
import Select from "react-select";
import useDispatch from "../../hooks/use-thunk-dispatch";
import { useSelector } from "react-redux";
import DataTable from "react-data-table-component";
import { AppState } from "../../store";
import { cloneDayAvailability } from "../../store/availabilities/actions";
import { getOperationAreas } from "../../store/operationAreas/actions";
import { OperationArea } from "../../typings/operationArea";
import { Div, Input, ActionButton, CalendarButton, SearchInput } from "./style";
import { Link } from "react-router-dom";
import { FaEdit, FaTrash } from "react-icons/fa";
import "react-dropdown/style.css";
import {
  getAvailabilities,
  cloneAvailability,
  cleanAvailability,
  deleteAvailability,
  deleteWeekAvailability
} from "../../store/availabilities/actions";
import { Availability } from "../../typings/availability";
import moment from "moment";
import { useToast } from "../toast";
import { confirmAlert } from "react-confirm-alert";
import ReactTooltip from "react-tooltip";
import { Modal } from "../modal/";
import { ConfirmationModal as CloneWeekConfirmationModal } from "../cloneAvailability-modal";
import { ConfirmationModal as CloneDayConfirmationModal } from "../cloneDayAvailability-modal";
import { useModal } from "../modal/useModal";
import styled from "styled-components";
import { daysInWeek } from "date-fns";
import "./style/index.css";

sessionStorage.setItem("SelectedWeek", "");
sessionStorage.setItem("cloneUntilWeek", "");

const FilterComponent = ({ filterText, onFilter }) => (
  <>
    <SearchInput
      id="search"
      placeholder="Rechercher..."
      type="text"
      value={filterText}
      onChange={onFilter}
    />
  </>
);

const Availabilities = () => {
  const { isShown, toggle } = useModal();
  const optionsButton = [
    { value: "", label: "Nettoyer Disponibilités", isDisabled: true },
    { value: "W", label: "Nettoyer semaine courante", isDisabled: false },
    { value: "M", label: "Nettoyer mois courant", isDisabled: false },
    { value: "Y", label: "Nettoyer année courante", isDisabled: false }
  ];
  const defaultOption = optionsButton[0];

  const SelectedWeek = sessionStorage.getItem("SelectedWeek");
  const dispatch = useDispatch();
  const [weekClone, setWeekClone] = React.useState(true);
  const [pending, setPending] = React.useState(true);
  const [currentWeek, setCurrentWeek] = useState(
    SelectedWeek ? SelectedWeek : moment().format("GGGG-[W]WW")
  );
  const [rangeFrom, setRangeFrom] = useState(
    moment(currentWeek)
      .startOf("week")
      .format("YYYY-MM-DD")
  );

  const [rangeTo, setRangeTo] = useState(
    moment(currentWeek)
      .endOf("week")
      .add(1, "days")
      .format("YYYY-MM-DD")
  );

  const [daysToClone, setDaysToClone] = useState([
    { value: 1, label: "Mardi" },
    { value: 2, label: "Mercredi" },
    { value: 3, label: "Jeudi" },
    { value: 4, label: "Vendredi" },
    { value: 5, label: "Samedi" },
    { value: 6, label: "Dimanche" }
  ]);

  const [selectedDaysToClone, setSelectedDaysToClone] = useState(daysToClone);

  const { showToast } = useToast();
  const [inprogress, setInprogress] = React.useState(false);
  const [cloneProgress, setCloneProgress] = React.useState(false);
  const [cleanProgress, setCleanProgress] = React.useState(false);
  sessionStorage.setItem("SelectedWeek", currentWeek);

  const options: OperationArea[] = useSelector<AppState, OperationArea[]>(
    state => state.operationAreas.allOperationAreas
  );
  const data: Availability[] = useSelector<AppState, Availability[]>(
    state => state.Availabilities.allAvailabilities
  );

  const handleDelete = useCallback(
    async id => {
      confirmAlert({
        title: "Confirmer la supression",
        message:
          "Etes-vous sûr que vous voulez supprimer cette disponibilité ?",
        buttons: [
          {
            label: "oui",
            onClick: async () => {
              setInprogress(true);
              const response = await dispatch(deleteAvailability(id));
              if (response.success) {
                showToast("Suppression effectuée avec succès");
                setInprogress(false);
                setPending(true);
              } else {
                showToast("Erreur lors de la suppression.");
                setInprogress(false);
                setPending(false);
              }
            }
          },
          {
            label: "non",
            onClick: () => {}
          }
        ]
      });
    },
    [dispatch]
  );

  const columns = [
    {
      name: "Zone géographique",
      selector: row => {
        return (function Actions() {
          let operationArea = options.find(
            s => s._id === row.operationArea
          ) as OperationArea;
          return operationArea ? operationArea.name : "-";
        })();
      },
      cell: row => {
        return (function Actions() {
          let operationArea = options.find(
            s => s._id === row.operationArea
          ) as OperationArea;
          return operationArea ? operationArea.name : "-";
        })();
      },

      sortable: true
    },
    {
      name: "Début",
      selector: "timeslotStart",
      sortable: true
    },
    {
      name: "Fin",
      selector: "timeslotEnd",
      sortable: true
    },
    {
      name: "Date",
      selector: "date",
      sortable: true
    },
    {
      name: "Nb des commandes maximales",
      selector: "maxNumberOfOrders",
      sortable: true
    },
    {
      name: "Nb des commandes restantes",
      selector: "remNumberOfOrders",
      sortable: true
    },
    {
      name: "Date de création",
      selector: "createdAt",
      sortable: true
    },
    {
      name: "Date de mise a jour",
      selector: "updatedAt",
      sortable: true
    },

    {
      name: "Action",
      selector: "id",
      cell: row => {
        return (function Actions() {
          return (
            <div>
              <Link to={"/Availability/edit/" + row.id}>
                <ActionButton data-tip data-for="UpdateTip">
                  <FaEdit></FaEdit>
                </ActionButton>
              </Link>
              <ReactTooltip id="UpdateTip" place="top" effect="solid">
                Modifier
              </ReactTooltip>
              <ActionButton
                data-tip
                data-for="DeleteTip"
                onClick={() => {
                  handleDelete(row.id);
                }}
              >
                <FaTrash></FaTrash>
              </ActionButton>
              <ReactTooltip id="DeleteTip" place="top" effect="solid">
                Supprimer
              </ReactTooltip>
            </div>
          );
        })();
      }
    }
  ];

  const onConfirmCloneWeek = async () => {
    setWeekClone(true);
    let cloningWeek = moment(currentWeek);
    let cloningUntilWeek = moment(
      sessionStorage.getItem("cloneUntilWeek")
    ).subtract(1, "week");
    const areaIds = Array.from(
      new Set(data.map((item: any) => item.operationArea))
    );
    const areaReferences = options
      .filter(o => areaIds.indexOf(o._id) >= 0)
      .map(e => e.id);

    while (cloningWeek <= cloningUntilWeek) {
      await Promise.all(
        areaReferences.map(async area => {
          let currentArea = options.find(
            operationArea => operationArea.id === area
          ) as OperationArea;
          const response = await dispatch(
            cloneAvailability(
              moment(cloningWeek)
                .startOf("week")
                .format("YYYY-MM-DD"),
              area
            )
          );
          if (response.success) {
            setInprogress(false);
            setCloneProgress(false);
            showToast(
              "Clone de la semaine " +
                moment(cloningWeek)
                  .startOf("week")
                  .format("YYYY-MM-DD") +
                " pour la zone " +
                currentArea.name +
                " effectué avec succées"
            );
          } else {
            setInprogress(false);
            setCloneProgress(false);
            showToast("Erreur lors de clone.");
          }
        })
      );
      cloningWeek = cloningWeek.add(1, "week");
    }

    toggle();
  };
  const onCancelCloneWeek = () => {
    setWeekClone(false);
    toggle();
  };

  const cloneDayAvailabilityPerArea = async (
    rangeFrom,
    areaReference,
    daysToClone
  ) => {
    let currentArea = options.find(
      operationArea => operationArea.id === areaReference
    ) as OperationArea;

    const response = await dispatch(
      await cloneDayAvailability(rangeFrom, areaReference, daysToClone)
    );

    if (response.success) {
      setPending(true);
      showToast(
        "Clone du jour " +
          moment(rangeFrom).format("YYYY-MM-DD") +
          " pour la zone " +
          currentArea.name +
          " effectué avec succées"
      );
    } else {
      showToast(
        "Erreur lors du clone du jour " +
          moment(rangeFrom).format("YYYY-MM-DD") +
          " pour la zone " +
          currentArea.name
      );
    }
  };

  const handleCloneDay = useCallback(
    async (rangeFrom, weekAvailabilities, operationAreas, daysToClone) => {
      setPending(true);
      setInprogress(true);
      const areaIds = Array.from(
        new Set(weekAvailabilities.map((item: any) => item.operationArea))
      );
      const areaReferences = operationAreas
        .filter(o => areaIds.indexOf(o._id) >= 0)
        .map(e => e.id);
      await Promise.all(
        areaReferences.map(async area => {
          await cloneDayAvailabilityPerArea(rangeFrom, area, daysToClone);
        })
      );
      setInprogress(false);
      setPending(false);
    },
    [dispatch]
  );

  const onConfirmCloneDay = async () => {
    handleCloneDay(
      rangeFrom,
      data,
      options,
      selectedDaysToClone.map(e => e.value)
    );
    toggle();
  };
  const onCancelCloneDay = () => {
    toggle();
  };

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      if (pending) {
        await dispatch(getOperationAreas());
        await dispatch(getAvailabilities(rangeFrom, rangeTo));
        setPending(false);
      }
    };

    fetchData();
  }, [dispatch, pending]);

  const [filterText, setFilterText] = React.useState("");

  const filteredItems = data.filter(item => {
    let operationArea = options.find(
      operationArea => operationArea._id === item.operationArea
    ) as OperationArea;
    if (
      operationArea &&
      operationArea.name
        .toString()
        .toLowerCase()
        .includes(filterText.toLowerCase())
    ) {
      return item;
    }

    return "";
  });

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setFilterText("");
      }
    };
  }, [filterText]);

  const ChangeDate = value => {
    setCurrentWeek(value);
    setRangeFrom(
      moment(value)
        .startOf("week")
        .format("YYYY-MM-DD")
    );
    setRangeTo(
      moment(value)
        .endOf("week")
        .add(1, "days")
        .format("YYYY-MM-DD")
    );
    setPending(true);
  };

  const handleCleanOptions = useCallback(
    async (range, SelectedWeek) => {
      let rangeToClean = {
        startDate: "",
        endDate: ""
      };
      switch (range) {
        case "W":
          rangeToClean = {
            startDate: moment(SelectedWeek)
              .startOf("week")
              .format("YYYY-MM-DD"),
            endDate: moment(SelectedWeek)
              .endOf("week")
              .add(1, "days")
              .format("YYYY-MM-DD")
          };
          break;
        case "M":
          rangeToClean = {
            startDate: moment(SelectedWeek)
              .startOf("month")
              .format("YYYY-MM-DD"),
            endDate: moment(SelectedWeek)
              .endOf("month")
              .add(1, "days")
              .format("YYYY-MM-DD")
          };

          break;
        case "Y":
          rangeToClean = {
            startDate: moment(SelectedWeek)
              .startOf("year")
              .format("YYYY-MM-DD"),
            endDate: moment(SelectedWeek)
              .endOf("year")
              .add(1, "days")
              .format("YYYY-MM-DD")
          };

          break;

        default:
          rangeToClean = {
            startDate: moment(currentWeek)
              .startOf("week")
              .format("YYYY-MM-DD"),
            endDate: moment(currentWeek)
              .endOf("week")
              .format("YYYY-MM-DD")
          };

          break;
      }

      confirmAlert({
        title: "Confirmer le nettoyage",
        message:
          "Etes-vous sûr que vous voulez nettoyer la période de " +
          rangeToClean.startDate +
          " à " +
          rangeToClean.endDate,
        buttons: [
          {
            label: "oui",
            onClick: async () => {
              setInprogress(true);
              setCleanProgress(true);
              showToast("nettoyage en cours");
              const response = await dispatch(cleanAvailability(rangeToClean));
              if (response.success) {
                setPending(true);
                showToast("nettoyage effectuée avec succès");
              } else {
                showToast("Erreur lors de nettoyage.");
              }
              setInprogress(false);
              setCleanProgress(false);
            }
          },
          {
            label: "non",
            onClick: () => {
              //
            }
          }
        ]
      });
    },
    [dispatch]
  );

  const deleteWeekArea = async (rangeFrom, areaReference) => {
    const response = await dispatch(
      deleteWeekAvailability(rangeFrom, areaReference)
    );

    if (response.success) {
      setPending(true);
      showToast(
        "Suppression de la semaine " +
          moment(rangeFrom).format("YYYY-MM-DD") +
          " effectuée avec succées"
      );
    } else {
      showToast(
        "Erreur lors de la suppression de la semaine " +
          moment(rangeFrom).format("YYYY-MM-DD")
      );
    }
  };

  const handleDeleteWeek = useCallback(
    async (rangeFrom, weekAvailabilities, operationAreas) => {
      confirmAlert({
        title: "Confirmer la supression",
        message: "Etes-vous sûr que vous voulez supprimer la semaine courante ",
        buttons: [
          {
            label: "oui",
            onClick: async () => {
              setPending(true);
              setInprogress(true);
              showToast("supression en cours");
              const areaIds = Array.from(
                new Set(
                  weekAvailabilities.map((item: any) => item.operationArea)
                )
              );
              const areaReferences = operationAreas
                .filter(o => areaIds.indexOf(o._id) >= 0)
                .map(e => e.id);
              await Promise.all(
                areaReferences.map(async area => {
                  await deleteWeekArea(rangeFrom, area);
                })
              );

              setInprogress(false);
              setPending(false);
            }
          },
          {
            label: "non",
            onClick: () => {
              //
            }
          }
        ]
      });
    },
    [dispatch]
  );

  return (
    <Layout title="Disponibilités " showSearchBag={false}>
      <div className="App">
        <Div>
          <div
            style={{
              width: "820px",
              padding: "25px 0px",
              margin: "0px 1%",
              display: "flex"
            }}
          >
            <div style={{ width: "100%" }}>
              <Modal
                isShown={isShown}
                hide={toggle}
                headerText="Confirmation"
                modalContent={
                  weekClone ? (
                    <CloneWeekConfirmationModal
                      onConfirm={onConfirmCloneWeek}
                      onCancel={onCancelCloneWeek}
                      message="Etes vous sur de vouloir cloner la semaine courante jusqu'à la semaine"
                      cloneUntilWeek={SelectedWeek}
                    />
                  ) : (
                    <CloneDayConfirmationModal
                      onConfirm={onConfirmCloneDay}
                      onCancel={onCancelCloneDay}
                      onChangeCloneDays={setSelectedDaysToClone}
                      selectedWeek={SelectedWeek}
                      message="Veuillez sélectionner les jours sur lesquels vous souhaitez cloner le premier jour"
                      daysToClone={daysToClone}
                    />
                  )
                }
              />

              <label data-tip data-for="CleanTip">
                <Select
                  className="select"
                  hideSelectedOptions
                  options={optionsButton}
                  onChange={e => handleCleanOptions(e.value, SelectedWeek)}
                  value={defaultOption}
                  placeholder="Select an option"
                />
              </label>
              <ReactTooltip id="CleanTip" place="top" effect="solid">
                Supprime les disponibilités sans zone géographique
                correspondante
              </ReactTooltip>
            </div>
            <Input
              type="button"
              data-tip
              data-for="CloneTip"
              style={{ backgroundColor: "green", color: "white" }}
              onClick={() => {
                setWeekClone(true);
                toggle();
              }}
              disabled={inprogress}
              value={
                cloneProgress ? "Clone en cours ..." : "Cloner une semaine"
              }
            ></Input>
            <ReactTooltip id="CloneDayTip" place="top" effect="solid">
              Cette action permet de cloner le 1er jour de la semaine vers les
              autres jour de la même semaine.
            </ReactTooltip>
            <Input
              type="button"
              data-tip
              data-for="DeleteWeekTip"
              style={{ backgroundColor: "red", color: "white" }}
              onClick={() => handleDeleteWeek(rangeFrom, data, options)}
              disabled={inprogress}
              value={"Supprimer la semaine courante"}
            ></Input>
            <ReactTooltip id="DeleteWeekTip" place="top" effect="solid">
              Cette action permet de supprimer les disponibilités de la semaine
              séléctionnée.
            </ReactTooltip>
            <Input
              type="button"
              data-tip
              data-for="CloneDayTip"
              style={{ backgroundColor: "green", color: "white" }}
              onClick={() => {
                setWeekClone(false);
                toggle();
              }}
              disabled={inprogress}
              value={"Cloner le 1er jour"}
            ></Input>
            <ReactTooltip id="CloneTip" place="top" effect="solid">
              Cette action permet de dupliquer les disponibilités de la semaine
              courante vers la semaine suivante
            </ReactTooltip>
          </div>
          <div
            style={{
              width: "28  ¨%",
              padding: "15px",
              margin: "5px",
              display: "flex"
            }}
          >
            <CalendarButton
              type="button"
              onClick={e => {
                const value = moment().format("GGGG-[W]WW");
                ChangeDate(value);
              }}
            >
              Semaine courante
            </CalendarButton>
            <Input
              style={{
                width: "200px"
              }}
              type="week"
              value={currentWeek}
              onChange={e => [ChangeDate(e.target.value)]}
            ></Input>
            <Link to="/availability/edit">
              <Input
                type="button"
                style={{
                  backgroundColor: "#122630",
                  color: "white"
                }}
                disabled={inprogress}
                value={"Ajouter une nouvelle Disponibilité"}
              ></Input>
            </Link>
          </div>
        </Div>
        <FilterComponent
          onFilter={e => setFilterText(e.target.value)}
          filterText={filterText}
        />

        <DataTable
          title="Disponibilités"
          columns={columns}
          data={filteredItems}
          defaultSortField="title"
          pagination
          responsive
          progressPending={pending}
        />
      </div>
    </Layout>
  );
};

export default Availabilities;
