import {
  SET_TIMESLOTS,
  SET_SERVICECLASSES,
  TimeslotActionTypes,
  TimeslotsState
} from "./types";
import { ServiceClasseMap } from "../../typings/serviceClasse";

const initialState: TimeslotsState = {
  allTimeslots: [],
  serviceClasses: {}
};

const TimeSlots = (
  state = initialState,
  action: TimeslotActionTypes
): TimeslotsState => {
  switch (action.type) {
    case SET_TIMESLOTS: {
      return {
        ...state,
        allTimeslots: action.timeslots
      };
    }
    case SET_SERVICECLASSES: {
      return {
        ...state,
        serviceClasses: action.serviceClasses.reduce(
          (serviceClasseMap, serviceClasse) => {
            serviceClasseMap[serviceClasse._id] = serviceClasse;
            return serviceClasseMap;
          },
          {} as ServiceClasseMap
        )
      };
    }
    default:
      return state;
  }
};

export default TimeSlots;
