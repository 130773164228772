import React, { CSSProperties } from "react";
import { Container, Icon, SubTitle, Title, TextWrapper } from "./style";
import { styles } from "./style";

interface Props {
  content: string;
  title?: string;
  subtitle?: string;
  icon?: string;
  bagsCount?: string;
  className?: string;
  style?: CSSProperties;
  rightIcon?: string;
  printTextLarge?: boolean;
  printTextSmall?: boolean;
}

const OrderInfoPiece = ({
  content,
  icon,
  bagsCount,
  subtitle,
  title,
  className,
  style,
  rightIcon,
  printTextLarge,
  printTextSmall
}: Props) => {
  style = style || {};
  style["display"] = "flex";
  style["flex-direction"] = "space-between";
  style["align-content"] = "center";
  style["justify-content"] = "center";

  return (
    <div style={style}>
      <Container>
        {icon && (
          <TextWrapper>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Icon src={icon} alt="" />
              <TextWrapper style={{ fontSize: 12, paddingLeft: 4 }}>
                {bagsCount}
              </TextWrapper>
            </div>
          </TextWrapper>
        )}

        <TextWrapper>
          {title && <Title>{title}</Title>}
          {subtitle && <SubTitle>{subtitle}</SubTitle>}
          <div
            style={{
              ...styles[className!],
              ...{ fontSize: printTextLarge ? 18 : printTextSmall ? 14 : 16 }
            }}
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </TextWrapper>
      </Container>

      {rightIcon && (
        <div style={{ display: "flex" }}>
          <Icon src="/img/warning.svg" alt="" style={{ width: "60px" }} />
        </div>
      )}
    </div>
  );
};

export default OrderInfoPiece;
