export const LOG_IN = "LOG_IN";
export const LOG_OUT = "LOG_OUT";

interface LogIn {
  type: typeof LOG_IN;
  token: string;
}

interface LogOut {
  type: typeof LOG_OUT;
}

export type AuthActionTypes = LogIn | LogOut;

export interface AuthState {
  token: string;
  role: string;
}

export interface Response {
  success: boolean;
  error?: string;
}

export interface JwtUser {
  role?: string;
}
