import React, { useCallback, useState } from "react";
import { RouteComponentProps } from "react-router";
import { useSelector } from "react-redux";
import dayjs from "dayjs";

import Comments from "../comments";
import Itemizer from "../itemizer";
import OrderInfoPiece from "../order-info-piece";
import OrderView from "../order-view";
import BagInfoPiece from "../bag-info-piece";
import { saveBagItemization } from "../../store/orders/actions";
import { OrderType } from "../../store/orders/types";
import {
  Button,
  ButtonLink,
  Input,
  OrderContainer,
  OrderInfo,
  OrderLeftPanel,
  OrderRightPanel
} from "./style";
import Separator from "../../ui/separator";
import useDispatch from "../../hooks/use-thunk-dispatch";
import { commentOrder } from "../../store/orders/actions";
import Layout from "../layout";
import { AppState } from "../../store";

interface MatchParams {
  id: string;
}

interface Props extends RouteComponentProps<MatchParams> {
  onChange?: (newOrder: OrderType) => void;
}

const getItemTotals = (order: OrderType) => {
  let sum = 0;
  order.itemization.bags.forEach(b => {
    b.itemization.forEach(i => {
      sum += i.quantity;
    });
  });
  return sum;
};

const Order = ({
  match: {
    params: { id: orderId }
  }
}: Props) => {
  const dispatch = useDispatch();
  const currentOrder = useSelector<AppState, OrderType | null>(
    state => state.orders.currentOrder
  );
  const currentBagBarcode = useSelector<AppState, string | null>(
    state => state.orders.currentBagBarcode
  );
  const isOrderInItemization =
    currentOrder &&
    currentOrder.itemization &&
    currentOrder.itemization.status !== "COMPLETED";

  const [comment, setComment] = useState<string>("");
  const [barcode, setBarcode] = useState<string>("");
  const [loadingComment, setLoadingComment] = useState<boolean>(false);

  const updateOrder = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setComment(e.currentTarget.value);
  }, []);

  const updateBarcode = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setBarcode(barcode + e.currentTarget.value);
    },
    []
  );

  const sendComment = useCallback(async () => {
    setLoadingComment(true);
    await dispatch(commentOrder(orderId, comment));
    setComment("");
    setLoadingComment(false);
  }, [comment, dispatch, orderId]);

  const sendBarcode = useCallback(async () => {
    setLoadingComment(true);
    await dispatch(saveBagItemization(barcode));
    setBarcode("");
    setLoadingComment(false);
  }, [barcode, dispatch]);

  return (
    <Layout title={currentOrder ? currentOrder.customer : ""}>
      {currentOrder ? (
        <OrderContainer>
          <OrderLeftPanel
            className={
              currentOrder!.itemization.status === "COMPLETED"
                ? "orderReady"
                : "orderItemizing"
            }
          >
            <OrderInfo>
              <ButtonLink
                href={`/order/${currentOrder.id}`}
                style={{
                  display: "none",
                  backgroundColor: "none",
                  width: "auto"
                }}
              >
                -
              </ButtonLink>
              <OrderInfoPiece
                icon="/img/order-number.svg"
                content={currentOrder.id}
              />
            </OrderInfo>
            <Separator />

            <OrderInfo>
              <OrderInfoPiece
                icon="/img/star.svg"
                content={currentOrder.serviceClass}
              />
              <OrderInfoPiece
                icon="/img/profile.svg"
                content={currentOrder.customer}
              />

              {currentOrder.corporate ? (
                <OrderInfoPiece
                  icon="/img/office.svg"
                  content={currentOrder.corporate.name}
                />
              ) : null}
              <OrderInfoPiece
                icon="/img/bag.svg"
                content={`Total articles: ${getItemTotals(currentOrder)}`}
              />
            </OrderInfo>
            <Separator />

            {(isOrderInItemization ||
              currentOrder.itemization.bags.length > 0) && (
              <div>
                <OrderInfo>
                  {currentOrder.itemization.bags.map(bag => (
                    <div
                      style={{ width: "100%" }}
                      key={bag.barcode + JSON.stringify(bag.itemization)}
                    >
                      <BagInfoPiece
                        key={bag.barcode + JSON.stringify(bag.itemization)}
                        barcode={bag.barcode}
                        type={bag.type}
                        itemization={bag.itemization}
                      />
                    </div>
                  ))}
                </OrderInfo>

                {isOrderInItemization && (
                  <div>
                    <Input
                      placeholder="New barcode"
                      value={barcode}
                      onChange={updateBarcode}
                      style={{
                        marginLeft: "2%",
                        marginRight: "2%",
                        marginBottom: "10px",
                        width: "96%",
                        border: "1px solid gray"
                      }}
                    />
                    <Button
                      loading={loadingComment}
                      onClick={sendBarcode}
                      style={{
                        marginLeft: "2%",
                        marginRight: "2%",
                        marginBottom: "10px",
                        width: "96%"
                      }}
                    >
                      Add
                    </Button>
                  </div>
                )}

                <Separator />
              </div>
            )}

            {currentOrder.dropoffDriver ? (
              <OrderInfo>
                <OrderInfoPiece
                  icon="/img/car.svg"
                  title="Chauffeur"
                  content={currentOrder.dropoffDriver}
                />
              </OrderInfo>
            ) : null}

            <OrderInfo>
              <OrderInfoPiece
                icon="/img/time.svg"
                title="Nettoyage à terminer pour"
                content={dayjs(currentOrder.dropoffTime.startTime).format(
                  "DD MMM YYYY, HH:mm"
                )}
              />
              {currentOrder.notes && (
                <OrderInfoPiece
                  icon="/img/note.svg"
                  title="Commentaire du client"
                  content={currentOrder.notes}
                />
              )}
            </OrderInfo>

            <Separator />
            <Comments comments={currentOrder.comments} />
            <Input
              placeholder="Ajouter un commentaire..."
              value={comment}
              onChange={updateOrder}
              style={{
                marginLeft: "2%",
                marginRight: "2%",
                marginBottom: "10px",
                width: "96%",
                border: "1px solid gray"
              }}
            />
            <Button
              loading={loadingComment}
              onClick={sendComment}
              style={{
                marginLeft: "2%",
                marginRight: "2%",
                marginBottom: "10px",
                width: "96%"
              }}
            >
              Commenter
            </Button>
          </OrderLeftPanel>
          <OrderRightPanel>
            {currentBagBarcode ? (
              <Itemizer
                status={currentOrder.cleaning.status}
                itemization={currentOrder.itemization}
              />
            ) : (
              <OrderView />
            )}
          </OrderRightPanel>
        </OrderContainer>
      ) : (
        <div>Commande vide</div>
      )}
    </Layout>
  );
};

export default Order;
