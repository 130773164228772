const black = "#000";
const border = "#aca9a9";
const borderLight = "#e1e0e0";
const disabled = "grey";
const error = "#d9414e";
const orange = "orange";
const secondary = "#00afff";
const secondaryDarken15 = "#0094d8";
const secondaryTransparentize50 = "rgba(0,175,255,0.25)";
const shadow = "0 3px 6px rgba(0,0,0,0.10), 0 3px 6px rgba(0,0,0,0.15)";
const success = "#00cc88";
const primary = "#122630";
const primaryLight = "#1e3f50";
const textPrimary = "#000";
const textLight = "#786e6e";
const title = "#786e6e";
const grey = "#999999";
const lightGrey = "#cfcfcf";
const white = "#fff";
const orderItemizing = "#FF000015";
const orderReady = "#00800015";

export default {
  black,
  border,
  borderLight,
  disabled,
  error,
  grey,
  lightGrey,
  orange,
  primary,
  primaryLight,
  secondary,
  secondaryDarken15,
  secondaryTransparentize50,
  shadow,
  success,
  textPrimary,
  textLight,
  title,
  white,
  orderItemizing,
  orderReady
};
