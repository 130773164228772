import React, { useEffect, useCallback } from "react";
import useDispatch from "../../hooks/use-thunk-dispatch";
import { useSelector } from "react-redux";
import { useToast } from "../toast";
import Layout from "../layout";
import DataTable from "react-data-table-component";
import { AppState } from "../../store";
import {
  getOperationAreas,
  postOperationArea,
  putOperationArea
} from "../../store/operationAreas/actions";
import { OperationArea } from "../../typings/operationArea";
import { Button, ActionButton, SearchInput } from "./style";
import OperationAreasMap from "../operationAreas-map";
import { Link } from "react-router-dom";
import { FaEdit, FaInfo, FaUnlock, FaLock } from "react-icons/fa";
import ReactTooltip from "react-tooltip";
import { Modal } from "../modal/";
import { CheckModal } from "../checkAvailability-modal";
import { useModal } from "../modal/useModal";
import { confirmAlert } from "react-confirm-alert";

sessionStorage.setItem("operationAreaId", "");

const Areas = () => {
  const dispatch = useDispatch();
  const { showToast } = useToast();
  const [pending, setPending] = React.useState(true);
  const data: OperationArea[] = useSelector<AppState, OperationArea[]>(
    state => state.operationAreas.allOperationAreas
  );
  const [tableRowsData, setTableRowsData] = React.useState(data);
  const hiddenFileInput = React.useRef<HTMLInputElement>(null);

  const { isShown, toggle } = useModal();

  const handleEnable = useCallback(
    async row => {
      let operationArea = {
        id: row.id,
        name: row.name,
        isEnabled: !row.isEnabled,
        location: {
          coordinates: row.location.coordinates,
          type: row.location.type
        }
      } as OperationArea;

      confirmAlert({
        title: "Confirmer ",
        message: row.isEnabled
          ? "êtes vous sûr de vouloir désactiver la zone " + row.name
          : "êtes vous sûr de vouloir activer la zone " + row.name,

        buttons: [
          {
            label: "oui",
            onClick: async () => {
              const response = await dispatch(putOperationArea(operationArea));
              if (response.success) {
                showToast(
                  row.isEnabled
                    ? "La zone " + row.name + " est désactivée "
                    : "La zone " + row.name + " est activée "
                );
                await dispatch(getOperationAreas());
              } else {
                showToast("Une erreur est survenue");
              }
            }
          },
          {
            label: "non",
            onClick: () => {
              //
            }
          }
        ]
      });
    },
    [dispatch]
  );

  const columns = [
    {
      name: "Id",
      selector: "id",
      sortable: true
    },
    {
      name: "Nom",
      selector: "name",
      sortable: true
    },
    {
      name: "Actif",
      selector: d => d.isEnabled,
      cell: row => {
        return (function change() {
          if (row.isEnabled) {
            return "oui";
          } else {
            return "non";
          }
        })();
      },
      sortable: true
    },
    {
      name: "Type",
      selector: "location.type",
      sortable: true
    },
    {
      name: "Actions",
      selector: "id",
      cell: row => {
        return (function Actions() {
          return (
            <div>
              <Link to={"/OperationAreas/edit/" + row.id}>
                <ActionButton data-tip data-for="UpdateTip">
                  <FaEdit></FaEdit>
                </ActionButton>
              </Link>
              <ReactTooltip id="UpdateTip" place="top" effect="solid">
                Modifier
              </ReactTooltip>
              <ActionButton
                data-tip
                data-for="TestOperationAreaTip"
                onClick={() => {
                  toggle();
                  sessionStorage.setItem("operationAreaId", row.id);
                }}
              >
                <FaInfo></FaInfo>
              </ActionButton>
              <ReactTooltip
                id="TestOperationAreaTip"
                place="top"
                effect="solid"
              >
                Vérifier les disponibilités de cette zone géographique.
              </ReactTooltip>

              <ActionButton
                data-tip
                data-for="enable"
                onClick={() => {
                  handleEnable(row);
                }}
              >
                {row.isEnabled ? <FaLock></FaLock> : <FaUnlock></FaUnlock>}
              </ActionButton>
              <ReactTooltip id="enable" place="top" effect="solid">
                Activer/désactiver une zone
              </ReactTooltip>
            </div>
          );
        })();
      }
    }
  ];

  function filterDatas(searchInput) {
    let searchData = data.filter(item => {
      if (
        item.id
          .toString()
          .toLowerCase()
          .includes(searchInput.toLowerCase())
      ) {
        return item;
      }
    });
    setTableRowsData(searchData);
  }

  useEffect(() => {
    setPending(false);
  }, [dispatch]);

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      await dispatch(getOperationAreas());
    };
    if (pending) {
      fetchData();
    }
  }, [pending]);

  useEffect(() => {
    if (!pending) {
      filterDatas("");
    }
  }, [data]);

  const handleClick = e => {
    if (hiddenFileInput != null && hiddenFileInput.current != null) {
      hiddenFileInput.current.click();
    }
  };

  function readFilesSequentially(fileReader, allFiles, currentIndex) {
    fileReader.readAsText(allFiles[currentIndex], "UTF-8");
    fileReader.onload = async e => {
      const result = fileReader.result as string;
      const resultObject = JSON.parse(result).geometry as OperationArea;
      const name = JSON.parse(result).properties["nom"];
      const code = JSON.parse(result).properties["code"];
      const id = (code + "-" + name)
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .replace(/\s+/g, "");
      const operationArea = {
        id: id,
        name: name,
        isEnabled: false,
        location: {
          coordinates: resultObject["coordinates"],
          type: resultObject["type"]
        }
      } as OperationArea;
      setPending(true);
      await dispatch(postOperationArea(operationArea));
      await dispatch(getOperationAreas());
      setPending(false);
    };
    fileReader.onloadend = e => {
      showToast(
        "Zone géographique " +
          (currentIndex + 1) +
          "/" +
          allFiles.length +
          " ajoutée avec succès."
      );
      if (allFiles.length > currentIndex + 1) {
        readFilesSequentially(fileReader, allFiles, currentIndex + 1);
      } else {
        setPending(false);
      }
    };
  }

  const onGlobalSearchChange = async e => {
    filterDatas(e.target.value.toLowerCase());
  };

  const handleUpload = e => {
    const fileReader = new FileReader();
    readFilesSequentially(fileReader, e.target.files, 0);
  };

  return (
    <Layout title="Zones géographiques " showSearchBag={false}>
      <div className="App">
        <Modal
          isShown={isShown}
          hide={toggle}
          headerText=""
          modalContent={<CheckModal message="Vérifier les disponibilités" />}
        />
        <div>
          <Link to="/OperationAreas/edit">
            <Button>Ajouter une nouvelle zone</Button>
          </Link>
          <Button onClick={handleClick}>Upload multiple</Button>
          <input
            ref={hiddenFileInput}
            multiple
            type="file"
            onChange={handleUpload}
            style={{ display: "none" }}
          />
          <SearchInput
            placeholder="Rechercher..."
            type="text"
            onChange={onGlobalSearchChange}
          />
        </div>

        <DataTable
          title="Zone géographiques "
          columns={columns}
          data={tableRowsData}
          defaultSortField="id"
          pagination
          responsive
          progressPending={pending}
        />
        <OperationAreasMap />
      </div>
    </Layout>
  );
};

export default Areas;
