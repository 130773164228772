import React, { FC, useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import BarcodeReader from "react-barcode-reader";

import { useToast, TYPE as TOAST_TYPE } from "../toast";
import useThunkDispatch from "../../hooks/use-thunk-dispatch";
import { AppState } from "../../store";
import { OrderType } from "../../store/orders/types";
import {
  clearSearched,
  searchOrderByBag,
  endCleaning
} from "../../store/orders/actions";
import {
  Container,
  PadClose,
  PadContainer,
  PadNumber,
  PadValue,
  ShowButton
} from "./style";

const BARCODE_LENGTH = 8;

interface PadNumberProps {
  barcode: string;
  num: number;
  setBarcode: (barcode: string) => void;
}

const keyListener = async (token, setBarcode, dispatch, showToast) => {
  if (isNaN(parseInt(token))) {
    const response = await dispatch(endCleaning({ orderId: token }, false));

    if (response.success) {
      showToast(token + " cleaning completed");
    } else {
      showToast(response.error);
    }

    return;
  }

  if (token.length > BARCODE_LENGTH) {
    token = token.substring(0, 8);
  }

  if (token.length <= BARCODE_LENGTH) {
    setBarcode(token);

    if (token.length === BARCODE_LENGTH) {
      const orderSearch = await dispatch(searchOrderByBag(token));

      if (!orderSearch.success) {
        showToast("Commande non trouvée", { type: TOAST_TYPE.ERROR });
        setBarcode("");
      }
    }
  }
};

const PadNumberButton: FC<PadNumberProps> = ({ barcode, num, setBarcode }) => {
  const { showToast } = useToast();
  const dispatch = useThunkDispatch();
  const appendBarcode = useCallback(async () => {
    keyListener(barcode + num, setBarcode, dispatch, showToast);
  }, [barcode, dispatch, num, setBarcode, showToast]);
  return <PadNumber onClick={appendBarcode}>{num}</PadNumber>;
};

const SearchBag = () => {
  const { showToast } = useToast();
  const history = useHistory();
  const dispatch = useThunkDispatch();
  const currentOrder = useSelector<AppState, OrderType | null>(
    state => state.orders.currentOrder
  );
  const searched = useSelector<AppState, boolean>(
    state => state.orders.searched
  );
  useEffect(() => {
    if (currentOrder && searched) {
      dispatch(clearSearched());
      history.push(`/order/${currentOrder.id}`);
    }
  }, [currentOrder, dispatch, history, searched]);
  const [showPad, setShowPad] = useState<boolean>(false);
  const [barcode, setBarcode] = useState<string>("");
  const togglePad = useCallback(() => {
    setBarcode("");
    setShowPad(!showPad);
  }, [showPad, setBarcode, setShowPad]);
  const delBarcode = useCallback(() => {
    if (barcode.length > 0) {
      setBarcode(barcode.slice(0, -1));
    }
  }, [barcode, setBarcode]);
  const clearBarcode = useCallback(() => {
    dispatch(clearSearched());
  }, [setBarcode]);

  return (
    <Container>
      {showPad ? (
        <PadContainer>
          <PadValue>{barcode}</PadValue>
          <PadClose onClick={togglePad}>
            <img src="/img/close.svg" alt="fermer" />
          </PadClose>
          {[1, 2, 3, 4, 5, 6, 7, 8, 9].map(num => (
            <PadNumberButton
              barcode={barcode}
              key={num}
              num={num}
              setBarcode={setBarcode}
            />
          ))}
          <PadNumber onClick={clearBarcode}>C</PadNumber>
          <PadNumberButton barcode={barcode} num={0} setBarcode={setBarcode} />
          <PadNumber onClick={delBarcode}>←</PadNumber>
        </PadContainer>
      ) : (
        <ShowButton onClick={togglePad}>Chercher un sac</ShowButton>
      )}

      <BarcodeReader
        onScan={data => keyListener(data, setBarcode, dispatch, showToast)}
      />
    </Container>
  );
};

export default SearchBag;
