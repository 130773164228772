import React, { ReactNode, useCallback } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
// import { slide as Menu } from "react-burger-menu";

import useDispatch from "../../hooks/use-thunk-dispatch";
import { logout as logoutAction } from "../../store/auth/actions";
import {
  ContentWrapper,
  Header,
  LogoutButton,
  Left,
  Title,
  Right,
  Wrapper,
  Select,
  Center
} from "./style";
// import MenuButton from "../menu-button";
import SearchBag from "../search-bag";
import SearchBar from "@opuscapita/react-searchbar";
import Menu from "../menu";

interface Layout {
  children: ReactNode;
  title: string;
  fixed?: boolean;
  showSearch?: boolean;
  showSearchBag?: boolean;
  showDatesDropdown?: boolean;
  callback?: (text: string) => void;
  filterDates?: string | null;
  changeDatesCallback?: (text: string) => void;
}

const Layout = ({
  title,
  children,
  fixed = false,
  showSearch = false,
  showSearchBag = true,
  showDatesDropdown = false,
  callback,
  filterDates,
  changeDatesCallback
}: Layout) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const logout = useCallback(async () => {
    await dispatch(logoutAction());
    history.push("/");
  }, [dispatch, history]);

  const datesOptions = {
    all: "",
    today:
      moment()
        .startOf("day")
        .unix() +
      "-" +
      moment()
        .add(1, "d")
        .startOf("day")
        .unix(),
    tomorrow:
      moment()
        .add(1, "d")
        .startOf("day")
        .unix() +
      "-" +
      moment()
        .add(2, "d")
        .startOf("day")
        .unix(),
    later:
      moment()
        .add(2, "d")
        .startOf("day")
        .unix() +
      "-" +
      moment()
        .add(1, "y")
        .startOf("day")
        .unix()
  };

  const getDropdownOptions = value => {
    const props = {};

    props["value"] = datesOptions[value];

    if (props["value"] == filterDates) {
      props["selected"] = "selected";
    }

    return props;
  };

  return (
    <Wrapper fixed={fixed}>
      <Header>
        <Menu />
        <Left>
          {/* <Menu>
            <a id="home" className="menu-item" href="/">
              Home
            </a>
            <a id="about" className="menu-item" href="/about">
              About
            </a>
            <a id="contact" className="menu-item" href="/contact">
              Contact
            </a>
            <a onClick={navigateTo} className="menu-item--small" href="">
              Settings
            </a>
          </Menu> */}
        </Left>
        <Center>
          <Title>{title}</Title>
          {showDatesDropdown ? (
            <Select
              onChange={input =>
                changeDatesCallback &&
                changeDatesCallback(input.currentTarget.value)
              }
            >
              <option {...getDropdownOptions("all")}>toute</option>
              <option {...getDropdownOptions("today")}>aujourd&apos;hui</option>
              <option {...getDropdownOptions("tomorrow")}>demain</option>
              <option {...getDropdownOptions("later")}>après-demain</option>
            </Select>
          ) : null}
        </Center>
        <Right style={{ display: "flex", alignContent: "center" }}>
          {showSearch ? (
            <div style={{ marginRight: "40px" }}>
              <SearchBar
                className="oc-search-bar"
                minChars={2}
                tooltipDelay={0}
                isDynamic={true}
                onSearch={text => callback && callback(text)}
                onClear={text => callback && callback(text)}
                placeholder="Placeholder"
              />
            </div>
          ) : null}

          <div>
            <LogoutButton onClick={logout}>
              <img src="/img/logout.svg" alt="Se déconnecter" />
            </LogoutButton>
          </div>
        </Right>
      </Header>
      <ContentWrapper>{children}</ContentWrapper>
      {showSearchBag ? <SearchBag /> : null}
    </Wrapper>
  );
};

export default Layout;
