import styled from "styled-components";
import colors from "../../../style/colors";

export const Title = styled.h1``;

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 10px 0;
  width: 100%;
`;

export const BackButton = styled.button`
  background-color: ${colors.secondary};
  border: none;
  border-radius: 25px;
  color: ${colors.white};
  font-weight: bold;
  height: 50px;
  line-height: 50px;
  text-align: center;
  width: 50px;
`;

export const EditButton = styled.button`
  background-color: ${colors.secondary};
  border: none;
  border-radius: 25px;
  color: ${colors.white};
  font-weight: bold;
  height: 50px;
  line-height: 50px;
  text-align: center;
  width: 250px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 0 20px 20px 20px;
  width: 100%;
`;
