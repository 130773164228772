import styled from "styled-components";

export const ActionButton = styled.button`
  background-color: #122630;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  margin: 0px;
  cursor: pointer;
  padding: 2px;
`;
