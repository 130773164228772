import React, { useEffect, useState, useCallback } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { AppState } from "../../store";
import Layout from "../layout";
import { getOperationAreas } from "../../store/operationAreas/actions";
import { OperationArea } from "../../typings/operationArea";
import { Availability } from "../../typings/availability";
import {
  getAvailabilities,
  postAvailability,
  putAvailability,
  deleteAvailability
} from "../../store/availabilities/actions";

import Select from "react-select";
import {
  Div,
  Input,
  Label,
  Number,
  ButtonAction,
  ButtonAnnuler,
  ButtonSupprimer,
  ButtonWithSelect,
  RowWrapper
} from "./style";
import { Link } from "react-router-dom";
import useDispatch from "../../hooks/use-thunk-dispatch";
import { confirmAlert } from "react-confirm-alert";
import { useToast } from "../toast";

import "./style/index.css";

const AvailabilityEdit = () => {
  const dispatch = useDispatch();
  const [pending, setPending] = React.useState(true);
  const params = useParams();
  const editMode = params["id"];
  const title = editMode ? "Modifier" : "Ajouter";
  const [inprogress, setInprogress] = React.useState(false);
  const [suppressionProgress, setSuppressionProgress] = React.useState(false);
  const [actionProgress, setActionProgress] = React.useState(false);

  const editable = useSelector<AppState, Availability>(state => {
    if (editMode) {
      return state.Availabilities.allAvailabilities.find(
        item => item.id === params["id"]
      ) as Availability;
    }
    return {
      id: params["id"],
      timeslotStart: "",
      timeslotEnd: "",
      date: "",
      maxNumberOfOrders: 0,
      remNumberOfOrders: 0
    } as Availability;
  });

  const [id, setId] = useState(params["id"]);
  const [operationArea, setOperationArea] = useState(
    editMode ? editable.operationArea : [""]
  );
  const [timeslotStart, setTimeslotStart] = useState(editable.timeslotStart);
  const [timeslotEnd, setTimeslotEnd] = useState(editable.timeslotEnd);
  const [date, setDate] = useState(editable.date);
  const [maxNumberOfOrders, setMaxNumberOfOrders] = useState(
    editable.maxNumberOfOrders
  );
  const [remNumberOfOrders, setRemNumberOfOrders] = useState(
    editable.remNumberOfOrders
  );
  const operationAreas: OperationArea[] = useSelector<
    AppState,
    OperationArea[]
  >(state => state.operationAreas.allOperationAreas);
  const [options, setOptions] = useState(
    operationAreas.map(e => ({
      label: e.name,
      value: e._id
    }))
  );

  const [selectedOptions, setSelectedOptions] = useState(
    operationAreas.map(e => ({
      label: e.name,
      value: e._id
    }))
  );

  const { showToast } = useToast();
  const history = useHistory();

  useEffect(() => {
    dispatch(getOperationAreas());
  }, [dispatch]);

  useEffect(() => {
    setOptions(
      operationAreas.map(e => ({
        label: e.name,
        value: e._id
      }))
    );
    if (editable) {
      setSelectedOptions(options.filter(s => s.value == operationArea));
    }
  }, [operationAreas]);

  const handleDelete = useCallback(
    async id => {
      confirmAlert({
        title: "Confirmer la supression",
        message:
          "Etes-vous sûr que vous voulez supprimer cette disponibilité ?",
        buttons: [
          {
            label: "oui",
            onClick: async () => {
              setSuppressionProgress(true);
              setInprogress(true);
              const response = await dispatch(deleteAvailability(id));
              if (response.success) {
                showToast("Suppression effectuée avec succès");
                history.push(`/availability`);
              } else {
                showToast("Erreur lors de la suppression.");
                setSuppressionProgress(false);
                setInprogress(false);
              }
            }
          },
          {
            label: "non",
            onClick: () => {
              //
            }
          }
        ]
      });
    },
    [dispatch]
  );

  const renderSupprimerButton = () => {
    if (editMode) {
      return (
        <ButtonSupprimer
          onClick={() => handleDelete(editable.id)}
          disabled={inprogress}
        >
          {suppressionProgress ? "en cours..." : "supprimer"}
        </ButtonSupprimer>
      );
    } else {
      return null;
    }
  };

  const handleSubmit = useCallback(
    async (
      operationAreas,
      timeslotStart,
      timeslotEnd,
      date,
      maxNumberOfOrders,
      remNumberOfOrders
    ) => {
      if (editMode) {
        let availability = {
          operationArea: operationAreas[0]!.value,
          timeslotStart: timeslotStart,
          timeslotEnd: timeslotEnd,
          date: date,
          maxNumberOfOrders: maxNumberOfOrders,
          remNumberOfOrders: remNumberOfOrders
        } as Availability;
        setActionProgress(true);
        setInprogress(true);
        let response = await dispatch(putAvailability(availability, id));
        if (response.success) {
          showToast("Modification effectuée avec succès");
          history.push(`/availability`);
        } else {
          setActionProgress(false);
          setInprogress(false);
          showToast("Veuillez  vérifier les infomrations entrées");
        }
      } else {
        operationAreas.forEach(async element => {
          let availability = {
            operationArea: element.value,
            timeslotStart: timeslotStart,
            timeslotEnd: timeslotEnd,
            date: date,
            maxNumberOfOrders: maxNumberOfOrders,
            remNumberOfOrders: remNumberOfOrders
          } as Availability;
          setActionProgress(true);
          setInprogress(true);
          let response = await dispatch(postAvailability(availability));
          if (response.success) {
            showToast(
              "Ajout de la disponibilité à la zone " +
                element.label +
                "  effectuée avec succès"
            );
            history.push(`/availability`);
          } else {
            setActionProgress(false);
            setInprogress(false);
            showToast("Veuillez  vérifier les infomrations entrées");
          }
        });
      }
    },
    [dispatch]
  );

  const handleSelectAllClick = e => {
    setSelectedOptions(options.filter(o => false));
    setSelectedOptions(options);
  };

  const handleSelectActivesClick = e => {
    setSelectedOptions(options.filter(o => false));
    setSelectedOptions(
      operationAreas
        .filter(o => o.isEnabled === true)
        .map(e => ({
          label: e.name,
          value: e._id
        }))
    );
  };

  console.log(editMode);
  return (
    <Layout title={title} showSearchBag={false}>
      <div className="App">
        <Div>
          {editMode ? (
            <Label className="block">Zone géographique</Label>
          ) : (
            <RowWrapper hidden={editMode}>
              <Label className="block">Zones géographiques</Label>
              <div style={{ width: "100px" }} />
              <ButtonWithSelect
                className="block"
                onClick={handleSelectAllClick}
              >
                Tout
              </ButtonWithSelect>
              <ButtonWithSelect
                className="block"
                onClick={handleSelectActivesClick}
              >
                Actifs
              </ButtonWithSelect>
            </RowWrapper>
          )}
          <Select
            classNamePrefix="react-select"
            isMulti={editMode ? false : true}
            options={options}
            value={selectedOptions}
            onChange={e =>
              editMode
                ? setSelectedOptions(e == null ? "" : e)
                : setSelectedOptions(e == null ? [] : e)
            }
          />

          <Label>Commence à</Label>
          <Input
            type="time"
            value={timeslotStart}
            onChange={e => setTimeslotStart(e.target.value)}
          ></Input>
          <Label>fini à</Label>
          <Input
            type="time"
            value={timeslotEnd}
            onChange={e => setTimeslotEnd(e.target.value)}
          ></Input>
          <Label>Date</Label>
          <Input
            type="date"
            value={date}
            onChange={e => setDate(e.target.value)}
          ></Input>
          <Label>nombre maximum de commandes</Label>
          <Number
            type="number"
            value={maxNumberOfOrders}
            onChange={e => setMaxNumberOfOrders(+e.target.value)}
          ></Number>
          <Label>Nombre de commandes restantes </Label>
          <Number
            type="number"
            value={remNumberOfOrders}
            onChange={e => setRemNumberOfOrders(+e.target.value)}
          ></Number>
          <ButtonAction
            onClick={() =>
              handleSubmit(
                selectedOptions,
                timeslotStart,
                timeslotEnd,
                date,
                maxNumberOfOrders,
                remNumberOfOrders
              )
            }
            disabled={inprogress}
          >
            {actionProgress ? "En cours..." : title}
          </ButtonAction>
          <Link to="/availability">
            <ButtonAnnuler disabled={inprogress}>Annuler</ButtonAnnuler>
          </Link>
          {renderSupprimerButton()}
        </Div>
      </div>
    </Layout>
  );
};

export default AvailabilityEdit;
