import React, { FC, CSSProperties } from "react";

import { Container, Loader, Text, BUTTON_TYPE } from "./style";

export { BUTTON_TYPE };

interface Props {
  children: string | JSX.Element;
  loading?: boolean;
  type?: BUTTON_TYPE;
  style?: CSSProperties;
  disabled?: boolean;
  onClick: () => void;
}

const Button: FC<Props> = ({
  children,
  disabled = false,
  loading = false,
  type = BUTTON_TYPE.PRIMARY,
  style,
  ...props
}) => (
  <Container
    style={style}
    disabled={disabled || loading}
    type={type}
    {...props}
  >
    {loading ? (
      <Loader>
        <img src="/img/spinner.svg" alt="Chargement..." />
      </Loader>
    ) : (
      <Text type={type}>{children}</Text>
    )}
  </Container>
);

export default Button;
