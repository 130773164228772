import styled from "styled-components";

export const Select = styled.select`
  box-sizing: border-box;
  border-radius: 4px;
  width: 30%;
  border: 1px solid black;
  padding: 5px 15px;
  margin-bottom: 10px;
  font-size: 18px;
`;

export const Button = styled.button`
  background-color: #122630;
  color: white;
  font-size: 20px;
  padding: 5px 5px;
  border-radius: 5px;
  margin: 10px 10px;
  cursor: pointer;
  float: right;
`;

export const Editbutton = styled.button`
  background-color: #122630;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  padding: 10px 25px;
`;
export const Label = styled.label`
  line-height: 2;
  text-align: left;
  padding: 25px 50px;
  color: black;
  font-size: 20px;
  font-weight: 200;
`;
export const Div = styled.div`
  margin: 2% auto;
  padding: 5% auto;
  height: 600px;
  width: 95%;
`;

export const Input = styled.input`
  display: block;
  box-sizing: border-box;
  width: 100%;
  border-radius: 4px;
  border: 1px solid black;
  padding: 10px 15px;
  margin-left: 3px;
  font-size: 14px;
`;
