import React, {
  ReactNode,
  useState,
  useCallback,
  useContext,
  createContext,
  useEffect
} from "react";

import { Wrapper, Toast as UIToast, TYPE } from "./style";

export { TYPE };

interface ToastContext {
  showToast: (toast: ReactNode, options?: Partial<Options>) => void;
}

const initialContext: ToastContext = {
  showToast: () => {
    //
  }
};

const ToastContext = createContext<ToastContext>(initialContext);

export interface ToastProviderProps {
  children: ReactNode;
}

function delay(ms: number) {
  return new Promise<void>(resolve => {
    setTimeout(resolve, ms);
  });
}

interface ToastProps {
  children: ReactNode;
  options: Options;
}

function Toast({ children, options: { type, duration } }: ToastProps) {
  const [fadeOut, setFadeOut] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setFadeOut(true);
    }, duration - 300);

    return () => {
      setFadeOut(false);
      clearTimeout(timeout);
    };
  }, [setFadeOut, duration]);

  return (
    <UIToast type={type} out={fadeOut}>
      {children}
    </UIToast>
  );
}

interface Options {
  duration: number;
  type?: TYPE;
}

interface State {
  [id: string]: {
    node: ReactNode;
    options: Options;
  };
}

const defaultOptions = {
  duration: 3000
};

export function ToastProvider({ children }: ToastProviderProps) {
  const [toasts, setToasts] = useState<State>({});

  const showToast = useCallback(
    async (
      node: ReactNode,
      partialOptions: Partial<Options> = defaultOptions
    ) => {
      const id = new Date().getTime().toString();

      const options = { ...defaultOptions, ...partialOptions };

      setToasts(toasts => ({
        ...toasts,
        [id]: { node, options }
      }));

      await delay(options.duration);

      setToasts(({ [id]: old, ...toasts }) => toasts);
    },
    [setToasts]
  );

  const arrayOfToasts = Object.keys(toasts)
    .reverse()
    .map(id => ({
      id,
      toast: toasts[id]
    }));

  return (
    <ToastContext.Provider value={{ showToast }}>
      {children}
      {arrayOfToasts.length > 0 && (
        <Wrapper>
          <div>
            {arrayOfToasts.map(({ id, toast }) => (
              <Toast key={id} options={toast.options}>
                {toast.node}
              </Toast>
            ))}
          </div>
        </Wrapper>
      )}
    </ToastContext.Provider>
  );
}

export function useToast() {
  return useContext(ToastContext);
}
