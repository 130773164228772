import { OperationArea } from "../../typings/operationArea";

export const SET_OPERATIONAREAS = "SET_OPERATIONAREAS";
export const POST_OPERATIONAREA_SUCCESS = "POST_OPERATIONAREA_SUCCESS";
export const PUT_OPERATIONAREA_SUCCESS = "PUT_OPERATIONAREA_SUCCESS";
export const DELETE_OPERATIONAREA_SUCCESS = "DELETE_OPERATIONAREA_SUCCESS";

interface SetOperationAreas {
  type: typeof SET_OPERATIONAREAS;
  operationAreas: OperationArea[];
}

export type OperationAreasActionTypes = SetOperationAreas;

export interface OperationAreasState {
  allOperationAreas: OperationArea[];
}
