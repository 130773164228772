import React, { useEffect, useState, useCallback } from "react";
import Layout from "../layout";
import { useParams, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { AppState } from "../../store";
import { Link } from "react-router-dom";
import useDispatch from "../../hooks/use-thunk-dispatch";
import {
  ServiceClasse,
  ServiceClasseMap,
  ServiceClasseUpdate
} from "../../typings/serviceClasse";
import {
  getServiceClasses,
  putServiceClasse,
  postServiceClasse,
  deleteServiceClasse,
  getServiceClasse
} from "../../store/serviceClasses/actions";
import { useToast } from "../toast";
import { confirmAlert } from "react-confirm-alert";
import LoadingSpinner from "../spinner/LoadingSpinner";

import {
  Div,
  Input,
  Label,
  Number,
  ButtonAction,
  ButtonAnnuler,
  ButtonSupprimer
} from "./style";

const ServiceClassesEdit = () => {
  const [pending, setPending] = React.useState(true);
  const [loading, setLoading] = React.useState(true);
  const params = useParams();
  const serviceClasseId = params["id"];
  const editMode = params["id"];
  const dispatch = useDispatch();
  const { showToast } = useToast();
  const history = useHistory();
  const [inprogress, setInprogress] = React.useState(false);
  const [suppressionProgress, setSuppressionProgress] = React.useState(false);
  const [actionProgress, setActionProgress] = React.useState(false);

  const [id, setId] = useState("");
  const [name, setName] = useState({ fr: "", en: "" });
  const [minOrderValue, setMinOrderValue] = useState(0);
  const [pickupLeadTime, setPickupLeadTime] = useState(0);
  const [timeslotDuration, setTimeslotDuration] = useState(0);
  const [sortOrder, setSortOrder] = useState(0);
  const [price, setPrice] = useState(0);

  const serviceClasse = useSelector<AppState, ServiceClasseUpdate>(
    state => state.serviceClasses.serviceClasse
  );
  useEffect(() => {
    setPending(true);
    dispatch(getServiceClasse(serviceClasseId));
    setPending(false);
  }, [dispatch]);

  useEffect(() => {
    if (params["id"]) {
      setPending(true);
      setId(serviceClasse.id);
      setName(serviceClasse.name);
      setMinOrderValue(serviceClasse.minOrderValue);
      setPickupLeadTime(serviceClasse.pickupLeadTime);
      setTimeslotDuration(serviceClasse.timeslotDuration);
      setSortOrder(serviceClasse.sortOrder);
      setPrice(serviceClasse.price);
      setPending(false);
    }
  }, [serviceClasse]);

  const title = serviceClasseId ? "Modifier" : "Ajouter";

  const handleDelete = useCallback(
    async id => {
      confirmAlert({
        title: "Confirmer la supression",
        message: "Etes-vous sûr que vous voulez supprimer ce service ",
        buttons: [
          {
            label: "oui",
            onClick: async () => {
              setSuppressionProgress(true);
              setInprogress(true);
              const response = await dispatch(deleteServiceClasse(id));
              if (response.success) {
                showToast("Suppression effectuée avec succès");
                history.push(`/serviceclasses`);
              } else {
                setSuppressionProgress(false);
                setInprogress(false);
                showToast("Erreur lors de la suppression.");
              }
            }
          },
          {
            label: "non",
            onClick: () => {
              //
            }
          }
        ]
      });
    },
    [dispatch]
  );

  const renderSupprimerButton = () => {
    if (serviceClasseId) {
      return (
        <ButtonSupprimer onClick={() => handleDelete(id)} disabled={inprogress}>
          {suppressionProgress ? "En cours..." : "Supprimer"}
        </ButtonSupprimer>
      );
    } else {
      return null;
    }
  };

  const handleSubmit = useCallback(
    async (
      id,
      name,
      minOrderValue,
      pickupLeadTime,
      timeslotDuration,
      sortOrder,
      price
    ) => {
      const serviceclass = {
        id,
        name: {
          fr: name.fr,
          en: name.en
        },
        minOrderValue,
        pickupLeadTime,
        timeslotDuration,
        sortOrder,
        price
      } as ServiceClasseUpdate;

      console.log(serviceclass);
      setActionProgress(true);
      setInprogress(true);
      const response = serviceClasseId
        ? await dispatch(putServiceClasse(serviceclass, id))
        : await dispatch(postServiceClasse(serviceclass));
      if (response.success) {
        serviceClasseId
          ? showToast("Modification effectuée avec succès")
          : showToast("Ajout effectuée avec succès");
        history.push(`/serviceclasses`);
      } else {
        setActionProgress(false);
        setInprogress(false);
        showToast("Veuillez  vérifier les infomrations entrées");
      }
    },
    [dispatch]
  );

  const renderForm = (
    <Div>
      <Label>Id</Label>
      <Input value={id} onChange={e => setId(e.target.value)}></Input>
      <Label>nom-fr</Label>
      <Input
        value={name ? name.fr : ""}
        onChange={e => setName({ ...name, fr: e.target.value })}
      ></Input>
      <Label>nom-en</Label>
      <Input
        value={name ? name.en : ""}
        onChange={e => setName({ ...name, en: e.target.value })}
      ></Input>

      <Label>Valeur minimale de la commande</Label>
      <Number
        type="number"
        value={minOrderValue}
        onChange={e => setMinOrderValue(+e.target.value)}
      ></Number>
      <Label>Délai de ramassage</Label>
      <Number
        type="number"
        value={pickupLeadTime}
        onChange={e => setPickupLeadTime(+e.target.value)}
      ></Number>
      <Label>durée du créneau horaire</Label>
      <Number
        type="number"
        value={timeslotDuration}
        onChange={e => setTimeslotDuration(+e.target.value)}
      ></Number>
      <Label>Ordre de tri</Label>
      <Number
        type="number"
        value={sortOrder}
        onChange={e => setSortOrder(+e.target.value)}
      ></Number>
      <Label>Prix</Label>
      <Number
        type="number"
        value={price}
        onChange={e => setPrice(+e.target.value)}
      ></Number>

      <ButtonAction
        onClick={() =>
          handleSubmit(
            id,
            name,
            minOrderValue,
            pickupLeadTime,
            timeslotDuration,
            sortOrder,
            price
          )
        }
        disabled={inprogress}
      >
        {actionProgress ? "En cours..." : title}
      </ButtonAction>
      <Link to="/serviceclasses">
        <ButtonAnnuler disabled={inprogress}>Annuler</ButtonAnnuler>
      </Link>
      {renderSupprimerButton()}
    </Div>
  );

  return (
    <Layout title={title} showSearchBag={false}>
      <div className="App">{pending ? <LoadingSpinner /> : renderForm}</div>
    </Layout>
  );
};

export default ServiceClassesEdit;
