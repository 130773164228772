import {
  SET_CATEGORIES,
  SET_PRODUCTS,
  ProductsActionTypes,
  ProductsState
} from "./types";
import { CategoryMap } from "../../typings/category";

const initialState: ProductsState = {
  allProducts: [],
  categories: {}
};

const ProductsReducer = (
  state = initialState,
  action: ProductsActionTypes
): ProductsState => {
  switch (action.type) {
    case SET_PRODUCTS: {
      return {
        ...state,
        allProducts: action.products
      };
    }
    case SET_CATEGORIES: {
      return {
        ...state,
        categories: action.categories.reduce((categoryMap, category) => {
          categoryMap[category.id] = category;
          return categoryMap;
        }, {} as CategoryMap)
      };
    }
    default:
      return state;
  }
};

export default ProductsReducer;
