import React, { Fragment } from "react";
import { Toggle as ToggleWrapper } from "./style";

interface Option<T> {
  value: T;
  text: string;
}

interface Toggle<T> {
  defaultValue?: T;
  onChange: (v: T) => void;
  options: Option<T>[];
}

function Toggle<T>({ defaultValue, options, onChange }: Toggle<T>) {
  return (
    <ToggleWrapper>
      {options.map(({ value, text }, index) => {
        const stringifiedValue: string = index.toString();

        return (
          <Fragment key={stringifiedValue}>
            <input
              type="radio"
              id={stringifiedValue}
              name="user-type"
              value={stringifiedValue}
              onChange={() => onChange(value)}
              defaultChecked={defaultValue === value}
              required
            />
            <label htmlFor={stringifiedValue}>{text}</label>
          </Fragment>
        );
      })}
    </ToggleWrapper>
  );
}

export default Toggle;
