import styled from "styled-components";

export const Wrapper = styled.div`
    width 80%;
    @media(min-width: 768px) {
    width: 60%;
    }
    @media(min-width: 1024px) {
    width: 40%;
    }
`;

export const ConfirmationButtons = styled.div`
  display: flex;
  justify-content: center;
`;

export const Message = styled.div`
  font-size: 0.9rem;
  margin-bottom: 10px;
  text-align: center;
`;

export const YesButton = styled.button`
  background-color: #122630;
  color: white;
  width: 3rem;
`;

export const NoButton = styled.button`
  background-color: #122630;
  color: white;
  width: 3rem;
  margin-left: 10px;
`;

export const Input = styled.input`
  display: block;
  box-sizing: border-box;
  width: 100%;
  border-radius: 4px;
  border: 1px solid black;
  padding: 10px 15px;
  margin-left: 3px;
  font-size: 14px;
  margin: auto;
`;
