import styled from "styled-components";

export const Title = styled.h1`
  font-family: Montserrat;
  font-size: 22px;
  font-weight: normal;
  line-height: 44px;
  padding-left: 16px;
`;
export const GoogleMapReactWrapper = styled.div`
  height: 800px;
  width: 100%;
`;
