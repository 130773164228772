import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

import { formatRange } from "../../utils/formatter";
import OrderInfoPiece from "../order-info-piece";
import { BagTypeType, OrderType } from "../../store/orders/types";
import { clearBag, setSearchedOrder } from "../../store/orders/actions";
import { OrderWrapper, OrderInfo } from "./style";

export { EmptyOrder } from "./empty-order";

interface Order {
  order: OrderType;
  onChange?: (newOrder: OrderType) => void;
  borderBottom?: boolean;
}

const Order = ({ order, borderBottom = true }: Order) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const navOrder = useCallback(() => {
    dispatch(clearBag());
    dispatch(setSearchedOrder(order));

    history.push(`/order/${order.id}`);
  }, [dispatch, history, order.id]);

  const getBagTotals = () => {
    let scannedBagsTotals = 0;

    for (let index = 0; index < order.itemization.bags.length; index++) {
      if (order.itemization.bags[index].itemization.length > 0) {
        scannedBagsTotals++;
      }
    }

    return scannedBagsTotals + " / " + order.itemization.bags.length;
  };

  const getBagsSummary = () => {
    const summary: { [key: string]: number } = {};
    let summaryString = "";

    for (let index = 0; index < order.itemization.bags.length; index++) {
      const bag = order.itemization.bags[index];

      if (bag.type) {
        if (!summary[bag.type]) {
          summary[bag.type] = 1;
        } else {
          summary[bag.type]++;
        }
      } else {
        if (!summary[bag.type]) {
          summary[""] = 1;
        } else {
          summary[""]++;
        }
      }
    }

    if (Object.keys(summary).length === 0) {
      return "0";
    }

    if (Object.keys(summary).length === 1 && summary[""]) {
      return summary[""].toString();
    }

    if (summary[""]) {
      summary["N/A"] = summary[""];
      delete summary[""];
    }

    for (const keyString in summary) {
      summaryString += summary[keyString] + " x " + keyString + "<br/>";
    }

    return summaryString;
  };

  const getServicesCount = (): number => {
    const services: BagTypeType[] = [];

    for (let index = 0; index < order.itemization.bags.length; index++) {
      const bag = order.itemization.bags[index];
      if (bag.type && !services.includes(bag.type)) {
        services.push(bag.type);
      }
    }

    return services.length;
  };

  return (
    <OrderWrapper borderBottom={borderBottom} onClick={navOrder}>
      <OrderInfo
        className={
          order.itemization.status === "COMPLETED"
            ? "orderReady"
            : "orderItemizing"
        }
      >
        <OrderInfoPiece icon="/img/order-number.svg" content={order.id} />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between"
          }}
        >
          <OrderInfoPiece icon="/img/profile.svg" content={order.customer} />
          {order.corporate ? (
            <OrderInfoPiece
              icon="/img/office.svg"
              content={order.corporate.name}
            />
          ) : null}
        </div>

        <OrderInfoPiece
          icon="/img/star.svg"
          className={order.serviceClass}
          content={order.serviceClass}
        />
        <OrderInfoPiece
          icon="/img/bag.svg"
          bagsCount={getBagTotals()}
          content={getBagsSummary()}
          rightIcon={getServicesCount() > 1 ? "/img/warning.svg" : undefined}
        />

        {order.dropoffDriver ? (
          <OrderInfoPiece
            icon="/img/car.svg"
            className={order.dropoffDriver}
            content={order.dropoffDriver}
          />
        ) : null}

        <OrderInfoPiece
          icon="/img/time.svg"
          content={formatRange(order.dropoffTime)}
        />
      </OrderInfo>
    </OrderWrapper>
  );
};

export default Order;
