import styled from "styled-components";
import colors from "../../../style/colors";
import OLayout from "../../layout";
import OButton from "../../../ui/button";

interface BlockProps {
  printHide?: boolean;
}

export const Block = styled.div<BlockProps>`
  @media print {
    display: ${({ printHide }) => (printHide ? "none" : "block")};
  }
`;

export const BlockTitle = styled.h3`
  color: ${colors.grey};
  font-size: 16px;
  margin: 0;
  margin-bottom: 10px;
  text-transform: uppercase;

  @media print {
    font-size: 95%;
  }
`;

export const Layout = styled(OLayout)`
  height: 100%;
`;

export const LayoutContent = styled.div`
  display: flex;
  flex-direction: row;

  @media print {
    flex-direction: column;
  }

  @media print {
    transform: scale(1);
  }
`;

export const Left = styled.div`
  height: 100%;
  padding-top: 25px;
  width: 50%;

  @media print {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
`;

export const Right = styled.div`
  padding-top: 25px;
  padding-right: 25px;
  width: 100%;

  @media print {
    padding-left: 25px;
  }
`;

export const OrderInfo = styled.div`
  padding: 12px 24px;

  span {
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;

    &:first-child {
      margin-right: 25px;
    }
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  @media print {
    display: none;
  }
`;

export const Button = styled(OButton)`
  padding: 0 10px;
  margin-right: 15px;
  width: 100px;

  &:last-child {
    margin-right: 0;
  }
`;

export const QRCodeContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  @media print {
    display: none;
  }
`;
