export enum UserType {
  DRIVER = "DRIVER",
  FACILITY = "FACILITY",
  ADMIN = "ADMIN",
  NULL = "NULL"
}

export interface User {
  email: string;
  id: string;
  name: string;
  type: UserType;
}

export const SET_USER = "SET_USER";
export const REMOVE_USER = "REMOVE_USER";

interface SetUser {
  type: typeof SET_USER;
  email: string;
  id: string;
  name: string;
  userType: UserType;
}

interface RemoveUser {
  type: typeof REMOVE_USER;
}

export type UserActionTypes = SetUser | RemoveUser;
