import { combineReducers } from "redux";
import auth from "./auth/reducer";
import user from "./user/reducer";
import orders from "./orders/reducer";
import products from "./products/reducer";
import operationAreas from "./operationAreas/reducer";
import serviceClasses from "./serviceClasses/reducer";
import timeslots from "./timeslot/reducer";
import holidays from "./holidays/reducer";
import Availabilities from "./availabilities/reducer";
import Tasks from "./tasks/reducer";

const rootReducer = combineReducers({
  auth,
  user,
  orders,
  products,
  operationAreas,
  timeslots,
  serviceClasses,
  holidays,
  Availabilities,
  Tasks
});

export default rootReducer;
