import React, { useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { AppState } from "../../store";
import { getOrders, setPage, changeDates } from "../../store/orders/actions";
import Layout from "../layout";
import OrderList from "../order-list";
import { OrderListWrapper } from "./style";
import { OrderType, CleaningStatus } from "../../store/orders/types";
import { Icon, Text } from "../order-info-piece/style";

interface Orders {
  orders: OrderType[];
}

interface Accumulator {
  [key: string]: OrderType[];
}

const Orders = () => {
  const page = useSelector<AppState, number>(state => state.orders.page);
  const totalPages = useSelector<AppState, number>(
    state => state.orders.totalPages
  );
  const orders = useSelector<AppState, OrderType[]>(
    state => state.orders.orders
  );
  const filterDates = useSelector<AppState, string | null>(
    state => state.orders.filterDates
  );
  const [searchTimeout, setSearchTimeout] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setPage(1));
    dispatch(changeDates(""));
    dispatch(getOrders());
  }, [dispatch]);

  const navigatePage = (offset: number) => {
    let newPageNumber = page + offset;

    if (newPageNumber < 1) {
      newPageNumber = 1;
    }

    if (newPageNumber > totalPages) {
      newPageNumber = totalPages;
    }

    dispatch(setPage(newPageNumber));
    dispatch(getOrders());
  };

  const ordersReducer = (acc: Accumulator, val: OrderType) => {
    let status = val.cleaning.status;
    const itemizationStatus = val.itemization.status;

    // I want to do this hack in case the facility is changing the itemization after the cleaning is completed
    if (itemizationStatus === "IN_PROGRESS") {
      status = CleaningStatus.BEING_PROCESSED;
    }

    const statusOrders = acc[status];

    if (statusOrders) {
      acc[status].push(val);
      return acc;
    }

    return acc;
  };

  const onSearch = (text: string) => {
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    setSearchTimeout(
      setTimeout(() => {
        dispatch(setPage(1));
        dispatch(getOrders(text));
      }, 500)
    );
  };

  const onChangeDates = (values: string) => {
    dispatch(setPage(1));
    dispatch(changeDates(values));
    dispatch(getOrders());
  };

  const filteredOrders = useMemo(
    () =>
      orders
        ? orders.reduce(ordersReducer, {
            [CleaningStatus.WAITING]: [],
            [CleaningStatus.BEING_PROCESSED]: [],
            [CleaningStatus.READY_FOR_DROPOFF]: []
          })
        : {
            [CleaningStatus.WAITING]: [],
            [CleaningStatus.BEING_PROCESSED]: [],
            [CleaningStatus.READY_FOR_DROPOFF]: []
          },
    [orders]
  );

  return (
    <Layout
      title="Commandes :"
      showDatesDropdown={true}
      showSearch={true}
      callback={onSearch}
      changeDatesCallback={onChangeDates}
      filterDates={filterDates}
    >
      <OrderListWrapper>
        <OrderList
          title="Commandes"
          orders={filteredOrders[CleaningStatus.WAITING]}
        />
        <OrderList
          title="En cours de traitement"
          orders={filteredOrders[CleaningStatus.BEING_PROCESSED]}
        />
        <OrderList
          title="Prêtes à être livrées"
          orders={filteredOrders[CleaningStatus.READY_FOR_DROPOFF]}
        />
      </OrderListWrapper>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <Icon
          src="/img/first.svg"
          style={{ marginRight: "10px" }}
          onClick={() => navigatePage(-page + 1)}
        />
        <Icon
          src="/img/previous.svg"
          style={{ marginRight: "10px" }}
          onClick={() => navigatePage(-1)}
        />
        <Text style={{ marginRight: "10px" }}>
          {page} / {totalPages}
        </Text>
        <Icon
          src="/img/next.svg"
          style={{ marginRight: "10px" }}
          onClick={() => navigatePage(1)}
        />
        <Icon
          src="/img/last.svg"
          style={{ marginRight: "10px" }}
          onClick={() => navigatePage(totalPages - page)}
        />
      </div>
    </Layout>
  );
};

export default Orders;
