import { Product } from "../../typings/product";
import { Category, CategoryMap } from "../../typings/category";

export const SET_PRODUCTS = "SET_PRODUCTS";
export const SET_CATEGORIES = "SET_CATEGORIES";

interface SetCategories {
  type: typeof SET_CATEGORIES;
  categories: Category[];
}

interface SetProducts {
  type: typeof SET_PRODUCTS;
  products: Product[];
}

export type ProductsActionTypes = SetCategories | SetProducts;

export interface ProductsState {
  allProducts: Product[];
  categories: CategoryMap;
}
