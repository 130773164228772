import styled from "styled-components";
import colors from "../../../style/colors";

export const BagButton = styled.button`
  background-color: ${colors.secondary};
  border: none;
  border-radius: 25px;
  color: ${colors.white}
  cursor: pointer;
  font-weight: bold;
  height: 50px;
  margin-bottom: 20px;
`;

export const BagDetail = styled.ul`
  list-style-type: none;
  width: 100%;
`;

export const BagDetailItem = styled.li`
  display: flex;
  justify-content: space-between;
  width: 80%;
`;

export const Name = styled.div``;
export const Qty = styled.div`
  color: ${colors.primary};
  font-weight: bold;
  font-size: 20px;
`;

export const BagTitle = styled.h2`
  color: ${colors.primary};
  text-align: center;
  width: 100%;
`;

export const Container = styled.div`
  align-items: center;
  border: 2px solid ${colors.primary};
  border-radius: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 20px;
  width: 45%;
`;
