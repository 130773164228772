import React, { useCallback, useState } from "react";
import { useSelector } from "react-redux";

import { useToast } from "../toast";
import Toggle from "../toggle";
import useDispatch from "../../hooks/use-thunk-dispatch";
import useProducts from "./use-products";
import Product from "../product-itemizer";
import { Product as ProductType } from "../../typings/product";
import { AppState } from "../../store";
import {
  BagTypeType,
  ItemizationType,
  OrdersState
} from "../../store/orders/types";
import {
  clearBag,
  saveBagItemization,
  setItemizationBagType
} from "../../store/orders/actions";
import {
  BackButton,
  ButtonContainer,
  Container,
  EditButton,
  Title
} from "./style";

interface Props {
  status: string;
  itemization: ItemizationType;
}

interface Item {
  product: string;
  quantity: number;
}

const Itemizer = ({ status }: Props) => {
  const dispatch = useDispatch();
  let [editMode, setEditMode] = useState<boolean>(false);
  let currentBag: any = null;

  const products = useSelector<AppState, ProductType[]>(
    state => state.products.allProducts
  );

  const bagType = useSelector<AppState, string>(
    state => state.orders.currentBagType!
  );

  const { itemization, currentBagBarcode, currentOrder } = useSelector<
    AppState,
    OrdersState
  >(state => state.orders);
  const isEditable = currentOrder!.isItemizationBlocked === false;
  editMode = editMode && isEditable;

  const isEditMode = () => {
    return status === "AWAITING_ITEMIZATION" || editMode;
  };

  const getBagType = () => {
    if (bagType) {
      return bagType;
    }

    if (currentBag) {
      return currentBag.type;
    }

    return "N/A";
  };

  const getProducts = () => {
    const prods = products.filter(
      product => product.cleaningType === getBagType()
    );

    return prods;
  };

  const currentItemization: { [key: string]: number } = {};
  if (
    currentOrder &&
    currentOrder.itemization &&
    currentOrder.itemization.bags
  ) {
    currentBag = currentOrder.itemization.bags.find(
      bag => bag.barcode === currentBagBarcode
    );

    if (currentBag) {
      currentBag.itemization.map(
        (item: Item) => (currentItemization[item.product] = item.quantity)
      );
    }
  }

  const back = useCallback(() => {
    dispatch(clearBag());
  }, [dispatch]);

  const hash: string = new Date().getUTCMilliseconds().toString();
  const toggleEdit = useCallback(() => {
    if (status === "AWAITING_ITEMIZATION") {
      back();
    }

    setEditMode(!editMode);
  }, [editMode, setEditMode]);

  const { showToast } = useToast();
  const saveBag = useCallback(async () => {
    const result = await dispatch(saveBagItemization(null));
    if (result && result.success) {
      showToast(
        "Comptage du sac enregistré. Vous pouvez passer au suivant ou finalier le comptage"
      );
    }
  }, [dispatch, showToast]);

  const setBagType = useCallback(
    btype => dispatch(setItemizationBagType(btype)),
    [dispatch]
  );
  const { loading } = useProducts();

  return (
    <Container>
      {isEditable ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            alignItems: "flex-start"
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "space-between",
              alignItems: "flex-end"
            }}
          >
            <ButtonContainer style={{ flex: 0.1, justifyContent: "flex-end" }}>
              <BackButton onClick={back}>←</BackButton>
            </ButtonContainer>

            <Title style={{ flex: 1 }}>
              Comptage du sac n°{currentBagBarcode}
            </Title>

            <ButtonContainer style={{ flex: 1, justifyContent: "flex-end" }}>
              {isEditMode() ? (
                <div>
                  <EditButton onClick={saveBag}>Enregistrer le sac</EditButton>
                  <EditButton onClick={toggleEdit}>Cancel</EditButton>
                </div>
              ) : (
                <EditButton onClick={toggleEdit}>Éditer le sac</EditButton>
              )}
            </ButtonContainer>
          </div>

          <ButtonContainer>
            <Toggle
              defaultValue={getBagType()}
              options={[
                { value: BagTypeType.WASH_AND_FOLD, text: "Wash & Fold" },
                { value: BagTypeType.DRY_CLEANING, text: "Dry Cleaning" }
              ]}
              onChange={setBagType}
            />
          </ButtonContainer>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            alignItems: "flex-start"
          }}
        >
          <div style={{ display: "flex", flex: 1, flexDirection: "row" }}>
            <ButtonContainer>
              <div style={{ display: "flex", flex: 1, flexDirection: "row" }}>
                <BackButton onClick={back}>←</BackButton>
                <Title>Comptage du sac n°{currentBagBarcode}</Title>
              </div>
            </ButtonContainer>
          </div>
        </div>
      )}

      {loading
        ? "Loading..."
        : getProducts().map(prod => {
            const quantity =
              itemization && prod.id in itemization ? itemization[prod.id] : 0;

            return (
              <Product
                key={prod.id + quantity}
                id={prod.id}
                name={prod.name}
                quantity={quantity}
                editMode={isEditMode()}
              />
            );
          })}
    </Container>
  );
};

export default Itemizer;
