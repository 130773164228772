import styled from "styled-components";
import colors from "../../../style/colors";

export const OrderListWrapper = styled.div`
  width: 100%;

  h2 {
    border: 1px solid ${colors.border};
    border-bottom: none;
    font-size: 18px;
    line-height: 22px;
    margin: 0;
    padding: 15px;
  }
`;
