import React from "react";
import { OrderWrapper, EmptyOrderContent } from "./style";

interface EmptyOrder {
  content: string;
}
export const EmptyOrder = ({ content }: EmptyOrder) => (
  <OrderWrapper borderBottom={true}>
    <EmptyOrderContent>
      <span>{content}</span>
    </EmptyOrderContent>
  </OrderWrapper>
);
