import styled from "styled-components";
import colors from "../../../style/colors";

export const Container = styled.table`
  display: block;
  width: 100%;
  margin: 10px 0;
`;

export const Icon = styled.img`
  margin-right: 30px;
  width: 30px;
  display: inline-block;

  @media print {
    margin-right: 10px;
  }
`;

export const Title = styled.div`
  color: ${colors.secondary};
  font-weight: bold;
`;

export const SubTitle = styled.div`
  color: ${colors.grey};
  font-size: 13px;
`;

export const Text = styled.div`
  font-size: 14px;
`;

export const TextWrapper = styled.div`
  display: table-cell;
  vertical-align: middle;
`;

export const Lite = {};

export const Express = {
  color: "red"
};

export const Gold = {
  color: "yellow"
};

type style = { [key: string]: any };
export const styles: { [key: string]: style } = {
  Lite,
  Express,
  Gold
};
