import styled from "styled-components";
import colors from "../../../style/colors";

export const Container = styled.div`
  bottom: 0;
  position: fixed;
  right: 0;
`;

export const PadClose = styled.div`
  background-color: ${colors.secondary};
  border-radius: 25px;
  color: ${colors.white};
  height: 30px;
  margin-top: 10px;
  padding: 3px 0 0 3px;
  width: 30px;
`;

export const PadContainer = styled.div`
  background-color: ${colors.lightGrey};
  display: flex;
  flex-wrap: wrap;
  height: 600px;
  justify-content: space-around;
  width: 500px;
`;
export const PadNumber = styled.button`
  font-size: 30px;
  width: 32%;
`;
export const PadValue = styled.div`
  background-color: ${colors.grey}
  font-family: monospace;
  font-size: 40px;
  height: 80px;
  margin-top: 10px;
  text-align: center;
  width: 80%;
`;
export const ShowButton = styled.button`
  background-color: ${colors.secondary};
  border: 2px solid ${colors.white};
  border-radius: 25px;
  box-shadow: -2px -2px 5px black;
  color: ${colors.white};
  margin: 20px;
  height: 50px;
  width: 250px;

  @media print {
    display: none;
  }
`;
