import styled from "styled-components";
import colors from "../../../style/colors";

export const BagWrapper = styled.div`
  background-color: ${colors.secondaryTransparentize50};
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 15px;
`;

export const BagItemization = styled.div``;

export const BagLine = styled.div`
  display: flex;
  flex-direction: row;
`;

export const BagLineHeader = styled(BagLine)`
  font-weight: bold;
  margin-bottom: 10px;
`;

const BagLineCol = styled.span`
  display: block;
  width: 100%;

  &:fist-child {
    border-bottom: 1px solid ${colors.black};
  }

  &:last-child {
    margin-right: 0;
  }
`;

export const BagName = styled(BagLineCol)`
  min-width: 50%;
`;
export const BagQuantity = styled(BagLineCol)``;
export const BagUnitPrice = styled(BagLineCol)``;
export const BagPrice = styled(BagLineCol)``;

export const BagTitle = styled.h4`
  margin-top: 15px;
`;

export const CartWrapper = styled.div`
  width: 100%;
`;
