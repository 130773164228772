import styled from "styled-components";
import colors from "../../../style/colors";

export const Wrapper = styled.div`
  border: 1px solid ${colors.border};
  border-top: none;
  display: flex;
  flex-direction: row;
  font-size: 16px;
  padding: 0 14px 12px;

  span {
    white-space: nowrap;
  }
`;

export const ButtonWrapper = styled.div``;
