import {
  ServiceClasse,
  ServiceClasseUpdate
} from "../../typings/serviceClasse";
import {
  SET_SERVICECLASSES,
  SET_SERVICECLASSE,
  ServiceClassesActionTypes,
  ServiceClassesState,
  ServiceClasseState
} from "./types";

const initialState: ServiceClassesState | ServiceClasseState = {
  allServiceClasses: [],
  serviceClasse: {} as ServiceClasseUpdate
};

const ServiceClassesReducer = (
  state = initialState,
  action: ServiceClassesActionTypes
): ServiceClassesState => {
  switch (action.type) {
    case SET_SERVICECLASSES: {
      return {
        ...state,
        allServiceClasses: action.serviceClasses
      };
    }
    case SET_SERVICECLASSE: {
      return {
        ...state,
        serviceClasse: action.serviceClasse
      };
    }

    default:
      return state;
  }
};

export default ServiceClassesReducer;
