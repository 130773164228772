import styled from "styled-components";
import colors from "./colors";

const Input = styled.input`
  border: 1px solid ${colors.border};
  border-radius: 4px;
  color: ${colors.black};
  font-family: Montserrat;
  font-size: 16px;
  font-weight: bold;
  line-height: 22px;
  padding: 14px;
  text-align: center;
  width: 100%;

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: ${colors.border};
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${colors.border};
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: ${colors.border};
  }
`;

export default Input;
