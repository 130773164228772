import { ThunkAction } from "redux-thunk";
import { AnyAction } from "redux";
import { get, post, remove, put, HttpResponse } from "../../utils/http";
import { ReduxDispatch } from "../../hooks/use-thunk-dispatch";
import { SET_TASKS, TasksActionTypes, POST_TASK_SUCCESS } from "./types";
import { AppState } from "..";
import { OnfleetMetadata, OnfleetTask, Tasks } from "../../typings/tasks";
import { ActionResponse } from "../tasks/types";
import moment from "moment";

interface GetTasksResponse {
  tasks: [];
}

export const SetTasks = (tasks: Tasks[]): TasksActionTypes => ({
  type: SET_TASKS,
  tasks
});

const postTaskSuccess = response => {
  return {
    type: POST_TASK_SUCCESS,
    response: response
  };
};

export const getTasks = (): ThunkAction<
  Promise<HttpResponse<GetTasksResponse>>,
  AppState,
  {},
  AnyAction
> => async (dispatch: ReduxDispatch, getState) => {
  try {
    const onfleetTasks = [] as OnfleetTask[];
    const days = [-10, -9, -8, -7, -6, -5, -4, -3, -2, -1, 0];
    await Promise.all(
      days.map(async day => {
        const fromDate = moment()
          .add(day, "days")
          .format("YYYY-MM-DD");
        const toDate = moment()
          .add(day + 1, "days")
          .format("YYYY-MM-DD");
        const response = await get(
          "/tasks?from=" + fromDate + "&to=" + toDate,
          getState()
        );
        if (response.parsedBody) {
          const tasksResponse = response.parsedBody as { tasks: OnfleetTask[] };
          onfleetTasks.push(...tasksResponse.tasks);
        }
      })
    );

    const tasks = [] as Tasks[];

    if (onfleetTasks != null) {
      onfleetTasks.forEach(task => {
        const orderMetadata = task.metadata.find(
          m => m.name === "order"
        ) as OnfleetMetadata;
        const serviceMetadata = task.metadata.find(
          m => m.name === "service"
        ) as OnfleetMetadata;
        const paymentStatusMetadata = task.metadata.find(
          m => m.name === "paymentStatus"
        ) as OnfleetMetadata;
        if (
          orderMetadata == null ||
          serviceMetadata == null ||
          paymentStatusMetadata == null
        ) {
          return;
        }
        const taskOrderReference = orderMetadata.value;
        const taskService = serviceMetadata.value;
        const taskPaymentStatus = paymentStatusMetadata.value;
        if (taskOrderReference && taskService) {
          const taskGroup: Tasks | undefined = tasks.find(
            t => t.orderReference === taskOrderReference
          );
          if (taskGroup === undefined) {
            const newTaskGroup: Tasks = {
              orderReference: taskOrderReference,
              service: taskService,
              paymentStatus: taskPaymentStatus,
              pickupTask: task.pickupTask ? task : null,
              dropoffTask: !task.pickupTask ? task : null,
              createdAt: moment(task.timeCreated).format("YYYY-MM-DD"),
              recipients: task.recipients,
              comment: "Erreur"
            };
            tasks.push(newTaskGroup);
          } else {
            if (task.pickupTask) {
              taskGroup.pickupTask = task;
              taskGroup.comment = "";
            } else {
              taskGroup.dropoffTask = task;
              taskGroup.comment = "";
            }
          }
        }
      });
    }

    dispatch(SetTasks(tasks));
  } catch (error) {
    return error;
  }
};

export const postTask = (task: OnfleetTask) => async (
  dispatch: ReduxDispatch,
  getState: () => AppState
): Promise<ActionResponse> => {
  let response!: HttpResponse<OnfleetTask>;

  try {
    response = await post(`/createtask`, task, getState());
    if (response && response.ok && response.parsedBody) {
      dispatch(postTaskSuccess("response"));
      return {
        success: true
      };
    } else {
      return {
        success: false
      };
    }
  } catch (e) {
    return {
      success: false
    };
  }
};
