import { SET_TASKS, TasksActionTypes, TasksState } from "./types";

const initialState: TasksState = {
  allTasks: []
};

const Tasks = (state = initialState, action: TasksActionTypes): TasksState => {
  switch (action.type) {
    case SET_TASKS: {
      return {
        ...state,
        allTasks: action.tasks
      };
    }
    default:
      return state;
  }
};

export default Tasks;
