import React, { useState } from "react";
import { connect } from "react-redux";
import Toggle from "../toggle";
import { UserType } from "../../store/user/types";
import { Response } from "../../store/auth/types";
import {
  Wrapper,
  LoginInputEmail,
  LoginInputPassword,
  LoginButton
} from "./style";
import { login as loginAction, Login } from "../../store/auth/actions";
import { History } from "history";

import { useToast, TYPE as TOAST_TYPE } from "../toast";

interface LoginPage {
  loginAction: (v: Login) => Promise<Response>;
  history: History;
}

export const LoginPage = ({ loginAction, history }: LoginPage) => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [userType, setUserType] = useState<UserType>(UserType.DRIVER);
  const [loadingAuth, setLoadingAuth] = useState(false);

  const { showToast } = useToast();

  const toggleOptions = [
    { value: UserType.DRIVER, text: "coursier" },
    { value: UserType.FACILITY, text: "pressing" },
    { value: UserType.ADMIN, text: "admin" }
  ];

  const login = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoadingAuth(true);

    const result = await loginAction({
      email,
      password,
      userType
    });

    setLoadingAuth(false);

    if (result.error) showToast(result.error, { type: TOAST_TYPE.ERROR });
    if (result.success) {
      showToast("Bienvenue");
      history.push("/orders");
    }
  };

  return (
    <Wrapper>
      <form onSubmit={login}>
        <img src="./img/wast_logo.svg" alt="Wast" />
        <Toggle<UserType>
          defaultValue={UserType.DRIVER}
          options={toggleOptions}
          onChange={setUserType}
        />
        <LoginInputEmail
          type="email"
          name="email"
          placeholder="ADRESSE MAIL"
          value={email}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setEmail(e.currentTarget.value)
          }
          required
        />
        <LoginInputPassword
          type="password"
          placeholder="MOT DE PASSE"
          name="password"
          value={password}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setPassword(e.currentTarget.value)
          }
          required
        />
        <LoginButton type="submit" disabled={!email || !password}>
          {loadingAuth ? "Connexion en cours..." : "Se connecter"}
        </LoginButton>
      </form>
    </Wrapper>
  );
};

const mapDispatchToProps = {
  loginAction
};

export default connect(null, mapDispatchToProps)(LoginPage);
