import {
  SET_AVAILABILITIES,
  AvailabilitiesActionTypes,
  CheckAvailabilitiesActionTypes,
  AvailabilitiesState
} from "./types";
import { OperationAreaMap } from "../../typings/operationArea";

const initialState: AvailabilitiesState = {
  allAvailabilities: []
};

const Availabilities = (
  state = initialState,
  action: AvailabilitiesActionTypes
): AvailabilitiesState => {
  switch (action.type) {
    case SET_AVAILABILITIES: {
      return {
        ...state,
        allAvailabilities: action.availabilities
      };
    }
    default:
      return state;
  }
};

export default Availabilities;
