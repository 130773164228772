import React from "react";
import SortButton from "./sort-button";
import { OrderSort } from "../../store/orders/types";
import { Wrapper, ButtonWrapper } from "./style";

interface SortBar {
  currentSort: OrderSort;
  updateSort: (newSort: OrderSort) => void;
}

const SortBar = ({ currentSort, updateSort }: SortBar) => (
  <Wrapper>
    <span>Trier par :</span>
    <ButtonWrapper>
      <SortButton
        label="n° de commande"
        onChange={updateSort}
        property="id"
        currentSort={currentSort}
      />
      <SortButton
        label="client"
        onChange={updateSort}
        property="customer"
        currentSort={currentSort}
      />
    </ButtonWrapper>
  </Wrapper>
);

export default SortBar;
