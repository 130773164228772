import styled from "styled-components";
import colors from "../../../style/colors";
import OButtonLink from "../../../ui/button-link";

export const BagButton = styled.button`
  background-color: ${colors.white};
  border: none;
  cursor: pointer;
`;

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding-top: 30px;
`;

export const ButtonLink = styled(OButtonLink)`
  margin: 20px auto;
`;
