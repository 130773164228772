import dayjs from "dayjs";

import { TimeRange } from "../store/orders/types";

export const formatRange = (d: TimeRange) =>
  `${dayjs(d.startTime).format("DD MMM YYYY, HH:mm")} - ${dayjs(
    d.endTime
  ).format("HH:mm")}`;

export const getBagIcon = (itemization: any) => {
  if (itemization.length > 0) {
    return "/img/bag.svg";
  }

  return "/img/bag-missing.svg";
};
