import {
  SET_OPERATIONAREAS,
  OperationAreasActionTypes,
  OperationAreasState
} from "./types";

const initialState: OperationAreasState = {
  allOperationAreas: []
};

const OperationAreasReducer = (
  state = initialState,
  action: OperationAreasActionTypes
): OperationAreasState => {
  switch (action.type) {
    case SET_OPERATIONAREAS: {
      return {
        ...state,
        allOperationAreas: action.operationAreas
      };
    }
    default:
      return state;
  }
};

export default OperationAreasReducer;
