import styled from "styled-components";
import colors from "../../../style/colors";

export const QuantityModifier = styled.button`
  background-color: ${colors.secondary}
  border: none;
  color: ${colors.white}
  font-size: 25px;
  font-weight: bold;
  height: 30px;
  width: 40px;
`;

export const ProductContainer = styled.div`
  border: 1px solid;
  margin-bottom: 20px;
  min-height: 100px;
  position: relative;
  width: 31%;
`;

export const ProductName = styled.div`
  padding: 10px;
  text-align: center;
  width: 100%;
`;

export const Quantity = styled.div`
  color: ${colors.white}
  font-weight: bold;
  line-height: 30px;
  text-align: center;
  width: 100%;
`;

export const QuantityModifierContainer = styled.div`
  background-color: ${colors.secondary}
  bottom: 0;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  position: absolute;
  width: 100%
`;
