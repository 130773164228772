import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { AppState } from "../../store";
import { OrdersState } from "../../store/orders/types";

import { useToast } from "../toast";
import useDispatch from "../../hooks/use-thunk-dispatch";
import { Container } from "./style";
import { ButtonContainer, EditButton } from "../itemizer/style";
import BagView from "../bag-view";
import {
  endCleaning,
  endItemization,
  clearBag,
  clearSearchedOrder
} from "../../store/orders/actions";
import Separator from "../../ui/separator";
import { Redirect } from "react-router-dom";

const OrderView = () => {
  const { currentOrder } = useSelector<AppState, OrdersState>(
    state => state.orders
  );
  const isItemizationInProgress =
    currentOrder && currentOrder.itemization.status !== "COMPLETED";
  const isCleaningInProgress =
    isItemizationInProgress === false &&
    currentOrder &&
    currentOrder.cleaning.status !== "COMPLETED";
  const dispatch = useDispatch();
  const history = useHistory();
  const { showToast } = useToast();

  const finishItemizationAction = useCallback(async () => {
    const response = await dispatch(endItemization());

    if (response.success) {
      dispatch(clearBag());
      // dispatch(clearSearchedOrder());

      // Add this in order to go to the printing page
      history.push(`/order/${currentOrder && currentOrder.id}/confirmation`);

      // disable this as I need to print after this
      // history.push(`/orders`);
    } else {
      showToast(response.error);
    }
  }, [dispatch]);

  const finishCleaningAction = useCallback(async () => {
    const response = await dispatch(
      endCleaning({ orderId: currentOrder!.id }, true)
    );

    if (response.success) {
      dispatch(clearBag());
      dispatch(clearSearchedOrder());

      history.push(`/orders`);
    } else {
      showToast(response.error);
    }
  }, [dispatch]);

  const gotoPrintAction = useCallback(async () => {
    history.push(`/order/${currentOrder && currentOrder.id}/confirmation`);
  }, []);

  return (
    <Container>
      {currentOrder === null ? <Redirect push to="/orders" /> : null}

      {isItemizationInProgress ? (
        <ButtonContainer>
          <EditButton onClick={finishItemizationAction}>
            Comtage terminé
          </EditButton>
        </ButtonContainer>
      ) : null}

      {/* {isCleaningInProgress ? (
        <ButtonContainer>
          <EditButton onClick={finishCleaningAction}>
            Nettoyage terminé
          </EditButton>
        </ButtonContainer>
      ) : null} */}

      {isItemizationInProgress ? null : (
        <EditButton onClick={gotoPrintAction}>Imprimer</EditButton>
      )}

      <Separator />

      {currentOrder &&
        currentOrder.itemization.bags.map(bag => (
          <BagView
            key={bag.barcode + JSON.stringify(bag.itemization)}
            bag={bag}
          />
        ))}
    </Container>
  );
};

export default OrderView;
