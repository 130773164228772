import styled from "styled-components";
import { default as OInput } from "../../../style/input";
import colors from "../../../style/colors";
import OButton from "../../../ui/button";
import OButtonLink from "../../../ui/button-link";

export const Input = styled(OInput)`
  border: none;
  border-top: 1px solid ${colors.borderLight}
  border-bottom: 0;
  font-weight: normal;
  text-align: left;
  text-transform: none;
`;

export const OrderContainer = styled.div`
  display: flex;
`;

export const OrderInfo = styled.div`
  padding: 12px 24px;

  span {
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;

    &:first-child {
      margin-right: 25px;
    }
  }
`;

export const EmptyOrderContent = styled.div`
  align-items: center;
  display: flex;
  font-style: italic;
  padding: 12px 24px;
`;

export const Button = styled(OButton)`
  background-color: ${colors.secondary};
  border: none;
  border-radius: 10px;
  color: ${colors.white};
  height: 50px;
  margin: auto;
  margin-bottom: 20px;
  width: 150px;
`;

export const OrderLeftPanel = styled.div`
  border-right: 1px solid;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 25%;

  &.orderItemizing {
    background-color: ${colors.orderItemizing};

    button {
      background-color: #00000000;
    }
  }

  &.orderReady {
    background-color: ${colors.orderReady};

    button {
      background-color: #00000000;
    }
  }
`;

export const OrderRightPanel = styled.div`
  width: 75%;
`;

export const ButtonLink = styled(OButtonLink)`
  margin: 20px auto;
`;
