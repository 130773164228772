import React, { useEffect } from "react";
import useDispatch from "../../hooks/use-thunk-dispatch";
import { useSelector } from "react-redux";
import Layout from "../layout";
import DataTable from "react-data-table-component";
import { AppState } from "../../store";
import { getServiceClasses } from "../../store/serviceClasses/actions";
import { ServiceClasse } from "../../typings/serviceClasse";
import { Button, Editbutton } from "./style";
import { Link } from "react-router-dom";
import { FaEdit } from "react-icons/fa";

const columns = [
  {
    name: "Id",
    selector: "id",
    sortable: true
  },
  {
    name: "Nom",
    selector: "name",
    sortable: true
  },
  {
    name: "Valeur min de la commande",
    selector: "minOrderValue",
    sortable: true
  },
  {
    name: "Délai de ramassage",
    selector: "pickupLeadTime",
    sortable: true
  },
  {
    name: "Créneau horaire",
    selector: "timeslotDuration",
    sortable: true
  },
  {
    name: "Ordre de tri",
    selector: "sortOrder",
    sortable: true
  },
  {
    name: "prix",
    selector: "price",
    sortable: true
  },
  {
    name: "zohoCrmId",
    selector: "zohoCrmId",
    sortable: true
  },
  {
    name: "zohoBookId",
    selector: "zohoBookId",
    sortable: true
  },
  {
    name: "createdAt",
    selector: "createdAt",
    sortable: true
  },
  {
    name: "updatedAt",
    selector: "updatedAt",
    sortable: true
  },
  {
    name: "action",
    selector: "id",
    cell: row => {
      return (function Actions() {
        return (
          <Link to={"/service-classes/edit/" + row.id}>
            <Editbutton>
              <FaEdit></FaEdit>Modifier
            </Editbutton>
          </Link>
        );
      })();
    }
  }
];

const ServiceClasses = () => {
  const dispatch = useDispatch();
  const [pending, setPending] = React.useState(true);

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      setPending(true);
      await dispatch(getServiceClasses());
      setPending(false);
    };

    fetchData();
  }, [dispatch]);

  const data: ServiceClasse[] = useSelector<AppState, ServiceClasse[]>(
    state => state.serviceClasses.allServiceClasses
  );

  return (
    <Layout title="Services" showSearchBag={false}>
      <div className="App">
        <Link to="/service-classes/edit">
          <Button>Ajouter un nouveau service</Button>
        </Link>
        <DataTable
          title="Services"
          columns={columns}
          data={data}
          defaultSortField="title"
          pagination
          responsive
          progressPending={pending}
        />
      </div>
    </Layout>
  );
};

export default ServiceClasses;
