import { useEffect, useState } from "react";

import { getCategories, getProducts } from "../../store/products/actions";
import { useToast, TOAST_TYPE } from "../../ui/toast";
import useThunkDispatch from "../../hooks/use-thunk-dispatch";

interface UseProductsResult {
  loading: boolean;
}

const useProducts = (): UseProductsResult => {
  const dispatch = useThunkDispatch();
  const { showToast } = useToast();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      setLoading(true);

      const catResponse = await dispatch(getCategories());
      if (!catResponse.ok) {
        showToast(catResponse.error ? catResponse.error.message : "Error", {
          type: TOAST_TYPE.ERROR
        });
      }

      const response = await dispatch(getProducts());
      if (!response.ok) {
        showToast(response.error ? response.error.message : "Error", {
          type: TOAST_TYPE.ERROR
        });
      }

      setLoading(false);
    };

    fetchData();
  }, [dispatch, showToast]);

  return { loading };
};

export default useProducts;
