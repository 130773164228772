import { Button } from "./style/sort-button";
import React from "react";
import { SortDirection, OrderSort, OrderType } from "../../store/orders/types";

interface SortButton {
  currentSort: OrderSort;
  label: string;
  property: keyof OrderType;
  onChange: (newSort: OrderSort) => void;
}

const SortButton = ({ label, onChange, property, currentSort }: SortButton) => (
  <Button
    isActive={currentSort.property === property}
    onClick={() =>
      onChange({
        property,
        direction:
          currentSort.direction === SortDirection.ASC
            ? SortDirection.DESC
            : SortDirection.ASC
      })
    }
  >
    <span>{label}</span>
    {currentSort.property === property && (
      <>
        &nbsp;
        {currentSort.direction === SortDirection.ASC ? "▲" : "▼"}
      </>
    )}
  </Button>
);

export default SortButton;
