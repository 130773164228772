import {
  UserActionTypes,
  User,
  UserType,
  SET_USER,
  REMOVE_USER
} from "./types";

const initialState: User = {
  email: "",
  id: "",
  name: "",
  type: UserType.NULL
};

const userReducer = (state = initialState, action: UserActionTypes): User => {
  switch (action.type) {
    case SET_USER: {
      return {
        ...state,
        email: action.email,
        id: action.id,
        name: action.name,
        type: action.userType
      };
    }
    case REMOVE_USER: {
      return initialState;
    }
    default:
      return state;
  }
};

export default userReducer;
